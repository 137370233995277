<template>
    <div class="left_menu">
        <div class="Breadcrumb">
            <el-page-header @back="goBack">
            </el-page-header>
        </div>
        <el-menu class="el-menu-vertical-demo" :default-active="$route.path" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
            <el-submenu index="1">
                <template slot="title">
                    <i class="el-icon-user"></i>
                    <span>会员管理</span>
                </template>
                <el-menu-item-group>
                    <!-- <template slot="title">分组一</template> -->
                    <router-link :to="{path:'/user/list',query:{account:account}}">
                        <el-menu-item index="/user/list">会员列表</el-menu-item>
                    </router-link>
                    <router-link to="/user/add">
                        <el-menu-item v-if="manSHow" index="/user/add">添加人员</el-menu-item>
                    </router-link>
                </el-menu-item-group>
            </el-submenu>
            <!-- <el-menu-item index="2" disabled>
                <i class="el-icon-circle-plus-outline"></i>
                <span slot="title">添加管理</span>
            </el-menu-item> -->
            <!-- <el-menu-item index="3" disabled>
                <i class="el-icon-plus"></i>
                <span slot="title">添加级别</span>
            </el-menu-item> -->
            <!-- <el-menu-item index="5" disabled>
                <i class="el-icon-s-claim"></i>
                <span slot="title">新医疗</span>
            </el-menu-item> -->

            <el-submenu index="2">
                <template slot="title">
                    <i class="el-icon-s-cooperation"></i>
                    <span>实用工具</span>
                </template>
                <el-menu-item-group>
                    <!-- <template slot="title">分组一</template> -->
                    <router-link :to="{path:'/tools/upload/photo',query:{account:account}}">
                        <el-menu-item v-if="manSHow" index="/tools/upload/photo">上传图片</el-menu-item>
                    </router-link>

                    <router-link :to="{path:'/tools/baike/catch',query:{account:account}}">
                        <el-menu-item v-if="manSHow" index="/tools/baike/catch">百科查询</el-menu-item>
                    </router-link>

                    <router-link :to="{path:'/tools/upload/sha256',query:{account:account}}">
                        <el-menu-item v-if="manSHow" index="/tools/upload/sha256">Sha256加密</el-menu-item>
                    </router-link>
                </el-menu-item-group>
            </el-submenu>

            <router-link :to="{path:'/debug/list'}" v-show="bug_show">
                <el-menu-item index="/debug/list">
                    <i class="el-icon-notebook-1"></i>
                    <span slot="title">需求列表</span>
                </el-menu-item>
            </router-link>

            <router-link :to="{path:'/user/feedback_add',query:{account:account}}">
                <el-menu-item index="/user/feedback_add">
                    <i class="el-icon-edit"></i>
                    <span slot="title">意见反馈</span>
                </el-menu-item>
            </router-link>
            <router-link to="/sign/out">
                <el-menu-item index="/sign/out">
                    <i class="el-icon-switch-button"></i>
                    <span slot="title">退出系统</span>
                </el-menu-item>
            </router-link>
        </el-menu>
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'LeftMenu0',
    data() {
        return {
            account: '',
            manSHow: false,
            bug_show: false,
        }
    },

    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        goBack() {
            history.go(-1);
        },
    },

    created() {
        // 从缓存获取user
        const child_user = sessionStorage.getItem('user')
        let user_obj = JSON.parse(child_user)
        this.account = user_obj.account
        console.log('user_obj.level', user_obj.level)
        if (user_obj.level == 1 || user_obj.level == 2) {
            this.manSHow = true
        }
        if (user_obj.level == 1) {
            this.bug_show = true
        }
    }
}

</script>

<style scoped>
.Breadcrumb {
    background-color: #545c64;
    color: #fff;
}

.left_menu {
    width: 100%;
    background-color: grey;
    /* display: inline; */
    /* z-index: 1000; */
}

a {
    text-decoration: none;
}

.router-link-active {
    text-decoration: none;
}
</style>
