<template>
  <el-container>
    <el-header>
      <HeaderTop />
    </el-header>
    <el-container>
      <el-aside width="200px">
        <LeftMenu />
      </el-aside>
      <el-container>
        <el-main>
          <div class='list_content'>
            <h2 style="text-align: center;">修改需求</h2>
            <el-form ref="form" :model="bug_record" label-width="80px">
              <el-form-item label="标题" style="margin-left: 0px;">
                <el-tooltip class="item" effect="dark" content="bug的简要描述" placement="right">
                  <el-input v-model="bug_record.title"></el-input>
                </el-tooltip>
              </el-form-item>
              <el-form-item label="详情" style="margin-left: 0px;">
                <el-tooltip class="item" effect="dark" content="bug的详细描述" placement="right">
                  <el-input type="textarea" v-model="bug_record.bug_detail" :autosize="{ minRows: 4, maxRows:8}"></el-input>
                </el-tooltip>
              </el-form-item>

              <el-form-item v-show="bug_record.bug_pic" label="图片详情">
              </el-form-item>

              <el-form-item label="需求类型">
                <el-select v-model="bug_record.bug_type" placeholder="请选择">
                  <el-option label="访客需求" value="访客需求"></el-option>
                  <el-option label="客户需求" value="客户需求"></el-option>
                  <el-option label="代理商需求" value="代理商需求"></el-option>
                  <el-option label="管理需求" value="管理需求"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="任务状态">
                <el-select v-model="bug_record.my_result" placeholder="新提交">
                  <el-option label="新提交" value="新提交"></el-option>
                  <el-option label="前端修复中" value="前端修复中"></el-option>
                  <el-option label="后端修复中" value="后端修复中"></el-option>
                  <el-option label="前端已修复" value="前端已修复"></el-option>
                  <el-option label="等待验收" value="等待验收"></el-option>
                  <el-option label="已修复" value="已修复"></el-option>
                  <el-option label="待商讨" value="待商讨"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="修复者" style="margin-left: 0px;width: 200px;">
                <el-tooltip class="item" effect="dark" content="谁更改了状态，谁在此签名" placement="right">
                  <el-input v-model="bug_record.fixed_name"></el-input>
                </el-tooltip>
              </el-form-item>
              <el-form-item label="审核结果">
                <el-select v-model="bug_record.author_result" placeholder="请选择">
                  <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="审核者" style="margin-left: 0px;width: 200px;">
                <el-tooltip class="item" effect="dark" content="谁提交的需求，谁在此签名" placement="right">
                  <el-input v-model="bug_record.submit_name"></el-input>
                </el-tooltip>
              </el-form-item>
              <el-form-item label="是否显示">
                <el-tooltip placement="left">
                  <div slot="content">关闭后不显示</div>is_show
                  <div class="class100">
                    <el-switch v-model="is_show" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                  </div>
                </el-tooltip>
              </el-form-item>
              <el-button type="primary" @click="onSubmit">修改</el-button>
              <el-button @click="cancel">取消</el-button>
            </el-form>
          </div>
        </el-main>
        <el-footer>
          <Footer />
        </el-footer>
      </el-container>
    </el-container>
    <router-view /><!-- 路由出口 -->
  </el-container>
</template>

<script>
  import LeftMenu from '@/components/LeftMenu0.vue'
  import HeaderTop from '@/components/Top.vue'
  import Footer from '@/components/Footer.vue'

  export default {
    name: 'bug_xg',
    // 注册组件
    components: {
      LeftMenu,
      HeaderTop,
      Footer
    },
    props: {},
    // data() 为变量赋值等
    data() {
      return {
        is_show: true,
        bug_record: {
          title: '',
          bug_detail: '',
          bug_pic: '',
          submit_time: '',
          fixed_time: '',
          author_result: '',
          my_result: '',
        },
        options: [{
          value: '新提交',
          label: '新提交'
        }, {
          value: '已修复',
          label: '已修复'
        }, {
          value: '待商讨',
          label: '待商讨'
        }],
        options2: [{
          value: '待审核',
          label: '待审核'
        }, {
          value: '前端已通过',
          label: '前端已通过'
        }, {
          value: '前端不通过',
          label: '前端不通过'
        }, {
          value: '后端不通过',
          label: '后端不通过'
        }, {
          value: '已通过',
          label: '已通过'
        }, {
          value: '待商讨',
          label: '待商讨'
        }],
      }
    },
    computed: {},
    watch: {},
    created() {
      // 获取id
      const rec_id = this.$route.query.id
      const that = this
      let req = {
        id: Number(rec_id),
      }
      this.$axios.post('/apis/bug/one', req)
        .then(function (res) {
          console.log(res)
          that.bug_record = res.data.Data
          that.is_show = true
          if (0 == res.data.Data.is_show) {
            that.is_show = false
          }
          that.is_show = true
          if (0 == res.data.Data.is_show) {
            that.is_show = false
          }
        })
    },
    methods: {
      onSubmit() {
        console.log('开始提交bug!');
        const that = this
        that.bug_record.is_show = 0
        if (true == that.is_show) {
          that.bug_record.is_show = 1
        }
        this.$axios.post('/apis/bug/xg', that.bug_record)
          .then(function (res) {
            console.log(res)
            if (res.data.Code == "200") {
              alert(res.data.Msg);
              window.history.go(-1);
            }
          })
      },
      cancel() {
        window.history.go(-1);
      }
    }
  }

</script>

<style scoped="scoped">
  .list_content {
    max-width: 800px;
  }

  .el-form-item__label {
    max-width: 200px !important;
  }

  /*大框体样式*/
  .el-main {

    height: calc(100vh - 120px);
  }

</style>
