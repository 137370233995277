<template>
    <el-container>
        <el-header>
            <HeaderTop />
        </el-header>
        <el-container>
            <el-aside width="200px">
                <LeftMenu />
            </el-aside>
            <el-container>
                <el-main>
                    <div class='list_content'>
                        <h2 style="text-align: center;">添加用户</h2>
                        <el-form ref="form" :model="user" label-width="80px">
                            <el-form-item label="*登陆账号" style="margin-left: 0px;">
                                <el-tooltip class="item" effect="dark" content="用户登录的账号，一般为客户的单位简称" placement="right">
                                    <el-input v-model="user.account"></el-input>
                                </el-tooltip>
                            </el-form-item>
                            <el-form-item label="*用户姓名" style="margin-left: 0px;">
                                <el-tooltip class="item" effect="dark" content="只是一个备注，用于联系客户的称呼，一般为客户方主要负责人的称呼或姓名" placement="right">
                                    <el-input v-model="user.user_name"></el-input>
                                </el-tooltip>
                            </el-form-item>
                            <el-form-item label="密码">
                                <el-input show-password v-model="user.old_pwd"></el-input>
                            </el-form-item>
                            <el-form-item v-show="show_level" label="级别">
                                <el-select v-model="user.level" placeholder="请选择">
                                    <el-option v-for="item in options_level" :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="用户状态">
                                <el-select v-model="user.is_use" placeholder="请选择">
                                    <el-option v-for="item in options_use" :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="对话链接" width="120px">
                                <el-tooltip class="item" effect="dark" placement="right">
                                    <div slot="content">
                                        　　1.商务通仅超链接:http://kft.zoosnet.net/LR/Chatpre.aspx?id=KFT22856036&lng=cn <br />
                                        　　2.快商通链接地址:http://z1-pcok6.kuaishangkf.com/bs/im.htm?cas=151444___689728&fi=150950<br />
                                        　　3.易聊网页直连配置：https://live.easyliao.com/live/chat.do?c=30546&g=32422&config=51346
                                    </div>
                                    <el-input v-model="user.swt_id">
                                        <el-select v-model="conf.dialog_type" slot="prepend" style="width:125px">
                                            <el-option label="商务通" value="商务通"></el-option>
                                            <el-option label="快商通" value="快商通"></el-option>
                                            <el-option label="易聊" value="易聊"></el-option>
                                        </el-select>
                                    </el-input>
                                </el-tooltip>
                            </el-form-item>
                            <el-form-item label="*主推站点" width="120px">
                                <el-input v-model="conf.tg_zd">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="有效期">
                                <el-col :span="11">
                                    <div class="block">
                                        <span class="demonstration"></span>
                                        <div class="demonstration"></div>
                                        <el-date-picker v-model="user.validity_time" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日 HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
                                        </el-date-picker>
                                        <el-checkbox v-show="show_permanent" v-model="permanent" style="padding-left: 15px;">永久
                                        </el-checkbox>
                                    </div>
                                </el-col>
                            </el-form-item>

                            <el-form-item v-show="show_level" label="上级单位">
                                <el-select v-model="user.superior_id" placeholder="请选择">
                                    <el-option label="寻牛" value="寻牛"></el-option>
                                    <el-option label="宿州天一" value="宿州天一"></el-option>
                                    <el-option label="宿州云脉" value="宿州云脉"></el-option>
                                    <el-option label="博硕科技" value="博硕科技"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item v-show="show_level" label="负责人">
                                <el-input v-model="user.principal"></el-input>
                            </el-form-item>

                            <el-form-item v-show="show_level" label="销售员">
                                <el-input v-model="user.sales"></el-input>
                            </el-form-item>

                            <el-form-item label="公司名称">
                                <el-input v-model="user.company_name"></el-input>
                            </el-form-item>
                            <el-form-item label="联系方式">
                                <el-input v-model="user.phone"></el-input>
                            </el-form-item>
                            <el-button type="primary" @click="onSubmit">确认</el-button>
                            <el-button @click="cancel">取消</el-button>
                        </el-form>
                    </div>
                </el-main>
                <el-footer>
                    <Footer />
                </el-footer>
            </el-container>
        </el-container>
        <router-view /><!-- 路由出口 -->
    </el-container>
</template>

<script>
import LeftMenu from '@/components/LeftMenu0.vue'
import HeaderTop from '@/components/Top.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'UserAdd',
    // 注册组件
    components: {
        LeftMenu,
        HeaderTop,
        Footer
    },
    props: {},
    // data() 为变量赋值等
    data() {
        return {
            show_level: true,
            show_permanent: true,
            permanent: '',
            user: {
                account: '',
                user_name: '',
                old_pwd: '',
                level: '',
                validity_time: '',
                company_name: '',
                phone: '',
                man_account: '',
                is_use: '',
                swt_id: '',
                dialog_v: '页内对话版',
            },
            conf: {
                is_copy_wx: 0,
                account: '',
                swt_code: '',
                web_code: '',
                is_open_shb: 1,
                swt_address: '',
                kst_address: '',
                yl_address: '',
                dialog_type: '',
                shb_show_time: 0,
                is_first_enter: 2,
                first_enter_time: 30,
                next_enter_time: 30,
                phone: '',
                wx_number: '',
                kf_card: '',
                custom_pic: '',
                kf_pic: '',
                krsm: '',
                key_word: '',
                top_title: '',
                cnzz: '',
                bottom_title: ''
            },
            options_level: [{
                value: 3,
                label: '普通用户'
            }, {
                value: 4,
                label: '正式会员'
            }, {
                value: 5,
                label: '销售人员'
            }, {
                value: 6,
                label: '测试案例账号'
            }],
            options_use: [{
                value: 1,
                label: '启用'
            }, {
                value: 0,
                label: '禁用'
            }]
        }
    },
    // created 在模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图
    created() {
        // 从缓存获取user
        let cache_user = sessionStorage.getItem('user')
        const cache_user_obj = JSON.parse(cache_user)
        if (cache_user_obj.level == 2) {
            this.options_level = [{
                value: 3,
                label: '普通用户'
            }]
            this.show_permanent = false
        }
        if (cache_user_obj.level == 3 || cache_user_obj.level == 4) {
            this.show_level = false
            this.show_permanent = false
        }
    },
    // mounted在模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作
    methods: {
        cancel() {
            history.go(-1)
        },
        onSubmit() {
            // 从缓存获取user
            let cache_user = sessionStorage.getItem('user')
            console.log('开始添加!');
            console.log('cache_user!', cache_user);
            const cache_user_obj = JSON.parse(cache_user)
            console.log("accoun", cache_user_obj.account)
            this.user.man_account = cache_user_obj.account
            this.conf.account = this.user.account
            console.log("--- permanent ---", this.permanent)
            if (this.permanent) {
                let now = new Date();
                // now.setDate(now.getDate()+(365*100))
                let new_data_str = (now.getFullYear() + 100) + '-' + (now.getMonth() + 1) + '-' + now.getDate() + ' ' + now
                    .getHours() + ':' + now.getMinutes() + ':' + now.getSeconds()
                this.user.validity_time = new_data_str
                console.log(" --- new_data_str ---", now.getMonth())
            }
            let req = this.conf
            if (req.dialog_type == "商务通") {
                req.swt_address = this.user.swt_id
            }
            if (req.dialog_type == "快商通") {
                req.kst_address = this.user.swt_id
            }
            if (req.dialog_type == "易聊") {
                req.yl_address = this.user.swt_id
            }

            const that = this
            this.$axios.post('/apis/user/add', that.user)
                .then(function (res) {
                    console.log("date11", typeof (that.user.validity_time))
                    console.log("date", that.user.validity_time)
                    if (res.data.Code === '200') {
                        console.log(res);
                        that.$axios.post('/apis/conf/shb/setting', req).then(function (res2) {
                            console.log("返回值打印-----", res2)
                            if (res2.data.Code === '200') {
                                alert('添加成功!')
                                history.go(-1)
                            } else {
                                alert(res2.data.Msg)
                                return
                            }
                        })
                    } else {
                        alert(res.data.Msg)
                        return
                    }
                })
        }
    }
}

</script>

<style scoped="scoped">
/*大框体样式*/
.el-main {
    height: calc(100vh - 120px);
}
.list_content {
    max-width: 800px;
}

.el-form-item__label {
    max-width: 200px !important;
}
</style>
