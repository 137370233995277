<template>
  <div class="wx_chat" :style='all_style'>
        <!-- 笑脸 -->
    <div id="smile_box">
        <div class="smile">
            <div class="faceabc">
            <img src="../../assets/img/a.gif" alt="...">
            </div>
            <h3>{{res_data.Data.phone}}</h3>
            <div class="mybtn">

            <el-button type="primary" @click="bdPhone">拨打电话</el-button>
            <el-button type="info" @click="jxzx">返回咨询</el-button>
            </div>
            <h2>打字不方便</h2>
            <h2>马上电话咨询</h2>
            <!--<button onclick='bodh1()'>bodh1</button>-->
        </div>
    </div>
    <div id="smile_zhezhao"></div>
    <!-- <div class="wx_chat" style="height: 200px;line-height: 400px;"> -->
    <div class="wx_head" :style='head_style'>
        <div class="head_box">
            <div class="head_right">
                <h2 class="tel_a"><i class='iconfont icon-dianhua2'></i></h2>
                <h2 class="tel_b" @click="bdPhone">{{headContnet.phone}}</h2>
            </div>
            
            <!-- <div class="head_left">
                <h2>
                <el-button type="text" @click="goBack"><i class="el-icon-close close"></i></el-button>
                </h2>
                
            </div> -->
            <!-- <h2>{{headContnet.top_title}}</h2>-->
        </div>
        <div class="head_title" :style='head_style'>
            <i class="el-icon-lock"></i>
            <a style="font-size:10px;margin:0 10px;">{{res_data.Data.item_type}}</a>
            <i class="el-icon-arrow-right" style="float:right;"></i>
        </div>
    </div>

    <!-- 这里有东西 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="70%">
      <span style="white-space: pre">{{back_msg}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="gogoBack">{{btn_text}}</el-button>
      </span>
    </el-dialog>

    <div class="wx_content">

          <div id="main_video">
          <div style="height: 25px; background-color:#ebebeb;"></div>
            <div style="position: relative;">
                <!-- <div id="video_play" @click="playvideo">
                </div> -->
                <!-- <img class="video_imp" src="http://share.hebeifuke.com/wap/newchat/picture/7e0a0c72de51c469dac573186c003fb6.jpg"> -->
                <video id="zhen_video" :src='res_data.Data.kf_video' controls="controls">
                </video>
                <div class="block" id="block">
                    <el-carousel trigger="click" height="400px">
                    <el-carousel-item v-for="item in lbt" :key="item">
                        <img class="video_imp" :src='item' >
                    </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
          <div class="video_context">
              <div class="video_context_left"><img :src="kf_pic" style="width:50px;height:50px;border-radius: 50%; "></div>
              <div class="video_context_right">
                  <div class="top_title" style="line-height:20px;height:20px;border-bottom:0;">{{res_data.Data.item_c_n}}</div>
                  <div class="context_right_details">{{res_data.Data.item_chara}}</div>
                  <div class="context_right_details">{{headContnet.phone}}</div>
              </div> 
          </div>
          <div class="video_secbottom">{{res_data.Data.item_describe}}</div>
          <div class="video_bottom">
                <div class="video_secbottom">
                    <a>7天已累计</a><a style="color:red">{{zixun}}</a><a>人咨询</a>
                    <div style="float:right;margin-right:5px;">
                        <button @click="searchIn">点我咨询</button>
                    </div>
                </div>
          </div>
      </div>

      <!-- 专题样式  -->
      <!-- <div class="bs_zt" v-show="show_item">
        <div class="bs_zt_about">
          <div class="bs_zt_about_left">
            <img :src="res_data.Data.company_pic1" />
          </div>
          <div class="bs_zt_about_right">
            <b>{{res_data.Data.item_n_n}}：</b><b>{{res_data.Data.item_name}}</b><br />
            <b>{{res_data.Data.item_t_n}}：</b>{{res_data.Data.item_type}}<br />
            <b>{{res_data.Data.item_d_n}}：</b>{{res_data.Data.item_describe}}
            <br />
            <b>{{res_data.Data.item_c_n}}：</b><span style="color: #FC89A8;">{{res_data.Data.item_chara}}</span>
          </div>
        </div>
        <div class="bs_zt_hj">
          <h5>{{res_data.Data.company_p2_n}}：</h5>
          <img :src="res_data.Data.company_pic2" />
        </div>
      </div> -->
      <!-- 专题样式 -->

      <p v-show="is_show_kcb" style="text-align: center;margin:10px;font-size: 12px;color: #a3a3a3;">{{begin_sentence2}}
      </p>
      <div class="content_list" v-for="(item,index) in tableData" v-bind:key="index" style="width: 100%">
        <div class="kefu" :style=item.kefu_style>
          <div class="face2">
            <img :src="item.kf_pic" />
          </div>
          <p v-html="item.left_msg" class="kefu_nr" :style='item.left_style'></p>
        </div>
        <div class="fangke" :style='item.fangke_style'>
          <div class="face1"><img :src="item.custom_pic" /></div>
          <p v-html="item.right_msg" class="fangke_nr" :style='item.right_style'></p>
        </div>
      </div>
    </div>
    <div class="face_package" id="face_package" style="display:none;background-color: white;">
      <img src="../../assets/img/1.gif" @click="fuzhi(1)" />
      <img src="../../assets/img/2.gif" @click="fuzhi(2)">
      <img src="../../assets/img/3.gif" @click="fuzhi(3)">
      <img src="../../assets/img/4.gif" @click="fuzhi(4)">
      <img src="../../assets/img/5.gif" @click="fuzhi(5)">
      <img src="../../assets/img/6.gif" @click="fuzhi(6)">
      <img src="../../assets/img/7.gif" @click="fuzhi(7)">
      <img src="../../assets/img/8.gif" @click="fuzhi(8)">
      <img src="../../assets/img/9.gif" @click="fuzhi(9)">
      <img src="../../assets/img/10.gif" @click="fuzhi(10)">
      <img src="../../assets/img/11.gif" @click="fuzhi(11)">
      <img src="../../assets/img/12.gif" @click="fuzhi(12)">
      <img src="../../assets/img/13.gif" @click="fuzhi(13)">
      <img src="../../assets/img/14.gif" @click="fuzhi(14)">
      <img src="../../assets/img/15.gif" @click="fuzhi(15)">
      <img src="../../assets/img/16.gif" @click="fuzhi(16)">
      <img src="../../assets/img/17.gif" @click="fuzhi(17)">
    </div>
    <div class="add_package" id="add_package" style="display:none;background-color: white;">
      <img src="../../assets/img/picture.png" />
      <img src="../../assets/img/folder.png" />
      <img src="../../assets/img/evaluation.png" />
    </div>
    <div class="tool">
        <div class="btn_box">
          <el-button round @click="bdPhone">拨打电话</el-button>
      </div>
      <div class="my_face mine" @click="show_face_package">
        <img src="/images/skin/jdb_face.gif">
      </div>
      <div class="my_tp mine" @click="onSubmit">
        <img src="/images/skin/jdb_tp.gif">
      </div>
      <div class="my_pj mine" @click="onSubmit">
        <img src="/images/skin/jdb_pj.gif">
      </div>
      <div class="my_dh mine">
        <div class="my_dhi"><i class="iconfont icon-tel-fill"></i></div>
        <div class="my_dhnr" @click="bdPhone">{{headContnet.phone}}</div>
      </div>
    </div>
    <div class="wx_input">
      <div class="input">
        <textarea type="text" name="put" id="put" class="inpt" wrap="virtual" v-model="input_mesg" placeholder="请在此输入……" v-on:input="show_fasong" @focus="clear" ref="inputVal"></textarea>

      </div>
      <div class="submit">
        <span class="el-icon-circle-plus-outline add_sbt" @click="add_package" style="display: none;"></span>
         <el-button type="primary" @click="onSubmit" style="height:40px">发送</el-button> 
      </div>
    </div>
    <div class="wx_bottom">{{bottomContent.bottomText}}</div>
    <a ref="tz_dialog_page" style="display:none">1231231</a>
  </div>
</template>
<script>
  export default {
    name: 'WxChat',
    data() {
      return {
        lbt: [],
        zixun:'123',
        btn_text: '确定',
        back_msg: '',
        dialogVisible: false,
        fk_id: 0,
        ref: '',
        show_item: false,
        is_show_kcb: true,
        fk_ip: '',
        tz_settime: 'ok',
        begin_sentence: '',
        begin_sentence_style: {
          display: 'none'
        },
        begin_sentence2: '',
        custom_pic: require('../../assets/img/face2.png'),
        kf_pic: require('../../assets/img/face1.png'),
        // 请求返回的数据
        res_data: {
          Data: {
            company_pic1: '',
            item_n_n: '',
            item_name: '',
            item_t_n: '',
            item_type: '',
            item_d_n: '',
            item_describe: '',
            item_c_n: '',
            item_chara: '',
            company_p2_n: '',
            company_pic2: '',
          },
        },
        search_key: '',
        // 后退链接
        watchPara: '',
        backLink: 'http:///www.baidu.com',
        isBackLink: 1,


        // 绑定聊天框样式
        all_style: {
          height: '',
        },

        // 绑定头部内容
        headContnet: {
          phone: '173 6448 6668',
          top_title: '客服在线咨询',
        },

        // 绑定头部style样式
        head_style: {
          backgroundColor: '#393A3F',
        },

        // 绑定底部内容
        bottomContent: {
          bottomText: '博硕科技·版权所有',
        },

        input_mesg: '',
        contnetStyle: {
          left_style: {
            backgroundColor: 'white',
            color: 'black',
          },
          right_style: {
            backgroundColor: '#67C23A',
            color: 'red',
          }
        },
        tableData: [],
      }
    },

    created() {
      console.log('create .......')
      this.changePageBefore()
      window.bdPhone = this.bdPhone
      window.cpWx = this.cpWx
      window.convs = this.convs
    },

    mounted() {
    this.zixun=parseInt(Math.random()*(800-500+1)+500); 
      console.log('updatePageLater .......')
          //   if (document.getElementById("zhen_video").paused) { }
    //   else {document.getElementById("video_play").style.display="none";}
      var myVideo=document.getElementById("zhen_video");  
      myVideo.addEventListener('play',function(){  
               document.getElementById("video_play").style.display="none";
      });  
      myVideo.addEventListener('pause',function(){  
          document.getElementById("video_play").style.display="block";
      });
      //   this.updatePageLater()
      
    },
    updated() {
      console.log('updateud 执行了 ...')
      // 聊天定位到底部
      let ele = document.getElementsByClassName('wx_content')[0];
      ele.scrollTop = ele.scrollHeight;


        
    },

    methods: {
        searchIn(){
        this.searchBtnFlag = !this.searchBtnFlag;
        this.$nextTick(function () {
            //DOM 更新了
            this.$refs.inputVal.focus()
        })
        },
        playvideo(){
            let zhen_video=document.getElementById("zhen_video"); 
            if (zhen_video.paused) {zhen_video.play();}
            else {zhen_video.pause(); }
        },
      async tzSmile(phone1) {
        this.$router.push({
          path: '/smile',
          query: {
            phone: await this.cacheWx(phone1)
          }
        })
      },
      jxzx(){
            document.getElementById("smile_box").style.display='none'
            document.getElementById("smile_zhezhao").style.display='none'
        },
      gogoBack() {
        this.dialogVisible = false
        let res_data1 = this.res_data.Data
        switch (res_data1.back_type) {
          case '2':
            this.tzSmile(res_data1.phone)
            break;
          case '3':
            this.bdPhone()
            break;
          case '6':
            this.cpWx()
            break;
          default:
            this.tzSmile(res_data1.phone)
            break;
        }
      },
      goBack() {
        let res_data = this.res_data.Data
        this.back_msg = '您确定退出吗？'
        switch (res_data.back_type) {
          case "1":
            this.dialogVisible=true
            this.back_msg = res_data.back_msg
            break;
          case "2":
            document.getElementById("smile_box").style.display='block'
            document.getElementById("smile_zhezhao").style.display='block'
            break;
          case "3":
            this.dialogVisible=true
            this.back_msg = '有需要可以拨打电话咨询:\n' + res_data.phone
            this.btn_text='拨打'
            break;
          case "6":
            this.dialogVisible=true
            this.back_msg = '有需要可以微信咨询:' + res_data.wx_number
            break;
          case '7':
            window.location.href = res_data.back_link
            break;
          default:
            this.dialogVisible=true
            this.back_msg = '您确定退出吗？'
            break;
        }
      },
      convs: function (obj) {
        this.input_mesg += obj
        this.onSubmit()
      },
      iosAutoFd: function () {
        document.addEventListener('gesturestart', function (e) {
          console.log('gesturestart');
          e.preventDefault();
        });

        // 在单个元素上单击两次 ===  dblclick
        document.addEventListener('dblclick', function (e) {
          console.log('dblclick');
          e.preventDefault();
        });

        // 一个手指放在屏幕上时，会触发 touchstart 事件
        document.addEventListener('touchstart', function (event) {
          console.log('touchstart');
          if (event.touches.length > 1) {
            event.preventDefault();
          }
        });

        // 如果一个或两个手指在屏幕上滑动，将会触发 gesturechange 事件。
        // 但只要有一个手指移开， 就会触发 gestureend 事件，紧接着又会触发基于该手指的 touchend 事件。
        var lastTouchEnd = 0;
        document.addEventListener('touchend', function (event) {
          console.log('touchend');
          var now = (new Date()).getTime();
          // 如果在300ms内触发两次touchend，就阻止默认事件
          if (now - lastTouchEnd <= 300) {
            event.preventDefault();
          }
          lastTouchEnd = now;
        }, false);
      },

      yndh: function () {
        console.log('yndh .......', this.res_data)
        // 引入 swt 迷你窗口
        let tz_url = this.TzUrl(this.res_data.Data, this.search_key, '')
        console.log('tz_url 11111', tz_url)
        let new_iframe = document.createElement('iframe')
        new_iframe.src = tz_url
        new_iframe.id = 'swt_iframe'
        new_iframe.name = 'swt_iframe'
        new_iframe.style.display = 'none'
        new_iframe.style.width = "1px"
        new_iframe.style.height = "1px"
        new_iframe.style.zIndex = "666"
        new_iframe.style.position = "absolute"
        document.body.appendChild(new_iframe)

        let that = this
        // 监听商务通迷你对话窗返回消息
        window.addEventListener('message', function (e) {
          var value = e.data;
          if (typeof (value) == 'object') {
            if (value['from'] == 'child') {
              console.log('parent value:', value)
              that.tableData.push({
                custom_pic: that.custom_pic,
                kf_pic: that.kf_pic,
                fangke_style: {
                  display: 'none',
                },
                left_msg: value['content'],
                left_style: {
                  display: 'block',
                  backgroundColor: that.contnetStyle.left_style.backgroundColor,
                  borderLeftColor: that.contnetStyle.left_style.backgroundColor,
                },
                right_msg: '',
                right_style: {
                  display: 'none',
                  backgroundColor: '',
                },
              })
            }
          }
        }, false)
      },

      changePageBefore: async function () {
        await this.getShbConf()
        // 执行其它操作
        await this.updatePageBefore()
        this.updatePageLater()
        if(this.res_data.Data.kf_video=='') { 
            document.getElementById('zhen_video').style.display='none';
            document.getElementById('block').style.display='block';
            }
        this.lbt=this.res_data.Data.kf_lb_pics.split('(bssplit)')
      },

      async onSubmit() {
        console.log('-----------------------')
        await this.getShbConf()
        let contList = this.tableData
        let $msg = this.input_mesg
        if (this.input_mesg != '') {
          let new_element = {
            custom_pic: this.custom_pic,
            kf_pic: this.kf_pic,
            right_msg: this.input_mesg,
            right_style: {
              backgroundColor: this.contnetStyle.right_style.backgroundColor,
              borderRightColor: this.contnetStyle.right_style.backgroundColor,
              color: this.contnetStyle.right_style.color
            },
            left_msg: '',
            left_style: {
              backgroundColor: this.contnetStyle.left_style.backgroundColor
            },
            kefu_style: {
              display: 'none',
            }
          }
          contList.push(new_element)
          this.input_mesg = ''
          this.is_break_auto_reply = true
        }
        // 如果是页内对话版
        if (this.res_data.Data.is_open_yndh == 1) {
          window.frames.swt_iframe.postMessage($msg, "*")
        } 
      },
      TzFUnc(res_data, tz_url, type) {
        if (res_data.is_auto_tz == 0 && type != 'click') {
          return
        }
        let tz_dialog_page = this.$refs.tz_dialog_page
        tz_dialog_page.href = tz_url
        tz_dialog_page.click()
      },
      replaceKey(keyStr, key) {
        if (keyStr != '' && keyStr != undefined) {
          keyStr = keyStr.replace('[key]', key)
        }
        return keyStr
      },
      TzUrl(res_data, search_key, input_mesg) {
        if (res_data != undefined) {
          let is_cs_mesgs = false
          let cs_mesgs = ''
          if (res_data.is_show_auto_reply == 1) {
            for (let single_msg of this.tableData) {
              if (single_msg.right_msg != '' && single_msg.right_msg != undefined) {
                is_cs_mesgs = true
                cs_mesgs = cs_mesgs + single_msg.right_msg + 'bssplit'
              }
              if (single_msg.left_msg != '' && single_msg.left_msg != undefined) {
                is_cs_mesgs = true
                cs_mesgs = cs_mesgs + single_msg.left_msg + 'bssplit'
              }
            }
            if (is_cs_mesgs == true) {
              cs_mesgs = '&cs_mesgs=' + encodeURIComponent(cs_mesgs)
              console.log('--- cs_mesgs ---', cs_mesgs)
            }
          }

          let key = ''
          let cs_input_mesg = ''
          let cs_krsm = ''
          console.log('--- search_key ---', search_key)

          if (search_key != '') {
            key = '&wd=' + encodeURIComponent(search_key)
          } else {
            key = ''
          }
          if (input_mesg != '') {
            cs_input_mesg = '&input_msg=' + encodeURIComponent(input_mesg)
          }

          if (res_data.krsm != '') {
            cs_krsm = '&e=' + '客人说明:' + encodeURIComponent(res_data.krsm + '   搜索关键词:' + search_key)
          }
          let cs_account = '&account=' + encodeURIComponent(res_data.account)
          let ref = document.referrer
          console.log('ref:', ref)
          let tz_url1 = res_data.swt_address
          let from_page = '&r=' + encodeURIComponent(this.ref)
          let my_page = '&p=' + encodeURIComponent(ref)
          let input_wd = ''
          let clickText = ''

          console.log('---------res_data.dialog_type---------:', res_data.dialog_type)
          switch (res_data.dialog_type) {
            case '商务通':
              tz_url1 = res_data.swt_address
              break;
            case '快商通':
              tz_url1 = res_data.kst_address
              from_page = '&ref=' + encodeURIComponent(this.ref)
              cs_krsm = '&sText=' + '客人说明:' + encodeURIComponent(res_data.krsm + '   搜索关键词:' + search_key)
              break;
            case '易聊':
              tz_url1 = res_data.yl_address
              from_page = '&ref=' + encodeURIComponent(this.ref)
              my_page = '&chatUrl=' + encodeURIComponent(ref)
              input_wd = '&input_wd=' + encodeURIComponent(search_key)
              clickText = '&clickText=' + encodeURIComponent(search_key)
              break;
          }

          let fk_id = '&fk_id=' + encodeURIComponent(this.fk_id)
          let tz_url = tz_url1 + key + cs_input_mesg + cs_account + cs_krsm + cs_mesgs + from_page + my_page +
            input_wd + clickText + fk_id
          console.log('---------tz_url3333------- --:', tz_url)
          return tz_url
        }
      },

      getSearchString(Url) {
        var str = Url;
        var strArr = str.split("?"); // 获取URL中?之后的字符（去掉第一位的问号）
        if (strArr.length > 1) {
          str = strArr[1]
          // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
          var arr = str.split("&");
          var obj = new Object();
          // 将每一个数组元素以=分隔并赋给obj对象
          for (var i = 0; i < arr.length; i++) {
            var tmp_arr = arr[i].split("=");
            obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
          }
          return obj;
        }
        return {};
      },

      async getShbConf() {
        // 获取链接参数
        let link_str = window.location.href
        console.log("link_str:", link_str) // accont,name
        let parms = this.getSearchString(link_str)
        console.log("----parms----:", parms) // accont,wd
        if (parms.hasOwnProperty('keyword')) {
          this.search_key = parms['keyword']
        }
        if (parms.hasOwnProperty('ip')) {
          this.fk_ip = parms['ip']
        }
        if (parms.hasOwnProperty('ref')) {
          this.ref = parms['ref']
        }
        if (parms.hasOwnProperty('account')) {
          // 获取升话宝设置
          let req = {
            account: parms['account']
          }
          let that = this
          await this.$axios.post('/apis/conf/shb', req).then(function (res) {
            that.res_data = res.data
            console.log('---- res.data.begin_sentence2 ----', res.data.Data.begin_sentence2)
            if (res.data.Data.begin_sentence2 != '') {
              that.begin_sentence = res.data.Data.begin_sentence
              that.begin_sentence2 = res.data.Data.begin_sentence2
            }
            if (res.data.Data.is_open_kcb == 1) {
              that.is_open_kcb = true
            } else {
              that.is_show_kcb = false
            }
            if (res.data.Data.custom_pic != '') {
              that.custom_pic = res.data.Data.custom_pic
            }
            if (res.data.Data.kf_pic != '') {
              that.kf_pic = res.data.Data.kf_pic
            }
            if (res.data.Data.is_use_item == 1) {
              that.show_item = true
            } else {
              that.show_item = false
            }
            console.log("返回值打印:", that.res_data)
          })
          let req_ip = {
            ip: that.fk_ip
          }
          await this.$axios.post('/apis/fkrecord/list', req_ip).then(function (res_ip) {
            console.log("res_ip返回值打印:", res_ip.data.Data)
            if (Number(res_ip.data.Data) > 0) {
              that.res_data.Data.is_first_enter = 1
            }
          })
        }
      },
      // 更新页面
      async updatePageLater() {
        let that = this
        console.log("this.res_data.Data值打印:", this.res_data)

        let res_data = this.res_data.Data
        if (this.res_data.Code == '200') {
          // 发送关键词
          console.log("是否发送关键词:", res_data.is_send_keyword, that.search_key)
          if (res_data.is_send_keyword == 1) {
            let real_key = res_data.key_word
            if (that.search_key != '') {
              real_key = that.search_key
            } else {
              that.search_key = res_data.key_word
            }
            that.tableData.push({
              custom_pic: that.custom_pic,
              kf_pic: that.kf_pic,
              kefu_style: {
                display: 'none',
              },
              left_msg: '',
              left_style: {
                display: 'none',
                backgroundColor: 'green',
              },
              right_msg: real_key,
              right_style: {
                display: 'block',
                backgroundColor: that.contnetStyle.right_style.backgroundColor,
                borderRightColor: that.contnetStyle.right_style.backgroundColor,
                color: that.contnetStyle.right_style.color,
              },
            })
          } else {
            that.search_key = ''
          }

          let get_ua = navigator.userAgent
          let UAObj = require('ua-device');
          let res_ua = new UAObj(get_ua);
          console.log('UA:', res_ua);
          let req_ip_add = {
            ip: that.fk_ip,
            account: res_data.account,
            key: that.search_key,
            from_url: this.ref,
            chat_url: document.referrer,
            browser: res_ua.browser.name,
            client_type: res_ua.device.type + '-' + res_ua.device.model + '-' + res_ua.os.name
          }
          await that.$axios.post('/apis/fkrecord/add', req_ip_add).then(function (res_add_ip) {
            console.log("添加访客记录回调-----", res_add_ip)
            that.fk_id = res_add_ip.data.Data
            console.log("添加访客记录回调 fk_id-----", res_add_ip.data.Data)
          })

          if (res_data.is_open_yndh == 1) {
            this.yndh()
          }

          // 发送开场白
          if (res_data.begin_sentence != '') {
            that.begin_sentence_style = {
              display: 'block',
              fontSize: '14px',
              margin: '10px',
              borderRadius: '5px',
              backgroundColor: '#fff',
              padding: '5px',
              lineHight: '20px'
            }
          }


          // 更新成非第一次
          let time_num = 0
          let stay_time = 0
          // 开启了升话宝停留时间
          // stay_time = res_data.shb_show_time
          if ((res_data.is_first_enter == 0) && (res_data.first_enter_time > 0)) {
            stay_time = res_data.first_enter_time
          }
          if ((res_data.is_first_enter == 1) && (res_data.next_enter_time > 0)) {
            stay_time = res_data.next_enter_time
          }

          if (stay_time != 0) {
            if (res_data.is_show_auto_reply == 1) {
              let cancel_Interval = window.setInterval(() => {
                if (1 == res_data.is_break_auto_reply && true == that.is_break_auto_reply) {
                  return
                }
                time_num += 1
                if ((res_data.is_send_first != 0) && (res_data.first_sj == time_num) && (res_data
                    .auto_reply_first !=
                    '')) {
                  that.tableData.push({
                    custom_pic: that.custom_pic,
                    kf_pic: that.kf_pic,
                    fangke_style: {
                      display: 'none',
                    },
                    left_msg: that.replaceKey(res_data.auto_reply_first, that.search_key),
                    left_style: {
                      display: 'block',
                      backgroundColor: that.contnetStyle.left_style.backgroundColor,
                      borderLeftColor: that.contnetStyle.left_style.backgroundColor,
                    },
                    right_msg: '',
                    right_style: {
                      display: 'none',
                      backgroundColor: '',
                    },
                  })
                }

                if ((res_data.is_send_second != 0) && (res_data.second_sj == time_num) && (res_data
                    .auto_reply_second !=
                    '')) {
                  that.tableData.push({
                    custom_pic: that.custom_pic,
                    kf_pic: that.kf_pic,
                    fangke_style: {
                      display: 'none',
                    },
                    left_msg: that.replaceKey(res_data.auto_reply_second, that.search_key),
                    left_style: {
                      display: 'block',
                      backgroundColor: that.contnetStyle.left_style.backgroundColor,
                      borderLeftColor: that.contnetStyle.left_style.backgroundColor,
                    },
                    right_msg: '',
                    right_style: {
                      display: 'none',
                      backgroundColor: '',
                    },
                  })
                }

                if ((res_data.is_send_third != 0) && (res_data.third_sj == time_num) && (res_data
                    .auto_reply_third !=
                    '')) {
                  that.tableData.push({
                    custom_pic: that.custom_pic,
                    kf_pic: that.kf_pic,
                    fangke_style: {
                      display: 'none',
                    },
                    left_msg: that.replaceKey(res_data.auto_reply_third, that.search_key),
                    left_style: {
                      display: 'block',
                      backgroundColor: that.contnetStyle.left_style.backgroundColor,
                      borderLeftColor: that.contnetStyle.left_style.backgroundColor,
                    },
                    right_msg: '',
                    right_style: {
                      display: 'none',
                      backgroundColor: '',
                    },
                  })
                }

                if ((res_data.is_send_fourth != 0) && (res_data.fourth_sj == time_num) && (res_data
                    .auto_reply_fourth !=
                    '')) {
                  that.tableData.push({
                    custom_pic: that.custom_pic,
                    kf_pic: that.kf_pic,
                    fangke_style: {
                      display: 'none',
                    },
                    left_msg: that.replaceKey(res_data.auto_reply_fourth, that.search_key),
                    left_style: {
                      display: 'block',
                      backgroundColor: that.contnetStyle.left_style.backgroundColor,
                      borderLeftColor: that.contnetStyle.left_style.backgroundColor,
                    },
                    right_msg: '',
                    right_style: {
                      display: 'none',
                      backgroundColor: '',
                    },
                  })
                }

                if ((res_data.is_send_five != 0) && (res_data.five_sj == time_num) && (res_data.auto_reply_five != '')) {
                  that.tableData.push({
                    custom_pic: that.custom_pic,
                    kf_pic: that.kf_pic,
                    fangke_style: {
                      display: 'none',
                    },
                    left_msg: that.replaceKey(res_data.auto_reply_five, that.search_key),
                    left_style: {
                      display: 'block',
                      backgroundColor: that.contnetStyle.left_style.backgroundColor,
                      borderLeftColor: that.contnetStyle.left_style.backgroundColor,
                    },
                    right_msg: '',
                    right_style: {
                      display: 'none',
                      backgroundColor: '',
                    },
                  })
                }

                if ((res_data.is_send_six != 0) && (res_data.six_sj == time_num) && (res_data.auto_reply_six != '')) {
                  that.tableData.push({
                    custom_pic: that.custom_pic,
                    kf_pic: that.kf_pic,
                    fangke_style: {
                      display: 'none',
                    },
                    left_msg: that.replaceKey(res_data.auto_reply_six, that.search_key),
                    left_style: {
                      display: 'block',
                      backgroundColor: that.contnetStyle.left_style.backgroundColor,
                      borderLeftColor: that.contnetStyle.left_style.backgroundColor,
                    },
                    right_msg: '',
                    right_style: {
                      display: 'none',
                      backgroundColor: '',
                    },
                  })
                }

                console.log('----', time_num)
                if (time_num == stay_time) {
                  clearInterval(cancel_Interval)
                  if (res_data.is_open_yndh != 1) {
                    let tz_url = this.TzUrl(res_data, that.search_key, '')
                    this.TzFUnc(res_data, tz_url, 'auto')
                  }
                }
              }, 1000);
            } else {
              setTimeout(() => {
                if (res_data.Data.is_open_yndh != 1) {
                  let tz_url = this.TzUrl(res_data, that.search_key, '')
                  this.TzFUnc(res_data, tz_url, 'auto')
                }
              }, Number(stay_time) * 1000);
            }
          }
        }
      },

      updatePageBefore() {
        console.log('-----------------------before-------------------')
        let that = this
        let res_data = this.res_data.Data
        if (this.res_data.Code == '200') {
          // 复制微信
          if (res_data.is_copy_wx != 0) {
            // 复制微信方法
          }

          // 后退链接
          if (res_data.back_link != '') {
            that.backLink = res_data.back_link
            that.isBackLink = res_data.is_back_link
          }

          // banIP
          if (res_data.ban_ip != '') {
            //    banIP
          }

          // ban_time
          if (res_data.ban_time != '') {
            //    ban_time
          }

          // ban_city
          if (res_data.ban_city != '') {
            //    ban_city
          }

          if (res_data.bkg_color != '') {
            console.log("bkg_color", res_data.bkg_color)
            // 修改头部背景颜色
            that.head_style.backgroundColor = res_data.bkg_color
          }

          // 修改用户回复背景颜色
          if (res_data.reply_color != '') {
            that.contnetStyle.right_style.backgroundColor = res_data.reply_color
          }

          if (res_data.font_color != '') {
            // 修改字体颜色
            that.contnetStyle.right_style.color = res_data.font_color
          }

          if (res_data.is_bp == 1) {
            // that.all_style.height = '500px'
          }

          // phone
          if (res_data.phone != '') {
            that.headContnet.phone = res_data.phone
          }

          // wx_number
          if (res_data.wx_number != '') {
            that.wx_number = res_data.wx_number
          }

          // custom_pic
          if (res_data.custom_pic != '') {
            that.custom_pic = res_data.custom_pic
          }

          // kf_pic
          if (res_data.kf_pic != '') {
            that.kf_pic = res_data.kf_pic
          }

          // krsm
          if (res_data.krsm != '') {
            that.headContnet.krsm = res_data.krsm
          }

          // top_title
          if (res_data.top_title != '') {
            that.headContnet.top_title = res_data.top_title
          }

          // bottom_title
          if (res_data.bottom_title != '') {
            that.bottomContent.bottomText = res_data.bottom_title
          }

          // cnzz
          if (res_data.cnzz != '') {
            that.headContnet.cnzz = res_data.cnzz
          }
        }
      },
      show_face_package() {
        var face_package = document.getElementsByClassName("face_package");
        console.log(face_package[0].style.display);
        var zhizhen = face_package[0].style.display;
        if (zhizhen == "none") {
          face_package[0].style.display = "block";
        } else {
          face_package[0].style.display = "none";
        }
      },
      add_package() {
        var add_package = document.getElementsByClassName("add_package");
        var zhizhen = add_package[0].style.display;
        if (zhizhen == "none") {
          add_package[0].style.display = "block";
        } else {
          add_package[0].style.display = "none";
        }
      },
      clear() {
        var put = document.getElementById("put");
        put.value = "";
      },
      show_fasong() {
        //      var add = document.getElementsByClassName("add_sbt");
        //      var fasong = document.getElementById("fasong_sbt");
        //      var put = document.getElementById("put");
        //      console.log(put.value);
        //      if (put.value == "") {
        //        add[0].style.display = "block";
        //        fasong.style.display = "none";
        //      } else {
        //        add[0].style.display = "none";
        //        fasong.style.display = "block";
        //      }
      },
      //构造函数
      fuzhi(i) {
        var add = document.getElementsByClassName("add_sbt");
        var fasong = document.getElementById("fasong_sbt");
        var put = document.getElementById("put");
        put.value = "[:" + i + ":]";
        add[0].style.display = "none";
        document.getElementById('face_package').style.display = 'none';
        fasong.style.display = "block";
        //	add[0].style.display="block";
        //	fasong.style.display="none";
      },

      async cacheWx(vxOrPhone) {
        let req = {
          Uid: Number(this.fk_id),
        }
        await this.$axios.post('/apis/cache/wx', req).then(function (res) {
          console.log('/apis/cache/wx', res)
          if (res.data.Code == '200') {
            let resArr = res.data.Data
            if (resArr != undefined) {
              if (resArr.length > 0) {
                vxOrPhone = resArr[0].WxNumber
              }
            }
          }
        })
        return vxOrPhone
      },

      bdPhone: async function () {
        let phone = await this.cacheWx(this.headContnet.phone)
        window.open('tel://' + phone)
      },
      cpWx: async function () {
        let wx_number = await this.cacheWx(this.res_data.Data.wx_number)
        this.$copyText(wx_number).then(function (e) {
          alert('微信复制成功!', e)
        })
      }

    }
  }

</script>

<style scoped="scoped">
  body {
    padding: 0px;
    margin: 0px;
  }

  /*专题模式的样式*/
  /* .bs_zt {
    display: flex;
    flex-direction: column;
    background-color: white;
  }

  .bs_zt_about {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid grey;
  }

  .bs_zt_about_left {
    width: 40%;
    overflow: hidden;
    padding: 5px;
    box-sizing: border-box
  }

  .bs_zt_about_left img {
    width: 100%;
  }

  .bs_zt_about_right {
    width: 60%;
    overflow: hidden;
    line-height: 20px;
    font-size: 13px;
  }

  .bs_zt_hj {
      
  }

  .bs_zt_hj h5 {
    margin: 0px;
    padding: 0px;
    height: 30px;
    line-height: 30px;
  }

  .bs_zt_hj img {
    width: 100%;
  } */

  /*顶部头部分*/
  .wx_head {
      z-index: 1001;
      color: white;
    height: 56px;
    line-height: 35px;
    margin: 0px;
    display: flex;
    flex-direction: column;
  }

    .head_box{
        display: flex;
    flex-direction: row;
        border-bottom:1px solid #e6e6e6;
    }
    .head_title{
        padding:0 5px;
        height: 20px;
        line-height: 20px;
        color: #858585;
        background-color: white;
        
    }

  .head_left {
    /*display: none;*/
    display: flex;
    width: 25px;
    position: relative;
    
  }

  .head_left h2 {
    margin: 0px;
    font-size: 14px;
    font-weight: 100;
    top: -20px;
    position: absolute;
    display: flex;
    align-items: flex-end;
  }

  .el-icon-close {
    border: 1px solid #0B759D;
    border-radius: 5px;
    display: block;
    color: #fff;
    width: 25px;
    height: 25px;
    line-height: 25px;
  }

  .head_right {
    display: flex;
    width: 90%;
    flex-direction: row;
    justify-content:center;
  }

  .tel_a {
    width: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .icon-dianhua2 {
    width: 40%;
    color: #fff;
    font-size: 16pt;
    display: block;
    text-align: right;
    float: left;
    font-size: 18pt !important;
    text-decoration: none;
    align-items: center;
    justify-content: center;
  }

  .tel_b {
    width: auto;
  }

  .head_right h2 {
    float: left;
    margin: 0px;
    font-size: 14px;
    font-weight: 100;
  }

  /*对话主体框部分————————————————————————————————————————————————————*/
  /*框体*/
  .wx_content {
    background-color: #ebebeb;
    position: absolute;
    width: auto;
    height: auto;
    overflow: auto;
    bottom: 127px;
    padding-bottom: 50px;
    top: 56px;
    box-sizing: border-box;
    _height: 100%;
    /*_border-top:60px solid #eee;
	_border-right:8px solid #eee;*/
    right: 0px;
    /*_border-left:8px solid #eee;*/
    left: 0px;
    /*_border-bottom:50px solid redeee;*/
    /*_top: 0px;*/
    z-index: 90;
  }

  /*内容列表*/
  .content_list {
    display: flex;
    flex-direction: column;
    margin: 0px 0px;
    padding: 0px 0px;
  }

  /*对话内容*/
  /*访客————————————————————————*/
  .fangke {
    position: relative;
    margin-right: 5px;
  }

  /*访客气泡*/
  .fangke_nr {
    border-radius: 7px;
    padding: 10px;
    margin: 10px;
    position: relative;
    margin-right: 40px;
    margin-left: 40px;
    min-width: 40px;
    float: right;
    display: inline-block;
    *display: inline;
    *zoom: 1;

    word-wrap: break-all;
    word-break: normal;
  }

  /*访客气泡脚*/
  .fangke_nr::before {
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    display: block;
    box-sizing: border-box;
    outline: none;
    border-width: 6px;
    margin-top: -6px;
    left: 100%;
    border-left-color: #B3E862;
    margin-left: -1;
    top: 16px;
  }

  /*访客头像*/
  .face1 {
    right: 0px;
    float: right;
    position: absolute;
    top: 2px;
  }

  .face1 img {
    width: 36px;
    height: 36px;
    border-radius: 35px;
    box-sizing: border-box;
    padding: 2px;
  }

  /*客服——————————————————————*/
  .kefu {
    position: relative;
    margin-left: 5px;
  }

  .content img {
    width: 36px;
  }

  /*客服气泡*/
  .kefu_nr {
    padding: 10px;
    margin: 10px;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
    margin-left: 40px;
    margin-right: 40px;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    min-width: 40px;
    float: left;
    word-wrap: break-word;
    word-break: normal;
  }

  .kefu_nr:after {
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    display: block;
    top: 16px;
    right: 100%;
    box-sizing: border-box;
    outline: none;
    border-width: 6px;
    margin-top: -6px;
    margin-right: -1;
    border-right-color: #fff;
  }

  /*客服头像*/
  .face2 {
    float: left;
    position: absolute;
    top: 2px;


  }

  .face2 img {
    width: 36px;
    border-radius: 36px;
    height: 36px;
    box-sizing: border-box;
    padding: 2px;
  }

  /*底部输入框部分————————————————————————————*/

  .wx_input {
    width: 100%;
    position: absolute;
    overflow: auto;
    bottom: 30px;
    box-sizing: border-box;
    /*_height:100%;*/
    /*_border-top:60px solid #eee;	_border-right:8px solid #eee;*/
    right: 0px;
    /*_border-left:8px solid #eee;*/
    left: 0px;
    /*_border-bottom:50px solid redeee;*/
    _top: 0px;
    z-index: 999;
    display: flex;
    flex-direction: row;
    height: 67px;

  }

  /*工具栏*/
  .tool {
    height: 30px;
    background-color: #C7E6F8;
    color: #102749;
    width: 100%;
    position: absolute;
    bottom: 97px;
    box-sizing: border-box;
    /*_height:100%;*/
    /*_border-top:60px solid #eee;	_border-right:8px solid #eee;*/
    right: 0px;
    /*_border-left:8px solid #eee;*/
    left: 0px;
    /*_border-bottom:50px solid redeee;*/
    _top: 0px;
    z-index: 999;
    display: flex;
    flex-direction: row;
    height: 30px;

  }
 .btn_box{
     position: absolute;
     top: -55px;
     z-index: 999;
 }
  .mine {
    margin: 0px 5px;
    display: flex;
    align-items: center;
  }

  .my_dh {
    color: #8B88FD;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .my_face {
    padding-left: 10px;
  }

  .my_dhi {
    transform: rotateX(180deg);

  }

  .icon-tel-fill {
    font-size: 16pt;
  }

  .face {
    width: 10%;
    display: flex;
    flex-direction: row;
  }

  .input {
    width: 90%;
    display: flex;
    background-color: red;
  }

  .submit {
    display: flex;
  }

  /*表情包里面的图片*/
  .face_package,
  .add_package {
    position: absolute;
    z-index: 999;
    display: block;
    bottom: 127px;
  }

  /*右侧添加上传等图标*/
  .add_package img {
    width: 20%;
    margin: 5px;
  }

  /*表情包按钮*/
  .face_first {
    font-size: 20pt !important;
    color: grey;
    margin: auto;
  }

  /*底部input*/
  .wx_input {
    background-color: #fff;
  }

  .wx_input .inpt {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #777672;
    font-size: 14px;
    height: 63px;
    width: 100%;
    margin: 0px !important;
    overflow: auto;
    border: 0;
    resize: none;
    white-space: pre-wrap;
    overflow-wrap: break-word;

  }

  .inpt {
    outline: none;
  }

  /*添加按钮*/
  .add_sbt {
    color: grey;
    font-size: 24pt !important;
    height: 43px !important;
    text-align: center !important;
    display: block;
    margin: auto;
  }

  /*发送按钮*/
  #fasong_sbt {
    color: white;
    text-align: center !important;
    line-height: 30px;
    font-size: 14pt !important;
    display: block;
    margin: auto;
    width: 85% !important;
    padding: 5px;
    border: none;
    background-color: #45c01a;
    border-radius: 7px;
  }

  .wx_bottom {
    text-align: center;
    position: absolute;
    bottom: 0px;
    height: 30px;
    line-height: 30px;
    width: 100%;
    color: gainsboro;
    font-size: 13px;
    background: url('/images/skin/jdb_bottom.jpg');
    background-color: #f1f1f1;
  }
/* 笑脸 */
.smile {
    padding-top:7%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: center;
    margin: 10px;
    border-radius: 15px;
    border: 1px solid #c1c1c1;
    background-color: #fff;
    position: absolute;
    bottom: 20px;
    top: 30px;
    box-sizing: border-box;
    /*_border-top:60px solid #eee;
	_border-right:8px solid #eee;*/
    right: 0px;
    /*_border-left:8px solid #eee;*/
    left: 0px;
    /*_border-bottom:50px solid redeee;*/
    _top: 0px;
    z-index: 1001;
  }

  .faceabc {
    text-align: center;
    width: 40%;
    margin-top: 50px;
  }

  .faceabc img {
    max-width:350px;
    width: 100%;
  }

  h2 {
    font-size: 28px;
    text-align: center;
    margin: 5px;
  }

  .smile h3 {
    color: red;
    text-align: center;
    font-weight: 100;
    margin: 5px;
  }

  .el-button {
    margin: 10px;
  }

  .mybtn {
    display: flex;
    flex-direction: row;
  }
  #smile_zhezhao{
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background-color: black;
      position: fixed;
      z-index: 1000;
      display: none;
  }
  #smile_box{
      width: 90%;
      height: 90%;
      position: fixed;
      z-index: 1001;
      left: 5%;
      display: none;
  }
   /* 视频 */
  #main_video{
      background: white;
      width: 90%;
      margin: 0 auto;
      border-radius: 5px;  

  }
  .video_imp{
      width: 100%;
      height: 100%;
  }
  .video_context{
    height: 70px;
    margin: 0 10px; 
  }
  .video_context_left{
    float: left;
    margin-right: 10px;
    margin-top: 7px;
  }
  .video_context_right{
    float: left;
  }
  .context_right_details{
      height: 20px;
      color: #858585;
      line-height:20px;
      font-size:12px; 
  }
  .video_secbottom{
      border-bottom:1px solid #e6e6e6;
      line-height:34px;
      height: 36px;
      font-size:16px;
      margin-left:10px;
  }
 #video_play{
     position: absolute;
     left: 45%;
     top: 30%;
     height: 48px;
     width: 48px;
     background-image: url(../../assets/img/video_play.png);
     background-size: 100% 100%;
     cursor:pointer
 }
 #zhen_video{
     width: 100%;
 }
 /* 轮播图 */
 .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    margin: 0;
  }
#block{
    display: none;
}

</style>
