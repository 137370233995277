<template>
	<el-container>
		<el-header>
			<HeaderTop />
		</el-header>
		<el-container>
			<el-aside width="200px">
				<LeftMenu />
			</el-aside>
			<el-container>
				<el-main>
					<h1>单个着陆页安装方法</h1>					
					<div class="info">
						安装代码到web网页中 如图(建议安装在头部)
					</div>
					<el-input v-model="input2" placeholder="请输入内容"></el-input>
					<img src="../../assets/img/install_show4.jpg" />
				</el-main>
				<el-footer>
					<Footer />
				</el-footer>
			</el-container>
		</el-container>
		<router-view />
		<!-- 路由出口 -->
	</el-container>
</template>
<script>
	import HeaderTop from '@/components/Top.vue'
	import LeftMenu from '@/components/LeftMenu.vue'
	import Footer from '@/components/Footer.vue'
	export default {
		data() {
			return {

				input2: '',

			}
		},
		// 注册组件
		components: {
			LeftMenu,
			HeaderTop,

			Footer,
		},
	}
</script>

<style scoped="scoped">
	.el-input {
		margin: 10px;
		max-width: 800px;
	}
</style>