<template>
	<el-container>
		<el-header>
			<HeaderTop />
		</el-header>
		<el-container>
			<el-aside width="200px">
				<LeftMenu />
			</el-aside>
			<el-container>
				<el-main>
					<h1>商务通安装说明：</h1>
					<div class="info">
						复制 安装代码到商务通 如图 (如果原本有代码，累加即可)
					</div>
					<el-input v-model="input" placeholder="请输入内容"></el-input>
					<img src="../../assets/img/install_show1.jpg" />
					<img src="../../assets/img/install_show2.jpg" />
					<img src="../../assets/img/install_show3.jpg" />
				</el-main>
				<el-footer>
					<Footer />
				</el-footer>
			</el-container>
		</el-container>
		<router-view />
		<!-- 路由出口 -->
	</el-container>
</template>
<script>
	import HeaderTop from '@/components/Top.vue'
	import LeftMenu from '@/components/LeftMenu.vue'
	import Footer from '@/components/Footer.vue'
	export default {

		data() {
			return {
				input: '',
				account: '',
			}
		},
		// 注册组件
		components: {
			LeftMenu,
			HeaderTop,
			Footer,
		},

		created() {
		
			this.account = this.$route.query.account
			// 根据账号查询升话宝参数
			this.getCueConf()
		},
		methods: {
			getCueConf() {
				// 获取用户设置
				const req = {
					account: this.account
				}
				const that = this
				this.$axios.post('/apis/conf/shb', req).then(function(res) {
					const re_date = res.data.Data
					if(res.data.Code == '200') {
						that.input = re_date.swt_code
						
					}
				})
			},
		}
	}
</script>

<style scoped="scoped">
	.el-input {
		max-width: 1000px;
		margin: 10px;
	}
</style>