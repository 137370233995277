<template>
    <el-container>
        <el-header>
            <HeaderTop />
        </el-header>
        <el-container>
            <el-aside width="200px">
                <LeftMenu />
            </el-aside>
            <el-container>
                <el-main>
                    <div class='list_content'>
                        <h2 style="text-align: center;">修改登陆账号<font color="red">{{user.account}}</font>的信息</h2>
                        <el-form ref="form" :model="user" label-width="80px">

                            <el-form-item label="账号">
                                <el-input v-model="user.account"></el-input>
                            </el-form-item>

                            <el-form-item label="用户名" style="margin-left: 0px;">
                                <el-tooltip class="item" effect="dark" content="一般为客户方主要负责人的称呼或姓名,如有负责人更换，请及时更新" placement="top-end">
                                    <el-input v-model="user.user_name"></el-input>
                                </el-tooltip>
                            </el-form-item>

                            <el-form-item label="密码">
                                <el-input show-password v-model="user.old_pwd"></el-input>
                            </el-form-item>
                            <!-- 超管和代理商有权限修改 级别 -->
                            <el-form-item v-if="manageAndDlsShow" label="级别">
                                <el-select v-model="user.level" placeholder="请选择">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <!-- 超管和代理商有权限修改 用户状态 -->
                            <el-form-item v-if="manageAndDlsShow" label="用户状态">
                                <el-select v-model="user.is_use" placeholder="请选择">
                                    <el-option v-for="item in options_use" :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <!-- 超管和代理商有权限修改 有效期 -->
                            <el-form-item v-if="manageAndDlsShow" label="有效期">
                                <el-col :span="11">
                                    <div class="block">
                                        <span class="demonstration"></span>
                                        <div class="demonstration"></div>
                                        <el-date-picker v-model="user.validity_time" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日 HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
                                        </el-date-picker>
                                        <!-- 超管可以修改 有效期为永久 -->
                                        <el-checkbox v-if="managerShow" v-model="permanent" style="padding-left: 15px;">永久</el-checkbox>
                                    </div>
                                </el-col>
                            </el-form-item>

                            <el-form-item label="公司名称">
                                <el-input v-model="user.company_name"></el-input>
                            </el-form-item>

                            <el-form-item v-if="manageAndDlsShow" label="上级单位">
                                <el-input v-model="user.superior_id"></el-input>
                            </el-form-item>

                            <el-form-item v-if="manageAndDlsShow" label="负责人">
                                <el-input v-model="user.principal"></el-input>
                            </el-form-item>

                            <el-form-item v-if="manageAndDlsShow" label="销售员">
                                <el-input v-model="user.sales"></el-input>
                            </el-form-item>

                            <el-form-item label="联系方式">
                                <el-input v-model="user.phone"></el-input>
                            </el-form-item>

                            <el-form-item v-if="manageAndDlsShow" label="发送流量">
                                <el-switch v-model="liulSendFlag" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                            </el-form-item>

                            <el-form-item v-if="manageAndDlsShow" label="公司图标">
                                <el-input v-model="user.top_img"></el-input>
                            </el-form-item>

                            <el-form-item v-if="manageAndDlsShow" label="备注">
                                <el-input type="textarea" :rows="5" placeholder="请输入..." v-model="user.remark"></el-input>
                            </el-form-item>

                            <el-button type="primary" @click="onSubmit">确认</el-button>
                            <el-button @click="cancel">取消</el-button>
                        </el-form>
                    </div>
                </el-main>
                <el-footer>
                    <Footer />
                </el-footer>
            </el-container>
        </el-container>
        <router-view /><!-- 路由出口 -->
    </el-container>
</template>

<script>
import HeaderTop from '@/components/Top.vue'
import LeftMenu from '@/components/LeftMenu0.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'UserSetting',
    components: {
        HeaderTop,
        LeftMenu,
        Footer
    },
    data() {
        return {
            liulSendFlag: false,
            permanent: false,
            managerShow: false,
            manageAndDlsShow: false,
            ptyhgl1: {},
            user: {},
            options_use: [{
                value: 1,
                label: '启用'
            },
            {
                value: 0,
                label: '禁用'
            }
            ],
            options: [{
                value: 2,
                label: '代理商'
            }, {
                value: 3,
                label: '普通用户'
            }, {
                value: 4,
                label: '正式会员'
            }, {
                value: 5,
                label: '销售人员'
            }, {
                value: 6,
                label: '测试案例账号'
            }]
        }
    },
    created() {
        // 从缓存获取user
        const cache_user = sessionStorage.getItem('user')
        let user_obj = JSON.parse(cache_user)
        // 超级管理员能看
        if (user_obj.level == 1) {
            this.managerShow = true
        }
        // 超管和代理商能看
        if (user_obj.level == 1 || user_obj.level == 2) {
            this.manageAndDlsShow = true
        }

        const that = this
        const rec_account = this.$route.params.account
        this.$axios.post('/apis/user/info', {
            "account": rec_account
        }).then(function (res) {
            console.log(res.data)
            if (res.data.Code === '200') {
                that.user = res.data.Data
                that.user.man_account = user_obj.account

                if (that.user.liul_send == 1) {
                    that.liulSendFlag = true
                } else {
                    that.liulSendFlag = false
                }

                let dateStr = that.user.validity_time
                if (dateStr != '' && dateStr != undefined) {
                    let from_year = dateStr.split('-')[0]
                    if (Number(from_year) > 2120) {
                        that.permanent = true
                    }
                }
            } else {
                alert(res.data.Msg)
            }
        })
    },
    methods: {
        cancel() {
            history.go(-1)
        },
        onSubmit() {
            console.log('开始修改!');
            const that = this
            if (that.permanent) {
                let now = new Date();
                let new_data_str = (now.getFullYear() + 100) + '-' + (now.getMonth() + 1) + '-' + now.getDate() + ' ' + now
                    .getHours() + ':' + now.getMinutes() + ':' + now.getSeconds()
                that.user.validity_time = new_data_str
            }

            if (that.liulSendFlag == true) {
                that.user.liul_send = 1
            } else {
                that.liulSendFlag = 2
            }

            this.$axios.post('/apis/user/update', that.user)
                .then(function (res) {
                    if (res.data.Code === '200') {
                        console.log(res);
                        alert('更改成功!')
                        history.go(-1)
                    } else {
                        alert(res.data.Msg)
                    }
                })
        }
    }
}

</script>

<style scoped="scoped" lang="less">
.list_content {
    max-width: 800px;
    padding: 10px;
}
</style>
