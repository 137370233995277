<template>
	<div class="dhjh_all">
  <div class="dhjh">
    <div class="class2">
      <div class="class2_1">复制微信</div>
      	<el-tooltip placement="top">
        <div slot="content">升话宝后台营销电话不为空或客服微信号不为空时，开启该功能，则实现拨打电话和复制微信功能供访客一键拨打和一键复制。</div> 
      <div class="class2_2">
        <el-switch v-model="is_copywx_bool" active-color="#13ce66" inactive-color="#ff4949">
        </el-switch>咨询员发送手机号，显示复制微信
      </div>
      </el-tooltip>
    </div>
      <div class="class6">
      <el-button type="primary" @click=onSubmit>保存</el-button>
    </div>
  </div>
     <div class="dhjh_tp"><img src="../assets/img/dhjh.jpg"></div>
    </div>
</template>

<script scoped="scoped">
  export default {
    name: 'DHJH',
    data() {
      return {
        conf: {
          is_copy_wx: 0,
        },
        input1: '',
        is_copywx_bool: false,
      }
    },
    created() {
      // 获取链接参数
      const rec_account = this.$route.query.account
      console.log('--------------------', rec_account)
      this.conf.account = rec_account
      this.getCueConf()
    },
    methods: {
      onSubmit() {
        // 保存升话宝设置
        const req = this.conf
        if (this.is_copywx_bool == true) {
          req.is_copy_wx = 1
        } else {
          req.is_copy_wx = 0
        }
        this.$axios.post('/apis/conf/shb/setting', req).then(function (res) {
          console.log("返回值打印-----", res)
          alert(res.data.Msg)
        })
      },

      getCueConf() {
        // 获取用户设置
        const req = {
          account: this.conf.account
        }
        const that = this
        this.$axios.post('/apis/conf/shb', req).then(function (res) {
          console.log("返回值打印:", res)
          const re_date = res.data.Data
          if (res.data.Code == '200') {
            if (re_date.is_copy_wx == 1) {
              that.is_copywx_bool = true
            } else {
              that.is_copywx_bool = false
            }
            that.conf = re_date
          }
        })
      }
    }
  }

</script>



<style scoped>

.dhjh_all {
    display: flex;
    flex-direction: row; 
		width: 100%;
	
  }

  .dhjh {
    
   width: 800px;
   
   
  }

  .dhjh_tp {
  
    max-width: 500px;
    padding-left: 10px;
  }

  .dhjh_tp img {
    width: 100%;
  }
  
  
  .class2 {
    height: 40px;
    color: #767676;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .class2_1 {
    background-color: #fbfbfb;
    border: 1px solid #e6e6e6;
    border-right: 0px;
    height: 40px;
    line-height: 40px;
    width: 20%;
    padding: 0px 10px;
    text-align: center;
  }

  .class2_2 {
    border: 1px solid #e6e6e6;
    height: 40px;
    line-height: 40px;
    width: 80%;
    padding: 0px 10px;
    text-align: left;
  }

  .class6 {
    margin: 10px;
    height: 40px;
  }

</style>
