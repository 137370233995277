<template>
  <div class="fkcj">
    <div class="set_title">后退按钮</div>
    <div style="margin: 5px; padding: 5px;">
      <el-radio v-model="conf.back_type" label='1'>后退弹出输入框</el-radio>
      <el-radio v-model="conf.back_type" label='2'>笑脸拨打电话</el-radio>
      <el-radio v-model="conf.back_type" label='3'>提示拨打电话</el-radio>
      <el-radio v-model="conf.back_type" label='4'>禁止商务通后退</el-radio>
      <el-radio v-model="conf.back_type" label='5'>关闭商务通或者快商通退出功能</el-radio>
      <el-radio v-model="conf.back_type" label='6'>后退提示添加微信</el-radio>
      <el-radio v-model="conf.back_type" label='7'>后退到指定链接</el-radio>
      <p></p>
      <div class="class2">
        <el-input placeholder="请输入内容" v-model="conf.back_msg">
          <template slot="prepend">自定义提示内容:</template>
        </el-input>
      </div>
      <div class="class2">
        <el-input placeholder="请输入内容" v-model="conf.back_link">
          <template slot="prepend">指定后退链接:</template>
        </el-input>
      </div>
    </div>


    <div class="class6">
      <el-button type="primary" @click='onSubmit'>保存</el-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "FKJC",
    data() {
      return {
        conf: {
          account: '',
          back_link: '',
          back_type: '1',
          back_msg: '',
        }
      }
    },

    created() {
      // 获取链接参数
      const rec_account = this.$route.query.account
      console.log('--------------------', rec_account)
      this.conf.account = rec_account
      this.getCueConf()
    },

    methods: {
      onSubmit() {
        // 保存升话宝设置
        const req = this.conf
        let that = this;
        this.$axios.post('/apis/conf/shb/setting', req).then(function (res) {
          if (res.data.Code == "200") {
            // 跳转到专题设置
            that.$router.push({
              path: '/dialog/ztsz',
              query: {
                account: that.conf.account,
              }
            })
          } else {
            alert(res.data.Msg)
          }
        })
      },

      getCueConf() {
        // 获取用户设置
        const req = {
          account: this.conf.account
        }
        const that = this
        this.$axios.post('/apis/conf/shb', req).then(function (res) {
          console.log("返回值打印:", res)
          const re_date = res.data.Data
          if (res.data.Code == '200') {
            that.conf = re_date
          }
        })
      }
    }
  }

</script>

<style scoped="scoped">
  .fkcj {
    max-width: 800px;
  }

  .class2 {
    margin: 10px;
  }

  .class6 {
    margin: 10px;
    height: 40px;
  }

</style>
