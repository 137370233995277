<template>
	<el-container>
		<el-header>
			<HeaderTop />
		</el-header>
		<el-container>
			<el-aside width="200px">
				<LeftMenu />
			</el-aside>
			<el-container>
				<el-main>
					<div class="yjfk">
					<h2>意见反馈</h2>
					<el-input placeholder="请留下您的称呼及联系方式" v-model="feedback.phone" class="input-with-select">
						<el-select v-model="feedback.fk_type" slot="prepend" placeholder="反馈类型">
							<el-option label="产品更新与改良" value="产品更新与改良"></el-option>
							<el-option label="功能定制与开发" value="功能定制与开发"></el-option>
							<el-option label="参与产品内测" value="参与产品内测"></el-option>
							<el-option label="投诉客服" value="投诉客服"></el-option>
						</el-select>
						<!--<el-button slot="append" icon="el-icon-search"></el-button>-->
					</el-input>

					<div style="margin: 20px 0;"></div>
					<el-input type="textarea" :autosize="{minRows: 4, maxRows: 6}" placeholder="请留下您的宝贵意见，我们会在最迟24小时内回复处理！" v-model="feedback.fk_content">
					</el-input>
					<div style="margin: 20px 0;"></div>
					<el-button type="primary" @click='onSubmit'>立刻提交</el-button>
					<aside>截止最后一次提交时间，已收到建议23条，已处理23条。</aside>
				</div>
				</el-main>
				<el-footer><Footer /></el-footer>
			</el-container>
		</el-container>
		<router-view />
		<!-- 路由出口 -->
	</el-container>
</template>
<script>
	import HeaderTop from '@/components/Top.vue'
	import LeftMenu from '@/components/LeftMenu0.vue'
	import Footer from '@/components/Footer.vue'
	export default {
    name:'FeefBack',
		// 注册组件
		components: {
			LeftMenu,
			HeaderTop,
			Footer,
		},
    // 绑定数据
    data() {
      return {
        feedback: {
          account:'',
          fk_type:'',
          phone:'',
          fk_content:''
        }
      }
    },
    // 初始化
    created() {
    // 获取链接参数
    const rec_account = this.$route.query.account // 获取链接参数
    this.feedback.account = rec_account
    },
    methods:{
      onSubmit(){
        let that = this
        this.$axios.post('/apis/feedback/add', that.feedback)
          .then(function(res) {
            alert(res.data.Msg)
          })
      }
    }

	}
</script>

<style scoped="scoped">
	.yjfk{max-width: 800px;}
	.el-select .el-input {
		width: 130px;
	}

	.input-with-select .el-input-group__prepend {
		background-color: #fff;
	}
</style>
