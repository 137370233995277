<template>
	 <el-container>
    <el-header>
      <HeaderTop />
    </el-header>
    <el-container>
      <el-aside width="200px">
        <LeftMenu />
      </el-aside>
      <el-container style="max-height: 844px; border: 1px solid #eee">
        <el-main>
        	<h1>快商通安装说明：</h1>
        	<div class="info">
        		复制 安装代码到快商通 如图 (如果原本有代码，累加即可)
        	</div>          	
          	<el-input v-model="input" placeholder="请输入内容"></el-input>
          	<img src="../../assets/img/install_kst1.jpg" />
          	<img src="../../assets/img/install_kst2.jpg" />
          	<img src="../../assets/img/install_kst3.jpg" />
        </el-main>
        <el-footer>
          <Footer />
        </el-footer>
      </el-container>
    </el-container>
    <router-view /><!-- 路由出口 -->
  </el-container>
</template>
<script>
	import HeaderTop from '@/components/Top.vue'
 	 import LeftMenu from '@/components/LeftMenu.vue' 
 	 import Footer from '@/components/Footer.vue'
   export default {
   	data() {
    return {
     
      input2: '',
   
    }
  },
    // 注册组件
    components: {
      LeftMenu,
      HeaderTop,
      
      Footer,
    },
   }
</script>

<style scoped="scoped">
	.el-input{margin: 10px;
	max-width: 1000px
		;}
</style>