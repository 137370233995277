<template>
    <div id="liul_list">
        <el-container>
            <el-header>
                <HeaderTop />
            </el-header>
            <el-container>
                <el-aside width="200px">
                    <LeftMenu />
                </el-aside>
                <el-container>
                    <el-main>
                        <!-- <keep-alive> -->
                        <div class="list_content">
                            <!-- 查询条件 -->
                            <el-form :inline="true" class="demo-form-inline">
                                <el-form-item>
                                    <div style="border: 1px solid #409eff;background-color:#409eff;color:#fff;padding:0 30px;border-radius:10px;margin-left:42px">
                                        <span>流量:{{tjData.RecListLen}}</span>
                                        <el-divider direction="vertical"></el-divider>
                                        <span>开口数:{{tjData.KkLen}}</span>
                                        <el-divider direction="vertical"></el-divider>
                                        <span>有效对话数:{{tjData.ValLen}}</span>
                                        <el-divider direction="vertical"></el-divider>
                                        <span>开口率:{{(tjData.KkLen/tjData.RecListLen*100).toFixed(2)}}%</span>
                                        <el-divider direction="vertical"></el-divider>
                                        <span>有效对话率:{{(tjData.ValLen/tjData.RecListLen*100).toFixed(2)}}%</span>
                                    </div>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="downloadNow">导出当前账号当前流量</el-button>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="downloadAll">导出当前账号全部流量</el-button>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="downloadNowAccount">导出所有账号当前流量</el-button>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="downloadAllAccount">导出所有账号所有流量</el-button>
                                </el-form-item>
                            </el-form>
                            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                                <el-form-item label="">
                                    <div class="block">
                                        <el-date-picker v-model="formInline.fw_time" type="datetimerange" align="right" :picker-options="pickerOptions" :start-placeholder="todayTime" :end-placeholder="todayTime" :default-time="time_01" value-format="yyyy-MM-dd HH:mm:ss" style="width: 360px;">
                                        </el-date-picker>
                                    </div>
                                </el-form-item>

                                <el-form-item>
                                    <el-input v-model="formInline.key_word" placeholder="关键词"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-input v-model="formInline.from_url" placeholder="来源页"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-input v-model="formInline.chat_url" placeholder="落地页"></el-input>
                                </el-form-item>

                                <el-form-item>
                                    <el-input v-model="formInline.browser" placeholder="浏览器"></el-input>
                                </el-form-item>

                                <!-- <el-form-item>
                  <div style="color:#409eff;"><a>{{today.today_years+'-'+today.today_mouth+'-'+today.today_day}}</a></div>
                </el-form-item> -->
                                <el-form-item>
                                    <el-button type="primary" @click="cx_sunbmit(2)">上一天</el-button>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="cx_sunbmit(1)">今天</el-button>
                                </el-form-item>
                                <el-form-item>
                                    <el-button v-if="this.today.today_day==this.today.today_day1" type="primary" disabled @click="cx_sunbmit(3)">下一天</el-button>
                                    <el-button v-else type="primary" @click="cx_sunbmit(3)">下一天</el-button>
                                </el-form-item>

                                <el-form-item label="有效数">
                                    <el-switch @change="cx_sunbmit(100)" v-model="formInline.is_effective_dialogue" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                                </el-form-item>
                                <el-form-item label="开口数">
                                    <el-switch @change="cx_sunbmit(100)" v-model="formInline.yx_dh" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                                </el-form-item>
                                <el-form-item label="根据ip去重">
                                    <el-switch @change="cx_sunbmit(100)" v-model="formInline.unique_ip" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="cx_sunbmit(100)">查询</el-button>
                                </el-form-item>
                            </el-form>
                            <!-- 列表内容 -->
                            <el-table :data="liul_list" :cell-style="{padding:'2px 0'}" height="600" border stripe>
                                <!-- 删除单条流量 -->
                                <el-table-column label="删除" align="center" width="50">
                                    <template slot-scope="scope">
                                        <el-button @click="deleteLiul(scope.row)" type="text" size="small" style="color:red;">X</el-button>
                                    </template>
                                </el-table-column>

                                <el-table-column prop="id" label="id" align="center" width="80"></el-table-column>
                                <el-table-column label="对话标记" align="center" width="120">
                                    <template slot-scope="scope">
                                        <span v-if='scope.row.chat_message =="" ||scope.row.chat_message==undefined'>
                                            <el-button @click="handleLiul(scope.row)" type="text" size="small" style="color:#C0C4CC;">
                                                仅访问
                                            </el-button>
                                        </span>
                                        <span v-else-if='scope.row.chat_message.split("bs-split").length>=3 && scope.row.chat_message.split("bs-split").length<10'>
                                            <el-button @click="handleLiul(scope.row)" type="text" size="small" style="color:#13ce66">有效对话:{{scope.row.chat_message.split("bs-split").length}}句</el-button>
                                        </span>
                                        <span v-else>
                                            <el-button @click="handleLiul(scope.row)" type="text" size="small" style="color:blue">开口数:{{scope.row.chat_message.split("bs-split").length}}句</el-button>
                                        </span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="account" label="访问账号" align="center" width="100"></el-table-column>
                                <el-table-column prop="skip_time" label="跳转时间(秒)" width="110" align="center">
                                    <template slot-scope="scope">
                                        <a target="_blank">{{ scope.row.skip_time/1000 }}</a>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="swt_stay_time" label="停留时间(秒)" width="110" align="center"></el-table-column>
                                <el-table-column prop="ip" label="访问ip" width="130" align="center"></el-table-column>
                                <el-table-column prop="key" label="访问关键词" width="150" show-overflow-tooltip align="center"></el-table-column>
                                <el-table-column prop="fw_time" label="访问时间" width="170" align="center"></el-table-column>
                                <el-table-column prop="from_url" label="竞价来源页" width="180" show-overflow-tooltip align="left">
                                    <template slot-scope="scope">
                                        <a class="mylink" target="_blank" :href="scope.row.from_url">{{scope.row.from_url}}</a>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="chat_url" label="用户落地页" width="200" show-overflow-tooltip align="left">
                                    <template slot-scope="scope">
                                        <a class="mylink" target="_blank" :href="scope.row.chat_url">{{scope.row.chat_url}}</a>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="browser" label="浏览器" width="140" align="center"></el-table-column>
                                <el-table-column prop="client_type" label="手机系统" width="190" show-overflow-tooltip align="center"></el-table-column>

                                <el-table-column prop="user_agent" label="UA" width="190" show-overflow-tooltip align="center">
                                </el-table-column>

                            </el-table>
                            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current_page" :page-sizes="[15,20,25]" :page-size="page_size" layout="total, sizes, prev, pager, next, jumper" :total="data_num" :sizes='page_num'>
                            </el-pagination>
                        </div>
                        <!-- 路由出口 -->
                        <router-view />
                    </el-main>
                    <el-footer>
                        <Footer />
                    </el-footer>
                </el-container>
            </el-container>
        </el-container>
    </div>
</template>


<script>
import LeftMenu from '@/components/LeftMenu0.vue'
import HeaderTop from '@/components/Top.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'fw_list',
    components: {
        LeftMenu,
        HeaderTop,
        Footer
    },
    data() {
        return {
            today: {
                today_years: '',
                today_mouth: '',
                today_day: '',
                today_years1: '',
                today_mouth1: '',
                today_day1: '',
                todayTime: '',
            },
            pickerOptions: {
                disabledDate(v) {
                    return v.getTime() >= new Date().getTime();
                }
            },
            cishu: '',
            time_01: ['00:00:01', '23:59:59'],
            liul_list: [],
            formInline: {
                from_url: "",
                browser: "",
                is_down_baobiao: 0,
                account: '',
                fw_time: [],
                yx_dh: false,
                is_effective_dialogue: false,
                unique_ip: true,
                key_word: "",
                chat_url: "",
                current_page: 1,
                page_size: 15, // 每页的数据
            },
            current_page: 1, //初始页
            page_size: 15, // 每页的数据
            data_num: 0, // 总条数
            page_num: 0, // 总页数
            tjData: [], // 统计数据
        }
    },
    created() {
        let date = new Date()
        this.today.today_years = date.getFullYear()
        this.today.today_years1 = date.getFullYear()
        this.today.today_mouth = date.getMonth() + 1
        this.today.today_mouth1 = date.getMonth() + 1


        if (date.getMonth() < 10) {
            this.today.today_mouth = '0' + this.today.today_mouth
            this.today.today_mouth1 = '0' + this.today.today_mouth1
        }
        this.today.today_day = date.getDate()
        this.today.today_day1 = date.getDate()
        this.time_01[1] = date.toLocaleTimeString('chinese', {
            hour12: false
        })
        this.todayTime = this.today.today_years + '-' + this.today.today_mouth + '-' + this.today.today_day
        console.log(this.todayTime)
        console.log('select!!!!!');
        // 从缓存获取user
        let account = this.$route.query.account
        let req = {
            'account': account,
            'current_page': this.current_page
        }
        let that = this
        this.formInline.account = account
        req.yx_dh = 0
        if (true == this.formInline.yx_dh) {
            req.yx_dh = 1
        }
        req.unique_ip = 0
        if (true == this.formInline.unique_ip) {
            req.unique_ip = 1
        }
        req.is_effective_dialogue = 0
        if (true == this.formInline.is_effective_dialogue) {
            req.is_effective_dialogue = 1
        }

        req.key_word = this.formInline.key_word
        req.chat_url = this.formInline.chat_url
        req.current_page = this.current_page
        req.page_size = this.page_size

        let cacheLiulStr = sessionStorage.getItem('liul_cache')
        if (cacheLiulStr != "" && cacheLiulStr != null) {
            let cacheLiulObj = JSON.parse(cacheLiulStr)
            if (cacheLiulObj.account == req.account) {
                req = cacheLiulObj
                this.current_page = Number(cacheLiulObj.current_page)
                this.formInline.key_word = cacheLiulObj.key_word
                this.formInline.chat_url = cacheLiulObj.chat_url
                this.formInline.fw_time = [cacheLiulObj.fw_time_start, cacheLiulObj.fw_time_finish]
                this.formInline.yx_dh = (cacheLiulObj.yx_dh == 1 ? true : false)
                this.formInline.unique_ip = (cacheLiulObj.unique_ip == 1 ? true : false)
            }
        }

        this.$axios.post('/apis/fkrecord/list', req)
            .then(function (res) {
                console.log(res.data)
                if (res.data.Code === '200') {
                    that.liul_list = res.data.Data.List
                    that.tjData = res.data.Data.Data
                    that.data_num = res.data.Data.TotalCount
                    that.page_num = res.data.Data.TotalPage
                }
            })
    },
    mounted() {

    },
    methods: {
        handleLiul(u) {
            this.$router.push({
                path: '/liul/one',
                query: {
                    id: u.id
                }
            })
        },
        cx_sunbmit(onday) {
            this.current_page = 1
            let req = this.onSubmit(onday)
            this.realSubmit(req)
        },
        onSubmit(oneday) {
            console.log('查询!');
            const that = this
            let req = {
                "account": that.formInline.account,
            }

            req.fw_time_start = this.formInline.fw_time[0]
            req.fw_time_finish = this.formInline.fw_time[1]
            req.key_word = this.formInline.key_word
            req.chat_url = this.formInline.chat_url

            if (oneday == 1) {
                this.cishu = 1
                this.today.today_years = this.today.today_years1
                this.today.today_mouth = this.today.today_mouth1
                this.today.today_day = this.today.today_day1
                req.fw_time_start = this.today.today_years + '-' + this.today.today_mouth + '-' + this.today.today_day + ' 00:00:01'
                req.fw_time_finish = this.today.today_years + '-' + this.today.today_mouth + '-' + this.today.today_day + ' 23:59:59'
            } else if (oneday == 2) {
                this.cishu = 1
                if ((this.today.today_day - 1 <= 0) && (this.today.today_mouth == 1 || this.today.today_mouth == 3 || this.today.today_mouth == 8 || this.today.today_mouth == 5 || this.today.today_mouth == 7 || this.today.today_mouth == 10 || this.today.today_mouth == 12)) {
                    this.today.today_day = 30
                    this.today.today_mouth = Number(this.today.today_mouth) - 1
                    if (this.today.today_mouth <= 0) {
                        this.today.today_mouth = 12
                        this.today.today_years -= 1
                    }
                } else if (this.today.today_day - 1 <= 0) {
                    this.today.today_day = 31
                    this.today.today_mouth = Number(this.today.today_mouth) - 1
                    if (this.today.today_mouth <= 0) {
                        this.today.today_mouth = 12
                        this.today.today_years -= 1
                    }
                } else this.today.today_day -= 1
                req.fw_time_start = this.today.today_years + '-' + this.today.today_mouth + '-' + this.today.today_day + ' 00:00:01'
                req.fw_time_finish = this.today.today_years + '-' + this.today.today_mouth + '-' + this.today.today_day + ' 23:59:59'
            } else if (oneday == 3) {
                this.cishu = 1
                if (this.today.today_day + 1 >= 31) {
                    if ((this.today.today_mouth == 1 || this.today.today_mouth == 3 || this.today.today_mouth == 8 || this.today.today_mouth == 5 || this.today.today_mouth == 7 || this.today.today_mouth == 10 || this.today.today_mouth == 12) && this.today.today_day == 30) {
                        this.today.today_day = 31
                    } else {
                        this.today.today_day = 1
                        this.today.today_mouth = Number(this.today.today_mouth) + 1
                    }

                } else if (this.today.today_day + 1 > 31) {
                    this.today.today_day = 1
                    this.today.today_mouth = Number(this.today.today_mouth) + 1
                } else {
                    this.today.today_day += 1
                }
                req.fw_time_start = this.today.today_years + '-' + this.today.today_mouth + '-' + this.today.today_day + ' 00:00:01'
                req.fw_time_finish = this.today.today_years + '-' + this.today.today_mouth + '-' + this.today.today_day + ' 23:59:59'
            } else if (oneday == 4) {
                if (this.cishu) {
                    req.fw_time_start = this.today.today_years + '-' + this.today.today_mouth + '-' + this.today.today_day + ' 00:00:01'
                    req.fw_time_finish = this.today.today_years + '-' + this.today.today_mouth + '-' + this.today.today_day + ' 23:59:59'
                }
            }
            req.current_page = this.current_page
            req.page_size = this.page_size
            req.yx_dh = 0
            if (true == this.formInline.yx_dh) {
                req.yx_dh = 1
            }

            req.unique_ip = 0
            if (true == this.formInline.unique_ip) {
                req.unique_ip = 1
            }

            req.is_effective_dialogue = 0
            if (true == this.formInline.is_effective_dialogue) {
                req.is_effective_dialogue = 1
            }

            req.fw_time = [req.fw_time_start, req.fw_time_finish]
            that.formInline.fw_time = [req.fw_time_start, req.fw_time_finish]
            sessionStorage.setItem('liul_cache', JSON.stringify(req))
            req.is_down_baobiao = that.formInline.is_down_baobiao;
            req.browser = that.formInline.browser;
            req.from_url = that.formInline.from_url;
            return req
        },
        realSubmit(req) {
            let that = this
            this.$axios.post('/apis/fkrecord/list', req)
                .then(function (res) {
                    if (res.data.Code === '200') {
                        that.liul_list = res.data.Data.List
                        that.tjData = res.data.Data.Data
                        console.log(res.data.Data.List);
                        that.data_num = res.data.Data.TotalCount
                        that.page_num = res.data.Data.TotalPage
                    }
                    if (res.data.Data.List == null) {
                        that.liul_list = undefined;
                        that.tjData.RecListLen = 0;
                        that.tjData.KkLen = 0;
                        that.tjData.ValLen = 0;

                    }
                })
        },
        // 初始页current_page、初始每页数据数page_size和数据data
        handleSizeChange: function (size) {
            this.page_size = size;
            console.log(this.page_size) //每页下拉显示数据
            let req = this.onSubmit(100)
            this.realSubmit(req)
        },
        handleCurrentChange: function (current_page) {
            this.current_page = current_page;
            console.log(this.current_page) //点击第几页
            let req = this.onSubmit(100)
            this.realSubmit(req)
        },
        downSubmit() {
            let req = this.onSubmit(100)
            let downurl = "/apis/upload/liulexcel?account=" + req.account
            downurl += "&is_down_baobiao=" + req.is_down_baobiao
            downurl += "&fw_time_start=" + req.fw_time_start
            downurl += "&fw_time_finish=" + req.fw_time_finish
            downurl += "&key_word=" + req.key_word
            downurl += "&chat_url=" + req.chat_url
            downurl += "&yx_dh=" + req.yx_dh
            downurl += "&unique_ip=" + req.unique_ip
            downurl += "&is_effective_dialogue=" + req.is_effective_dialogue
            alert("开始下载!")
            window.location.href = downurl
        },
        downloadNow() {
            this.formInline.is_down_baobiao = 1;
            this.downSubmit()
        },
        downloadAll() {
            this.formInline.is_down_baobiao = 2;
            this.downSubmit()
        },
        downloadNowAccount() {
            this.formInline.is_down_baobiao = 3;
            this.downSubmit()
        },
        downloadAllAccount() {
            this.formInline.is_down_baobiao = 4;
            this.downSubmit()
        },
        deleteLiul(row) {
            let that = this;
            let liulId = row.id;
            if (confirm('确定删除本条流量数据吗?')) {
                // 发送删除请求
                if (liulId != 0 && typeof liulId != "undefined") {
                    let req = {};
                    req.id = liulId;
                    that.$axios.post('/apis/liul/del', req).then(function (res2) {
                        if (res2.data.Code === '200') {
                            // 获取到当前流量的下标删除
                            let liulIndex = that.liul_list.indexOf(row);
                            if (liulIndex != -1) {
                                that.liul_list.splice(liulIndex, 1);
                            };
                            alert("删除成功!");
                        } else {
                            alert("删除失败!--" + res2.data.Msg + res2.data.Data);
                        }
                    })
                } else {
                    alert("id 有误!--" + liulId);
                }
            }
        },
    }
}

</script>

<style scoped>
.mylink:hover {
    cursor: pointer;
    color: #f56c6c !important;
}

.mylink:link,
.mylink:visited {
    color: #409eff;
}
</style>
