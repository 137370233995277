<template>
  <el-container>
    <el-header>
      <HeaderTop />
    </el-header>
    <el-container>
      <el-aside width="200px">
        <LeftMenu />
      </el-aside>
      <el-container>
        <el-main>
          <div class='list_content'>
            <h2 style="text-align: center;">流量详情</h2>
            <el-form ref="form" :model="liul_list" label-width="120px">
              <el-form-item label="访问账号" style="margin-left: 0px;">
                <el-tooltip class="item" effect="dark" content="访问账号" placement="right">
                  <el-input v-model="liul_list.account"></el-input>
                </el-tooltip>
              </el-form-item>

              <el-form-item label="停留时间(秒)" style="margin-left: 0px;">
                <el-tooltip class="item" effect="dark" content="停留时间" placement="right">
                  <el-input v-model="liul_list.swt_stay_time"></el-input>
                </el-tooltip>
              </el-form-item>

              <el-form-item label="访问ip" style="margin-left: 0px;">
                <el-tooltip class="item" effect="dark" content="访问ip" placement="right">
                  <el-input v-model="liul_list.key"></el-input>
                </el-tooltip>
              </el-form-item>

              <el-form-item label="关键词" style="margin-left: 0px;">
                <el-tooltip class="item" effect="dark" content="关键词" placement="right">
                  <el-input v-model="liul_list.key"></el-input>
                </el-tooltip>
              </el-form-item>

              <el-form-item label="访问时间" style="margin-left: 0px;">
                <el-tooltip class="item" effect="dark" content="访问时间" placement="right">
                  <el-input v-model="liul_list.fw_time"></el-input>
                </el-tooltip>
              </el-form-item>
              <el-form-item label="浏览器" style="margin-left: 0px;">
                <el-tooltip class="item" effect="dark" content="浏览器" placement="right">
                  <el-input v-model="liul_list.browser"></el-input>
                </el-tooltip>
              </el-form-item>
              <el-form-item label="手机系统" style="margin-left: 0px;">
                <el-tooltip class="item" effect="dark" content="手机系统" placement="right">
                  <el-input v-model="liul_list.client_type"></el-input>
                </el-tooltip>
              </el-form-item>

              <el-form-item label="竞价来源页" style="margin-left: 0px;">
                <el-tooltip class="item" effect="dark" content="竞价来源页" placement="right">
                  <el-input type="textarea" v-model="liul_list.from_url" :autosize="{ minRows: 1, maxRows:3}"></el-input>
                </el-tooltip>
              </el-form-item>

              <el-form-item label="用户落地页" style="margin-left: 0px;">
                <el-tooltip class="item" effect="dark" content="用户落地页" placement="right">
                  <el-input type="textarea" v-model="liul_list.chat_url" :autosize="{ minRows: 1, maxRows:3}"></el-input>
                </el-tooltip>
              </el-form-item>

              <el-form-item label="对话内容" style="margin-left: 0px;">
                <el-tooltip class="item" effect="dark" content="对话内容" placement="right">
                  <el-input type="textarea" v-model="liul_list.chat_message" :autosize="{ minRows: 5, maxRows:20}"></el-input>
                </el-tooltip>
              </el-form-item>

              <el-button type="primary" @click="cancel">返回</el-button>
            </el-form>
          </div>
        </el-main>
        <el-footer>
          <Footer />
        </el-footer>
      </el-container>
    </el-container>
    <router-view /><!-- 路由出口 -->
  </el-container>
</template>

<script>
  import LeftMenu from '@/components/LeftMenu0.vue'
  import HeaderTop from '@/components/Top.vue'
  import Footer from '@/components/Footer.vue'

  export default {
    name: 'bug_xg',
    // 注册组件
    components: {
      LeftMenu,
      HeaderTop,
      Footer
    },
    props: {},
    // data() 为变量赋值等
    data() {
      return {
        is_show: true,
        liul_list: {
          id: '',
          account: '',
          ip: '',
          key: '',
          fw_time: '',
          from_url: '',
          chat_url: '',
          browser: '',
          client_type: '',
          chat_message: '',
        },
      }
    },
    computed: {},
    watch: {},
    created() {
      // 获取id
      const rec_id = this.$route.query.id
      const that = this
      let req = {
        id: Number(rec_id),
      }
      this.$axios.post('/apis/fkrecord/one', req)
        .then(function (res) {
          console.log(res)
          that.liul_list = res.data.Data
          that.liul_list.chat_message = that.liul_list.chat_message.replace(/bs-split/g, '\n')
        })
    },
    mounted: {},
    methods: {
      cancel() {
        window.history.go(-1);
      }
    }
  }

</script>

<style scoped="scoped">
  .list_content {
    max-width: 800px;
  }

  .el-form-item__label {
    max-width: 200px !important;
  }

  /*大框体样式*/
  .el-main {

    height: calc(100vh - 120px);
  }

</style>
