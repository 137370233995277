<template>
    <div class="qjsz_all">
        <div class="qjsz">
            <div class="class2">
                <div class="class2_1">开启升话宝</div>
                <div class="class2_2">
                    <el-switch v-model="is_open_shb_bool" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                    <font color="red" style="text-align: center;width: 500px;">{{conf.account}}</font>
                </div>
            </div>
            <div class="class3">
                <el-tooltip class="item" effect="dark" content="请将下方商务通代码提交保存后，即可获得安装代码" placement="right">
                    <el-input placeholder="" v-model="conf.web_code" id="webcode" @focus="qxfz(1)" @blur="zqx">
                        <template slot="prepend">页面代码</template>
                    </el-input>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="请将下方商务通代码提交保存后，即可获得安装代码" placement="right">
                    <el-input placeholder="" v-model="conf.swt_code" id="swtcode" @focus="qxfz(2)" @blur="zqx">
                        <template slot="prepend">商务通代码</template>
                    </el-input>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="请将下方商务通代码提交保存后，即可获得安装代码" placement="right">
                    <el-input placeholder="" v-model="conf.shb360_code" id="shb360code" @focus="qxfz(3)" @blur="zqx">
                        <template slot="prepend">360代码</template>
                    </el-input>
                </el-tooltip>
                <!-- ************************* 新版代码 *********************************** -->
                <!-- <div v-if="isSwtShow">
                    <el-tooltip v-if="isSwtShow" class="item" effect="dark" content="请将下方商务通代码提交保存后，即可获得安装代码" placement="right">
                        <el-input placeholder="" v-model="newWebCode" id="newwebcode" @focus="qxfz(4)" @blur="zqx">
                            <template slot="prepend">新版页面代码</template>
                        </el-input>
                    </el-tooltip>
                    <el-tooltip v-if="isSwtShow" class="item" effect="dark" content="请将下方商务通代码提交保存后，即可获得安装代码" placement="right">
                        <el-input placeholder="" v-model="newSwtCode" id="newswtcode" @focus="qxfz(5)" @blur="zqx">
                            <template slot="prepend">新版商务通代码</template>
                        </el-input>
                    </el-tooltip>
                    <el-tooltip v-if="isSwtShow" class="item" effect="dark" content="请将下方商务通代码提交保存后，即可获得安装代码" placement="right">
                        <el-input placeholder="" v-model="new360Code" id="newshb360code" @focus="qxfz(6)" @blur="zqx">
                            <template slot="prepend">新版360代码</template>
                        </el-input>
                    </el-tooltip>
                </div> -->
                <!-- *************************************************************************** -->
                <el-tooltip class="item" effect="dark" content="请将商务通生成网页代码，获取仅超链接，填写在这里。点击下方的保存按钮，以获取上方的安装代码" placement="right">
                    <el-input placeholder="如：http://kft.zoosnet.net/LR/Chatpre.aspx?id=KFT22856036&lng=cn" v-model="dialog_address" class="input-with-select">
                        <el-select @change="dialog_type_chage" v-model="conf.dialog_type" slot="prepend">
                            <el-option label="商务通" value="商务通"></el-option>
                            <el-option label="快商通" value="快商通"></el-option>
                            <el-option label="易聊" value="易聊"></el-option>
                            <el-option label="wicomm" value="wicomm"></el-option>
                            <el-option label="其他" value="其他"></el-option>
                        </el-select>
                        <el-button slot="append" icon="el-icon-bottom-right">
                            <el-link type="primary" @click='djce'>点击测试</el-link>
                        </el-button>
                    </el-input>
                </el-tooltip>

                <el-tooltip class="item" effect="dark" content="请将推广站点填入,点击测试即可看到效果" placement="right">
                    <el-input placeholder="" v-model="conf.tg_zd">
                        <template slot="prepend">主推站点</template>
                        <el-button slot="append" icon="el-icon-bottom-right">
                            <el-link type="primary" @click='dj_zd'>点击测试</el-link>
                        </el-button>
                    </el-input>
                </el-tooltip>
            </div>
            <!-- <div class="class4">
                <div class="class4_1">
                    <el-checkbox v-model="checked">跳转版是否秒跳 (0=秒跳,1=不秒跳)</el-checkbox>
                </div>
                <el-tooltip class="item" effect="dark" content="当访客访问着陆页时，在着陆页浏览多久以后，开始显示升话宝对话窗口" placement="right">
                    <div class="class4_2">
                        <el-input-number v-model="conf.shb_show_time" controls-position="right" @change="handleChange" :min="0" :max="300" label="描述文字"></el-input-number>
                    </div>
                </el-tooltip>
            </div> -->

            <!-- <el-tooltip class="item" effect="dark" content="时间结束后，是否自动跳转到商务通,默认不勾选。只有访客点击发送按钮，才会跳转到商务通。【界面风格为页内对话版除外】 " placement="right">
                <div class="class4">
                    <div class="class4_1">
                        <span style="color:gray">升话宝显示时间首次</span>
                    </div>
                    <div class="class4_2">
                        <el-tooltip class="item" effect="dark" content="访客首次进入着陆页时，升话宝界面显示的时长" placement="right">
                            <el-input-number controls-position="right" v-model="conf.first_enter_time" @change="handleChange" :min="0" :max="999" label="描述文字"></el-input-number>
                        </el-tooltip>
                    </div>
                </div>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="时间结束后，是否自动跳转到商务通,默认不勾选。只有访客点击发送按钮，才会跳转到商务通。【界面风格为页内对话版除外】 " placement="right">
                <div class="class4">
                    <div class="class4_1">
                        <span style="color:gray">升话宝显示时间再次</span>
                    </div>
                    <el-tooltip class="item" effect="dark" content="访客再次进入着陆页时，升话宝界面显示的时长" placement="right">
                        <div class="class4_2">
                            <el-input-number controls-position="right" v-model="conf.next_enter_time" @change="handleChange" :min="0" :max="999" label="描述文字"></el-input-number>
                        </div>
                    </el-tooltip>
                </div>
            </el-tooltip> -->
            <div class="class5">
                <el-input placeholder="请输入座机或手机号必须为电话格式" v-model="conf.phone">
                    <template slot="prepend" class="class5_bt">1营销电话</template>
                </el-input>
                <el-tooltip class="item" effect="dark" content="该项为空，则代表不开启该功能" placement="right">
                    <el-input placeholder="在线咨询" v-model="conf.kf_card">
                        <template slot="prepend" class="class5_bt">2客服名片</template>
                    </el-input>
                </el-tooltip>
                <el-input placeholder="请输入用于访客对话中显示的头像图片地址" v-model="conf.custom_pic">
                    <template slot="prepend">3客人头像</template>
                </el-input>
                <el-input placeholder="请输入用于客服对话中显示的头像图片地址" v-model="conf.kf_pic">
                    <template slot="prepend">4客服头像</template>
                </el-input>
                <el-tooltip class="item" effect="dark" placement="right">
                    <div slot="content">当浏览器升级或搜索引擎禁止获取访客搜索关键词时，<br />将您在此填写的关键词显示到对话窗口中</div>
                    <el-input placeholder="在线咨询中心" v-model="conf.key_word">
                        <template slot="prepend">5默认关键词</template>
                    </el-input>
                </el-tooltip>
                <el-input placeholder="用于升话宝对话窗口顶部展示的标题名称" v-model="conf.top_title">
                    <template slot="prepend">6窗口标题</template>
                </el-input>
                <el-input placeholder="用于升话宝对话窗口底部展示的标题名称" v-model="conf.bottom_title">
                    <template slot="prepend">7底部标题</template>
                </el-input>
                <el-tooltip class="item" effect="dark" content="该项如果不填写，则不会在对话框显示客服微信号的悬浮" placement="right">
                    <el-input placeholder="请输入客服微信号" v-model="conf.wx_number">
                        <template slot="prepend" class="class5_bt">客服微信号</template>
                    </el-input>
                </el-tooltip>
                <div class="class2">
                    <div class="class2_1" style="font-size:14px;color: #909399;">根据坐席更新手机号</div>
                    <el-tooltip placement="top">
                        <div slot="content">开启后会自动抓取商务通客服名片上 后11位 的电话号码。</div>
                        <div class="class2_2">
                            <el-switch v-model="is_copywx_bool" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                        </div>
                    </el-tooltip>
                </div>
                <el-tooltip class="item" effect="dark" content="用于区分升话宝带来的访客标记，为空则不记录该标记" placement="right">
                    <el-input placeholder="xzy" v-model="conf.krsm">
                        <template slot="prepend">客人说明:</template>
                    </el-input>
                </el-tooltip>
                <div class="el-radiox">
                    <div id="swt_open">商务通打开方式</div>
                    <div class="swt_open2">
                        <template>
                            <el-radio v-model="tz_type" label="1">location.href</el-radio>
                            <!-- <el-radio v-model="tz_type" label="2">openZoosUrl</el-radio> -->
                        </template>
                    </div>
                </div>
                <el-tooltip class="item" effect="dark" placement="right">
                    <div slot="content">可以将您在51la或者站长之家的统计代码粘贴进来。<br />以方便对比、区分统计升话宝带来的对话</div>
                    <el-input placeholder="网址：http或https开头的网址" v-model="conf.cnzz">
                        <template slot="prepend">嵌入统计代码（js）</template>
                    </el-input>
                </el-tooltip>
            </div>
            <div class="class6">
                <el-button type="primary" @click='onSubmit'>保存</el-button>
            </div>
        </div>
        <div class="qjsztp"><img src="../assets/img/qjsz.jpg"></div>

    </div>

</template>
<script>
//是否开启升话宝的开关
export default {
    name: 'QJSZ',
    data() {
        return {
            // isSwtShow: false,
            newWebCode: "",
            newSwtCode: "",
            new360Code: "",
            // dialog_type: '商务通',
            tz_type: '1',
            select: '',
            dialog_address: '',
            conf: {
                is_copy_wx: 0,
                account: '',
                swt_code: '',
                web_code: '',
                is_open_shb: 1,
                swt_address: '',
                kst_address: '',
                yl_address: '',
                dialog_type: '',
                shb_show_time: 0,
                is_first_enter: 2,
                first_enter_time: 30,
                next_enter_time: 30,
                phone: '',
                wx_number: '',
                kf_card: '',
                custom_pic: '',
                kf_pic: '',
                krsm: '',
                key_word: '',
                top_title: '',
                cnzz: '',
                bottom_title: '可以输入电话或者微信（聊天已加密，请放心咨询）'
            },
            radio: '',
            value: true,
            checked: true,
            checked1: false,
            input1: '',
            is_copywx_bool: false,

            is_open_shb_bool: true,
        }
    },

    created() {
        // 获取链接参数
        let rec_account = this.$route.query.account
        console.log('--------------------', rec_account)
        this.conf.account = rec_account
        this.getCueConf()
    },

    //显示时间为
    methods: {
        handleChange(value) {
            console.log(value);
        },

        dialog_type_chage() {
            switch (this.conf.dialog_type) {
                case "商务通":
                    // this.isSwtShow = true
                    let yuming = this.conf.swt_address.split("//")[1].split("/")[0];
                    let swtid = this.conf.swt_address.split("?")[1].split("&")[0].split("=")[1]
                    console.log("yuming: ", yuming);
                    console.log("swtid: ", swtid);

                    //  商务通链接
                    this.dialog_address = this.conf.swt_address
                    // 普通页面安装代码
                    this.conf.web_code = `<script src="https://shb.dreamsun.net/static/js/newshbwebjs/swt/newswtweb.js?yuming=` + yuming + `&swtid=` + swtid + `"><\/script>`
                    //  360安装代码
                    this.conf.shb360_code = `https://shb.dreamsun.net/static/js/newshbwebjs/swt/newswtweb.js?yuming=` + yuming + `&swtid=` + swtid
                    // 商务通后台安装代码
                    this.conf.swt_code = `<script src="https://shb.dreamsun.net/static/js/newshbwebjs/swt/newshbswt.js?account=` + this.conf.account + `"><\/script>`

                    break;
                case "快商通":
                    //  商务通链接
                    this.dialog_address = this.conf.kst_address
                    // 普通页面安装代码
                    this.conf.web_code = '<script src="https://shb.dreamsun.net/static/js/kstshblist.js?account=' + this.conf.account + '&type=1">' + '<\/script>'
                    //  360安装代码
                    this.conf.shb360_code = 'https://shb.dreamsun.net/static/js/kstshblist.js?account=' + this.conf.account
                    // 商务通后台安装代码
                    this.conf.swt_code = '<script src="https://shb.dreamsun.net/static/js/kst101.js?tz=1">' + '<\/script>'
                    // this.isSwtShow = false
                    break;
                case "易聊":
                    // 易聊链接
                    this.dialog_address = this.conf.yl_address
                    // 普通页面安装代码
                    this.conf.web_code = this.conf.web_code = '<script src="https://shb.dreamsun.net/static/js/ylshblist.js?account=' + this.conf.account + '&type=1">' + '<\/script>'
                    //  360 页面安装代码
                    this.conf.shb360_code = 'https://shb.dreamsun.net/static/js/ylshblist.js?account=' + this.conf.account
                    // 易聊后台安装代码
                    this.conf.swt_code = 'let yl_js = document.createElement("script"); yl_js.src = "https://shb.dreamsun.net/static/js/yl101.js?tz=1"; document.body.appendChild(yl_js);'
                    // this.isSwtShow = false
                    break;
            }
        },

        //全选复制
        qxfz(codeType) {
            var Input = ''
            if (codeType == 1) {
                Input = document.getElementById('webcode')
            } else if (codeType == 2) {
                Input = document.getElementById('swtcode')
            } else if (codeType == 3) {
                Input = document.getElementById('shb360code')
            } else if (codeType == 4) {
                Input = document.getElementById('newwebcode')
            } else if (codeType == 5) {
                Input = document.getElementById('newswtcode')
            } else if (codeType == 6) {
                Input = document.getElementById('newshb360code')
            }

            Input.focus()
            Input.select()
            try {
                document.execCommand('copy');
                this.$message('复制成功 !');
            } catch (e) { }
        },
        //再全选
        zqx() {
            console.log("aaa")
        },

        djce() {
            if (this.dialog_address != '' && this.dialog_address != undefined) {
                window.open(this.dialog_address)
            }
        },
        dj_zd() {
            if (this.conf.tg_zd != '' && this.conf.tg_zd != undefined) {
                window.open(this.conf.tg_zd)
            }
        },
        onSubmit() {
            // 保存升话宝设置
            let that = this;
            let req = this.conf
            if (this.is_copywx_bool == true) {
                req.is_copy_wx = 1
            } else {
                req.is_copy_wx = 0
            }
            if (this.is_open_shb_bool) {
                req.is_open_shb = 1
            } else {
                req.is_open_shb = 0
            }

            if (req.dialog_type == "商务通") {
                req.swt_address = this.dialog_address
            }
            if (req.dialog_type == "快商通") {
                req.kst_address = this.dialog_address
            }
            if (req.dialog_type == "易聊") {
                req.yl_address = this.dialog_address
            }

            this.$axios.post('/apis/conf/shb/setting', req).then(function (res) {
                if (res.data.Code == "200") {
                    // 跳转到窗口风格
                    that.$router.push({
                        path: '/dialog/ckfg',
                        query: {
                            account: that.conf.account,
                        }
                    })
                } else {
                    alert(res.data.Msg)
                }
            })
        },

        GetQueryString(url, $name) {
            let $index = url.indexOf($name + "=");
            let $e = '';
            if ($index > 1) {
                $e = url.substring($index + $name.length + 1);
                if (($index = $e.indexOf("&")) > 0) {
                    $e = $e.substring(0, $index);
                }
            }
            return $e;
        },

        getCueConf() {
            // 获取用户设置
            let req = {
                account: this.conf.account
            }
            let that = this
            this.$axios.post('/apis/conf/shb', req).then(function (res) {
                console.log("返回值打印:", res)
                let re_date = res.data.Data
                if (res.data.Code == '200') {
                    if (re_date.is_copy_wx == 1) {
                        that.is_copywx_bool = true
                    } else {
                        that.is_copywx_bool = false
                    }
                    if (re_date.is_open_shb == 1) {
                        that.is_open_shb_bool = true
                    } else {
                        that.is_open_shb_bool = false
                    }
                    that.conf = re_date

                    switch (that.conf.dialog_type) {
                        case "商务通":
                            // that.isSwtShow = true
                            let yuming = that.conf.swt_address.split("//")[1].split("/")[0];
                            let swtid = that.conf.swt_address.split("?")[1].split("&")[0].split("=")[1]
                            console.log("yuming: ", yuming);
                            console.log("swtid: ", swtid);

                            //  商务通链接
                            that.dialog_address = that.conf.swt_address
                            // 普通页面安装代码
                            that.conf.web_code = `<script src="https://shb.dreamsun.net/static/js/newshbwebjs/swt/newswtweb.js?yuming=` + yuming + `&swtid=` + swtid + `"><\/script>`
                            //  360安装代码
                            that.conf.shb360_code = `https://shb.dreamsun.net/static/js/newshbwebjs/swt/newswtweb.js?yuming=` + yuming + `&swtid=` + swtid
                            // 商务通后台安装代码
                            that.conf.swt_code = `<script src="https://shb.dreamsun.net/static/js/newshbwebjs/swt/newshbswt.js?account=` + that.conf.account + `"><\/script>`

                            break;
                        case "快商通":
                            //  商务通链接
                            that.dialog_address = that.conf.kst_address
                            // 普通页面安装代码
                            that.conf.web_code = '<script src="https://shb.dreamsun.net/static/js/kstshblist.js?account=' + that.conf.account + '&type=1">' + '<\/script>'
                            //  360安装代码
                            that.conf.shb360_code = 'https://shb.dreamsun.net/static/js/kstshblist.js?account=' + that.conf.account
                            // 商务通后台安装代码
                            that.conf.swt_code = '<script src="https://shb.dreamsun.net/static/js/kst101.js?tz=1">' + '<\/script>'
                            // that.isSwtShow = false
                            break;
                        case "易聊":
                            // 易聊链接
                            that.dialog_address = that.conf.yl_address
                            // 普通页面安装代码
                            that.conf.web_code = that.conf.web_code = '<script src="https://shb.dreamsun.net/static/js/ylshblist.js?account=' + that.conf.account + '&type=1">' + '<\/script>'
                            //  360 页面安装代码
                            that.conf.shb360_code = 'https://shb.dreamsun.net/static/js/ylshblist.js?account=' + that.conf.account
                            // 易聊后台安装代码
                            that.conf.swt_code = 'let yl_js = document.createElement("script"); yl_js.src = "https://shb.dreamsun.net/static/js/yl101.js?tz=1"; document.body.appendChild(yl_js);'
                            // that.isSwtShow = false
                            break;
                    }

                }
            })
        }
    }
};

</script>

<style scoped>
.qjsz_all {
    display: flex;
    flex-direction: row;
}

.qjsz {
    display: flex;
    flex-direction: column;
    max-width: 800px;
}

.qjsztp {
    display: flex;
    align-items: center;
    max-width: 500px;
    padding-left: 10px;
}

.qjsztp img {
    width: 100%;
}

.el-select {
    width: 100px !important;
}

.code {
    display: flex;
}

.class2 {
    height: 40px;
    color: #767676;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}

.class2_1 {
    background-color: #fbfbfb;
    border: 1px solid #e6e6e6;
    height: 40px;
    line-height: 40px;
    width: 20%;
    padding: 0px 10px;
    text-align: center;
}

.class2_2 {
    border: 1px solid #e6e6e6;
    height: 40px;
    line-height: 40px;
    width: 80%;
    padding: 0px 10px;
    text-align: left;
    border-left: 0;
}

.class3 {
    height: 80px;
}

.class3 {
    height: auto;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
}

.class4 {
    display: flex;
    flex-direction: row;
    margin: 8px 0px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
}

.class4_1 {
    padding: 0px 10px;
    background: #f5f7fa;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #dcdfe6;
    font-size: 14px;
}

.class4_2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    line-height: 40px;
    padding: 0px 10px;
}

.class4 .el-input-number {
    width: 100px !important;
    text-align: center;
}

.el-input-number__decrease,
.el-input-number__increase {
    width: 30px !important;
}

.is-controls-right {
    margin-left: 10px;
}

.el-radiox {
    position: relative;
    font-size: 14px;
    line-height: normal;
    display: inline-table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 5px !important;
}

#swt_open {
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #f5f7fa;
    color: #909399;
    vertical-align: middle;
    display: table-cell;
    position: relative;
    padding: 0 20px;
    width: 130px !important;
    white-space: nowrap;
}

.swt_open2 {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    /*transition: border-color .2s cubic-bezier(.645,.045,.355,1);*/
    /*width: 100%;*/
    -webkit-rtl-ordering: logical;
    -webkit-writing-mode: horizontal-tb !important;
    vertical-align: middle;
    display: table-cell;
}

.class5 {
    height: auto;
}

.el-input-group__prepend {
    width: 130px !important;
}

.el-input-group {
    margin-top: 5px !important;
}

.class5_bt {
    width: 130px;
}

.class6 {
    margin: 10px;
    height: 40px;
}

.el-input-number {
    margin-right: 8px;
}
</style>
