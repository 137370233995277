<template>
  <el-container>
    <el-header>
      <HeaderTop />
    </el-header>
    <el-container>
      <el-aside width="200px">
        <LeftMenu />
      </el-aside>
      <el-container>
        <el-main>
          <div id="dzydy">
            <el-form :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="100px" class="demo-dynamic">
              <el-form-item prop="ydyType" label="分类">
                <el-col :span="12">
                  <el-input v-model="dynamicValidateForm.ydyType" width="200px"></el-input>
                </el-col>
              </el-form-item>

              <el-form-item prop="ydyPpc" label="匹配词">
                <el-col :span="12">
                  <el-input type="textarea" v-model="dynamicValidateForm.ydyPpc" width="200px"></el-input>
                </el-col>
              </el-form-item>

              <el-form-item v-for="(domain, index) in dynamicValidateForm.domains" :label="'引导语' + (index+1)" :key="domain.key" :prop="'domains.' + index + '.KgContent'">
                <div>
                  <el-col :span="12">
                    <el-input type="textarea" v-model="domain.kgContent"></el-input>
                  </el-col>
                </div>
                <div>
                  <span style="float:left;margin-left:20px">出现时间:</span>
                  <el-input-number style="float:left;margin-left:6px;" v-model="domain.waitTime" controls-position="right" :min="1" :max="300" label="停留时间">
                  </el-input-number>
                </div>
                <el-button style="margin-left:10px;float:left" @click.prevent="removeDomain(domain)">删除</el-button>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="addDomain">新增引导语</el-button>
                <el-button type="primary" @click="submitForm()">提交</el-button>
                <el-button type="primary" @click="tzYdyInfo()">查看</el-button>
                <el-button @click="resetForm('dynamicValidateForm')">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-main>
        <el-footer style="position:fixed;bottom:0px;width:90%">
          <Footer />
        </el-footer>
      </el-container>
    </el-container>
    <router-view /><!-- 路由出口 -->
  </el-container>
</template>

<script>
  import HeaderTop from '@/components/Top.vue'
  import LeftMenu from '@/components/LeftMenu.vue'
  import Footer from '@/components/Footer.vue'
  export default {
    name: 'DZYDY',
    // 注册组件
    components: {
      LeftMenu,
      HeaderTop,
      Footer,
    },
    data() {
      return {
        name: "YDYDZ",
        UserInfo: {},
        YdyIndex: 1,
        dynamicValidateForm: {
          // 引导语分类
          ydyType: "分类1",
          // 引导语匹配词
          ydyPpc: "匹配词1",
          domains: [
            // 引导语内容
            {
              keyOrder: 1,
              waitTime: 0,
              kgContent: '',
              key: Date.now(),
            }
          ],
        },
        conf: {},
      }
    },
    created() {
      let that = this;
      // 缓存获取用户信息
      const childUser = sessionStorage.getItem('sec_user');
      that.UserInfo = JSON.parse(childUser);
      console.log("that.UserInfo:", that.UserInfo);
    },
    methods: {
      tzYdyInfo: function () {
        this.$router.push({
          path: '/dialog/dzydy/info',
          query: {
            account: this.account
          }
        })
      },

      submitForm() {
        let that = this;
        let reqObj = {};
        reqObj.userId = that.UserInfo.id;
        reqObj.keyNames = that.dynamicValidateForm.ydyPpc;
        reqObj.keyType = that.dynamicValidateForm.ydyType;
        reqObj.keyNames = "," + reqObj.keyNames + ",";
        reqObj.keyNames = reqObj.keyNames.replace(/\n/g, ',');
        // 发送添加请求
        that.$axios.post('/apis/keyType/add', reqObj).then(function (res) {
          if (res.data.Code === '200') {
            for (let ydyOne of that.dynamicValidateForm.domains) {
              ydyOne.KtId = Number(res.data.Data);
              that.$axios.post('/apis/keyGuide/add', ydyOne).then(function (res2) {
                if (res2.data.Code == '200') {
                  alert("添加引导语 " + ydyOne.kgContent + " 成功!");
                } else {
                  alert("添加引导语 " + ydyOne.kgContent + " 失败!--" + res2.data.Msg);
                }
              })
            };
            that.tzYdyInfo();
          } else {
            alert("添加分类 " + reqObj.keyNames + " 失败!--" + res.data.Msg);
          };
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      removeDomain(item) {
        var index = this.dynamicValidateForm.domains.indexOf(item)
        if (index !== -1) {
          this.dynamicValidateForm.domains.splice(index, 1)
        }
        this.YdyIndex -= 1;
      },
      addDomain() {
        let that = this
        that.dynamicValidateForm.domains.push({
          kgContent: '',
          waitTime: 0,
          keyOrder: that.YdyIndex += 1,
          key: Date.now(),
        });
      }
    },
  }

</script>

<style scoped="scoped">
  #dzydy {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 80%;
    height: 76%;
    text-align: center;
    /* background-color: burlywood; */
    border: 1px solid rgb(230, 230, 230);
  }

  #ydy_key {
    position: relative;
    width: 40%;
    height: 70%;
    margin-top: 8%;
    background-color: #d3dce6;
    float: left;
    margin-left: 1%;
    border: #0085e8 1px solid;
    box-sizing: border-box;
    border-radius: 25px;
    line-height: 40px;
    border: 1px solid rgb(230, 230, 230);
  }

  #ydy_conf {
    position: relative;
    width: 56%;
    height: 70%;
    margin-top: 8%;
    background-color: #e5e9f2;
    float: left;
    margin-left: 1%;
    margin-right: 1%;
    border: #0085e8 1px solid;
    box-sizing: border-box;
    border-radius: 25px;
    line-height: 40px;
    border: 1px solid rgb(230, 230, 230);
  }

</style>
