<template>
  <div class="aqsz">

    <el-tabs type="border-card">
      <el-tab-pane>
        <span slot="label"><i class="el-icon-s-platform"></i>ip屏蔽</span>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>黑名单</span><span style="font-size:5px;color:gray">(每个ip之间用#分隔,例如 127.0.0.1#211.211.211)</span>

          </div>
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" v-model="conf.ban_ip"></el-input>
          　<span style="color: #909399;">系统已屏蔽百度总部IP段，不会影响百度SEO</span>
        </el-card>
      </el-tab-pane>

      <el-tab-pane>
        <span slot="label"><i class="el-icon-date"></i>开启时段</span>
        <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" placeholder="" v-model="conf.ban_time">
        </el-input>
        　<span style="color: #909399;"> 提示：每个时段之间用#分隔,例如 1~2#13~14 (精确到小时)</span>
      </el-tab-pane>

      <el-tab-pane>
        <span slot="label"><i class="el-icon-place"></i>地区屏蔽</span>
        <template>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="黑名单" name="ban_city">
              <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" placeholder="黑名单" v-model="conf.ban_city">
              </el-input>
            </el-tab-pane>
            <el-tab-pane label="白名单" name="ban_city2">
              <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" placeholder="白名单" v-model="conf.allow_city"></el-input>
            </el-tab-pane>
          </el-tabs>
        </template>
        <span style="color: #909399;">提示：每个城市之间用#分隔,例如 北京市#上海市#广东省 (精确到省份或城市)</span>
      </el-tab-pane>

      <el-tab-pane>
        <span slot="label"><i class="el-icon-date"></i>域名屏蔽</span>
        <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" placeholder="">
        </el-input>
        　<span style="color: #909399;"> 提示：每个域名之间用#分隔</span>
      </el-tab-pane>
    </el-tabs>
    <div class="save">
      <el-button type="primary" @click='onSubmit'>保存</el-button>
    </div>

  </div>
</template>
<script>
  //开启时段复选框
  var h = [''];
  for (var i = 0; i < 24; i++) {
    if (i <= 9) {
      h[i] = "0" + i;

    } else {
      h[i] = '' + i;
    }
  }
  const cityOptions = h;
  export default {
    data() {
      return {
        conf: {
          account: '',
          ban_ip: '',
          ban_time: '',
          ban_city: '',
          allow_city: '',
        },
        textarea1: '',
        textarea2: '',
        activeName: 'ban_city',
        //开启时段复选框
        checkAll: false,
        checkedCities: h,
        //	checkedCities: [h[0],h[1]],
        cities: cityOptions,
        isIndeterminate: true
      }
    },

    created() {
      // 获取链接参数
      const rec_account = this.$route.query.account
      console.log('--------------------', rec_account)
      this.conf.account = rec_account
      this.getCueConf()
    },
    methods: {
      handleCheckAllChange(val) {
        this.checkedCities = val ? cityOptions : [];
        this.isIndeterminate = false;
      },
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.cities.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
      },

      onSubmit() {
        // 保存升话宝设置
        // 克隆一个对象的值
        let that = this;
        const req = this.conf
        this.$axios.post('/apis/conf/shb/setting', req).then(function (res) {
          if (res.data.Code == "200") {
            // 跳转到窗口风格
            that.$router.push({
              path: '/dialog/qjsz',
              query: {
                account: that.conf.account,
              }
            })
          } else {
            alert(res.data.Msg)
          }
        })
      },

      getCueConf() {
        // 获取用户设置
        const req = {
          account: this.conf.account
        }
        const that = this
        this.$axios.post('/apis/conf/shb', req).then(function (res) {
          console.log("返回值打印:", res)
          const re_date = res.data.Data
          if (res.data.Code == '200') {
            that.conf = re_date
          }
        })
      }
    },

  }

</script>

<style scoped="scoped">
  .aqsz {
    max-width: 800px;
  }

  .save {
    margin: 10px;
  }

</style>
