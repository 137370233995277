<template>
  <el-container>
    <el-header>
      <HeaderTop />
    </el-header>
    <el-container>
      <el-aside width="200px">
        <LeftMenu />
      </el-aside>
      <el-container>
        <el-main>
          <div id="dzydy">
            <!-- 引导语分类 -->
            <div id="ydy_type">
              <el-table style="padding:5px;" :data="ydyTypeList" :cell-style="{padding:'2px 0'}" height="500" border stripe>
                <el-table-column prop="keyType" label="分类" align="center" width="220"></el-table-column>
                <el-table-column label="查看" width="80" align="center">
                  <template slot-scope="scope">
                    <el-button @click="handleYdyInfo(scope.row)" type="text" size="small">查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 引导语匹配词 -->
            <div id="ydy_info_right">
              <div style="padding:10px">
                <el-form v-show="showDetail1" :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="100px" class="demo-dynamic">
                  <el-form-item prop="keyType" label="分类">
                    <el-col :span="12">
                      <el-input v-model="dynamicValidateForm.keyType" width="200px"></el-input>
                    </el-col>
                  </el-form-item>

                  <el-form-item prop="keyNames" label="匹配词">
                    <el-col :span="12">
                      <el-input type="textarea" v-model="dynamicValidateForm.keyNames" width="200px"></el-input>
                    </el-col>
                  </el-form-item>

                  <el-form-item v-for="(domain, index) in dynamicValidateForm.domains" :label="'引导语' + (domain.keyOrder)" :key="domain.key" :prop="'domains.' + index + '.value'">
                    <div>
                      <el-col :span="12">
                        <el-input type="textarea" v-model="domain.kgContent"></el-input>
                      </el-col>
                    </div>
                    <div>
                      <span style="float:left;margin-left:20px">出现时间:</span>
                      <el-input-number style="float:left;margin-left:6px;" v-model="domain.waitTime" controls-position="right" :min="1" :max="300" label="停留时间">
                      </el-input-number>
                    </div>
                    <el-button style="margin-left:10px;float:left" @click.prevent="removeDomain(domain)">删除</el-button>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="tzDzydy">添加分类</el-button>
                    <el-button type="primary" @click="addDomain">新增引导语</el-button>
                    <el-button type="primary" @click="submitForm('dynamicValidateForm')">保存修改</el-button>
                    <el-button type="primary" @click="handleYdyDelete()">删除当前分类</el-button>
                    <el-button @click="resetForm('dynamicValidateForm')">重置</el-button>
                  </el-form-item>
                </el-form>
                <el-form v-show="showDetail2">
                  <el-form-item>
                    <el-button type="primary" @click="tzDzydy">添加分类</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
        </el-main>
        <el-footer style="position:fixed;bottom:0px;width:90%">
          <Footer />
        </el-footer>
      </el-container>
    </el-container>
    <router-view /><!-- 路由出口 -->
  </el-container>
</template>

<script>
  import HeaderTop from '@/components/Top.vue'
  import LeftMenu from '@/components/LeftMenu.vue'
  import Footer from '@/components/Footer.vue'
  export default {
    name: 'DZYDY',
    // 注册组件
    components: {
      LeftMenu,
      HeaderTop,
      Footer,
    },
    data() {
      return {
        name: "DZYDYINFO",
        showDetail1: true,
        showDetail2: false,
        CurrentType: {},
        UserInfo: {},
        YdyIndex: 1,
        // 引导语分类
        ydyTypeList: [],
        dynamicValidateForm: {
          keyType: "",
          // 引导语匹配词
          keyNames: "",
          domains: [
            // 引导语内容
            {
              keyOrder: 1,
              waitTime: 0,
              kgContent: '',
              key: Date.now(),
            }
          ],
        },
      }
    },
    created() {
      let that = this;
      // 缓存获取用户信息
      const childUser = sessionStorage.getItem('sec_user');
      that.UserInfo = JSON.parse(childUser);
      console.log("that.UserInfo:", that.UserInfo);

      that.getPpcTypeList();
    },
    methods: {
      getPpcTypeList() {
        let that = this;
        let reqObj = {};
        reqObj.userId = that.UserInfo.id;
        // 发送列表请求
        that.$axios.post('/apis/keyType/getKeyTypeCbbsWithType', reqObj).then(function (res) {
          if (res.data.Code === '200') {
            that.ydyTypeList = res.data.Data;
            console.log("that.ydyTypeList:", that.ydyTypeList)
            if (typeof that.ydyTypeList == "undefined" || that.ydyTypeList.length < 1) {
              that.showDetail1 = false;
              that.showDetail2 = true;
              return
            }

            // 获取引导语
            let req2 = {};
            that.CurrentType = that.ydyTypeList[0];
            req2.ktId = that.ydyTypeList[0].ktId;
            that.$axios.post('/apis/keyGuide/search', req2).then(function (res2) {
              if (res2.data.Code === '200') {
                that.dynamicValidateForm.domains = res2.data.Data;
                that.dynamicValidateForm.keyType = that.ydyTypeList[0].keyType;
                that.dynamicValidateForm.keyNames = that.ydyTypeList[0].keyNames.replace(/,/g, '\n');
              }
            })
          } else {
            alert("获取分类列表失败!--" + res.data.Msg);
          };
        });
      },
      handleYdyInfo: function (row) {
        let that = this;
        that.CurrentType = row;
        that.dynamicValidateForm.keyType = that.CurrentType.keyType;
        that.dynamicValidateForm.keyNames = that.CurrentType.keyNames.replace(/,/g, '\n');
        // 获取引导语
        let req = {};
        req.ktId = that.CurrentType.ktId;
        that.$axios.post('/apis/keyGuide/search', req).then(function (res2) {
          if (res2.data.Code === '200') {
            that.dynamicValidateForm.domains = res2.data.Data;
          }
        })
      },
      handleYdyDelete: function () {
        let that = this;
        if (confirm('确定删除本条分类吗?')) {
          // 发送删除请求
          if (that.CurrentType.ktId != 0 && typeof that.CurrentType.ktId != "undefined") {
            let req = {};
            req.ktId = that.CurrentType.ktId;
            that.$axios.post('/apis/keyType/del', req).then(function (res2) {
              if (res2.data.Code === '200') {
                alert("删除成功!");
                window.location.reload();
              }
            })
          } else {
            alert("分类id获取失败!--", that.KetId);
          }
        }
      },

      submitForm(formName) {
        // 提交方法
        let that = this;
        let req = {};
        req.kt = {};
        req.kt.ktId = that.CurrentType.ktId;
        req.kt.keyNames = that.dynamicValidateForm.keyNames.replace(/\n/g, ',');
        req.kt.keyType = that.dynamicValidateForm.keyType;
        req.kt.userId = that.UserInfo.id;
        req.kgs = [];
        for (let ydyOne of that.dynamicValidateForm.domains) {
          let reqYdy = {};
          reqYdy.keyOrder = ydyOne.keyOrder;
          reqYdy.waitTime = ydyOne.waitTime;
          reqYdy.kgContent = ydyOne.kgContent;
          req.kgs.push(reqYdy);
        }

        that.$axios.post('/apis/keyType/updKtAndKg', req).then(function (res2) {
          if (res2.data.Code === '200') {
            alert("更新成功!");
            window.location.reload();
          } else {
            alert("更新失败! --" + res2.data.Msg);
          }
        })
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      removeDomain(item) {
        let that = this;
        // 删除引导语
        let req = {};
        req.kgId = item.kgId;
        if (confirm('确定删除本条引导语吗?')) {
          // 发送删除请求
          var index = that.dynamicValidateForm.domains.indexOf(item)
          if (index !== -1) {
            that.dynamicValidateForm.domains.splice(index, 1)
          }
          that.YdyIndex -= 1;
          alert("删除引导语成功!");
        }
      },
      addDomain() {
        let that = this
        let indexArr = [];
        for (let domainsOne of that.dynamicValidateForm.domains) {
          indexArr.push(domainsOne.keyOrder);
        }
        for (let domainsOne of that.dynamicValidateForm.domains) {
          if (domainsOne.keyOrder > 1 && indexArr.indexOf(domainsOne.keyOrder - 1) == -1) {
            that.dynamicValidateForm.domains.splice(domainsOne.keyOrder - 2, 0, {
              waitTime: 0,
              kgContent: '',
              keyOrder: domainsOne.keyOrder - 1,
              key: Date.now(),
            });
            return;
          }
        }
        that.dynamicValidateForm.domains.push({
          waitTime: 0,
          kgContent: '',
          keyOrder: that.dynamicValidateForm.domains.length + 1,
          key: Date.now(),
        });
      },
      tzDzydy() {
        this.$router.push({
          path: '/dialog/dzydy',
        })
      },
    }
  }

</script>

<style scoped="scoped">
  #dzydy {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 80%;
    height: 660px;
    text-align: center;
    /* background-color: burlywood; */
    border: 1px solid rgb(230, 230, 230);
  }

  #ydy_info_right {
    overflow: scroll;
    height: 600px;
  }

  #ydy_type {
    position: relative;
    width: 20%;
    /* height: 70%; */
    top: 8%;
    float: left;
    margin-left: 1%;
    box-sizing: border-box;
    line-height: 40px;
    left: 5%;
    border: 1px solid rgb(230, 230, 230);
  }

  #ydy_info_right {
    position: relative;
    width: 70%;
    float: right;
    top: 8%;
    right: 1%;
    border: 1px solid rgb(230, 230, 230);
  }

</style>
