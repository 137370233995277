<template>
	<el-container>
		<el-header>
			<HeaderTop />
		</el-header>
		<el-container>
			<el-aside width="200px">
				<LeftMenu />
			</el-aside>
			<el-container style="max-height: 844px; border: 1px solid #eee">
				<el-main>
					<h1>专题页安装说明</h1>
					<div class="info">
						注意：用此方法，需要修改所有竞价账户的着陆页
					</div>
					<div class="info">
						将我们提供的文件夹上传到账户域名下；例如你的账户域名是http://m.mycode.com
					</div>
					
					<div class="info">
						然后访问这个文件夹下的update.php文件，点击“是的”，开始更新：
					</div>
					<img src="../../assets/img/install_show6.jpg" />
					<div class="info">
						更新成功后，会自动跳转到生成的专题页
					</div>
					<img src="../../assets/img/install_show7.jpg" />
					<div class="info">
						然后竞价账户，修改所有着陆页为这个URL即可，如：http://m.mycode.com/shb/
					</div>
					<div class="info">
						用百度推广客户端的文字替换，或者其他批量修改着陆页的方法，将竞价着陆页全部换成此专题页。
					</div>
					<img src="../../assets/img/install_show8.jpg" />
				</el-main>
				<el-footer>
					<Footer />
				</el-footer>
			</el-container>
		</el-container>
		<router-view />
		<!-- 路由出口 -->
	</el-container>
</template>
<script>
	import HeaderTop from '@/components/Top.vue'
	import LeftMenu from '@/components/LeftMenu.vue'
	import Footer from '@/components/Footer.vue'
	export default {
		data() {
			return {

			}
		},
		// 注册组件
		components: {
			LeftMenu,
			HeaderTop,

			Footer,
		},
	}
</script>

<style>

</style>