<template>
    <div class="left_menu">
        <div class="Breadcrumb">
            <el-page-header @back='goBack' :content='showbt' :style='mystl'>
            </el-page-header>
        </div>
        <el-menu class="el-menu-vertical" :default-openeds="openeds" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" :default-active="activerouter" :unique-opened="true">
            <el-submenu index="1">
                <template slot="title">
                    <i class="el-icon-user"></i>
                    <span>会员管理</span>
                </template>
                <el-menu-item-group>
                    <!-- <template slot="title">分组一</template> -->
                    <router-link :to="{path:'/user/list',query:{account:account}}">
                        <el-menu-item index="/user/list">会员列表</el-menu-item>
                    </router-link>
                    <router-link to="/user/add">
                        <el-menu-item :style='qxgl' index="/user/add">添加人员</el-menu-item>
                    </router-link>
                </el-menu-item-group>
            </el-submenu>
            <!-- <el-menu-item index="2" disabled>
                    <i class="el-icon-setting"></i>
                    <span slot="title">添加管理</span>
                </el-menu-item> -->
            <!-- <el-menu-item index="3" disabled>
                <i class="el-icon-setting"></i>
                <span slot="title">添加级别</span>
            </el-menu-item> -->
            <el-submenu index="4">
                <router-link to="/dialog/setting"></router-link>
                <template slot="title">
                    <i class="el-icon-setting"></i>
                    <span>对话设置</span>
                </template>
                <el-menu-item-group>
                    <!-- <template slot="title">分组一</template> -->
                    <el-menu-item @click='tzQjsz' index="/dialog/qjsz">{{qjsz}}</el-menu-item>
                    <el-menu-item @click='tzCkfg' index="/dialog/ckfg">{{ckfg}}</el-menu-item>
                    <el-menu-item @click='tzDhnr' index="/dialog/dhnr">{{dhnr}}</el-menu-item>
                    <!-- <el-menu-item @click='tzDhjh' index="/dialog/dhjh">{{dhjh}}</el-menu-item> -->
                    <el-menu-item @click='tzFkjc' index="/dialog/fkjc">{{fkjc}}</el-menu-item>
                    <el-menu-item @click='tzZtsz' index="/dialog/ztsz">{{ztsz}}</el-menu-item>
                    <!-- <el-menu-item @click='tzAqsz' index="/dialog/aqsz">{{aqsz}}</el-menu-item> -->
                    <el-menu-item @click='tzDzydy' index="/dialog/dzydy">{{dzydy}}</el-menu-item>
                </el-menu-item-group>
            </el-submenu>
            <!-- <el-menu-item index="5" disabled>
                <i class="el-icon-setting"></i>
                <span slot="title">新医疗</span>
            </el-menu-item> -->

            <el-submenu index="6" v-if="false">

                <template slot="title">
                    <i class="el-icon-info"></i>
                    <span>安装说明</span>
                </template>
                <el-menu-item-group>
                    <router-link :to="{path:'/dialog/install_show',query:{account:account}}">
                        <el-menu-item index="/dialog/install_show"><i class="el-icon-chat-dot-round"></i>商务通</el-menu-item>
                    </router-link>
                    <router-link :to="{path:'/dialog/install_show2',query:{account:account}}">
                        <el-menu-item index="/dialog/install_show2"> <i class="el-icon-chat-dot-square"></i>快商通</el-menu-item>
                    </router-link>
                </el-menu-item-group>
                <el-menu-item-group>
                    <router-link :to="{path:'/dialog/install_show3',query:{account:account}}">
                        <el-menu-item index="/dialog/install_show3"><i class="el-icon-document-remove"></i>单页面</el-menu-item>
                    </router-link>
                    <router-link :to="{path:'/dialog/install_show4',query:{account:account}}">
                        <el-menu-item index="/dialog/install_show4"><i class="el-icon-document-copy"></i>多页面</el-menu-item>
                    </router-link>
                    <router-link :to="{path:'/dialog/install_show5',query:{account:account}}">
                        <el-menu-item index="/dialog/install_show5"><i class="el-icon-folder-checked"></i>专题页</el-menu-item>
                    </router-link>
                </el-menu-item-group>
            </el-submenu>

            <router-link to="/user/feedback_add">
                <el-menu-item index="/user/feedback_add">
                    <i class="el-icon-edit"></i>
                    <span slot="title">意见反馈</span>
                </el-menu-item>
            </router-link>
            <router-link to="/sign/out">
                <el-menu-item index="7">
                    <i class="el-icon-setting"></i>
                    <span slot="title">退出系统</span>
                </el-menu-item>
            </router-link>
        </el-menu>
    </div>
</template>

<script>
export default {
    name: 'LeftMenu',
    data() {
        return {
            activerouter: '',
            qxgl: {},
            openeds: ['6'],
            uniqueOpened: false,
            account: '',
            showbt: '页面详情',
            mystl: '{color:white;}',
            ckfg: '窗口风格',
            dhnr: '对话内容',
            // dhjh: '对话交互',
            fkjc: '后退设置',
            ztsz: '专题设置',
            aqsz: '安全设置',
            qjsz: '全局设置',
            dzydy: "定制引导语",
        }
    },

    created() {
        // 从缓存获取user
        const login_user = sessionStorage.getItem('user')
        let login_user_obj = JSON.parse(login_user)
        if (login_user_obj.level == 3) {
            this.qxgl = {
                display: 'none',
            }
        }

        const child_user = sessionStorage.getItem('sec_user')
        let user_obj = JSON.parse(child_user)
        this.account = user_obj.account
    },

    mounted() {
        this.activerouter = this.$route.path
    },
    methods: {
        goBack() {
            history.go(-1);
        },
        tzCkfg() {
            this.$router.push({
                path: '/dialog/ckfg',
                query: {
                    account: this.account
                }
            })
        },
        tzDhnr() {
            this.$router.push({
                path: '/dialog/dhnr',
                query: {
                    account: this.account
                }
            })
        },
        tzDhjh() {
            this.$router.push({
                path: '/dialog/dhjh',
                query: {
                    account: this.account
                }
            })
        },
        tzFkjc() {
            this.$router.push({
                path: '/dialog/fkjc',
                query: {
                    account: this.account
                }
            })
        },
        tzZtsz() {
            this.$router.push({
                path: '/dialog/ztsz',
                query: {
                    account: this.account
                }
            })
        },
        tzAqsz() {
            this.$router.push({
                path: '/dialog/aqsz',
                query: {
                    account: this.account
                }
            })
        },
        tzQjsz() {
            this.$router.push({
                path: '/dialog/qjsz',
                query: {
                    account: this.account
                }
            })
        },
        tzDzydy() {
            this.$router.push({
                path: '/dialog/dzydy/info',
                query: {
                    account: this.account
                }
            })
        },

    },

}

</script>

<style scoped="scoped">
.Breadcrumb {
    background-color: #545c64;
    color: #fff;
}

.left_menu {
    width: 100%;
    /* display: inline; */
    /* z-index: 1000; */
}

a {
    text-decoration: none;
}

.router-link-active {
    text-decoration: none;
}

.el-page-header__content {
    color: white !important;
    font-size: 14px !important;
}
</style>
