<template>
  <el-container>
    <el-header>
      <HeaderTop />
    </el-header>
    <el-container>
      <el-aside width="200px">
        <LeftMenu />
      </el-aside>
      <el-container>
        <el-main>
          <setDhnr />
        </el-main>
        <el-footer>
          <Footer />
        </el-footer>
      </el-container>
    </el-container>
    <router-view /><!-- 路由出口 -->
  </el-container>
</template>
<script>
  import HeaderTop from '@/components/Top.vue'
  import LeftMenu from '@/components/LeftMenu.vue'
  import setDhnr from '@/components/set_dhnr.vue'
  import Footer from '@/components/Footer.vue'
  // 从缓存获取user
  //const cache_user = sessionStorage.getItem('user')
  //定义和注册组件
  export default {
    name: 'set1',
    // 注册组件
    components: {
      LeftMenu,
      HeaderTop,
      setDhnr,
      Footer,
    },
    beforeRouteLeave(to, from, next) {
      const answer = window.confirm('请确认当前页面是否保存,未保存请点击取消')
      if (answer) {
        next()
      } else {
        next(false)
      }
    }
  }

</script>
<style scoped="scoped">


</style>
