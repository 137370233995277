<template>
  <el-container>
    <el-header>
      <HeaderTop />
    </el-header>
    <el-container>
      <el-aside width="200px">
        <LeftMenu />
      </el-aside>
      <el-container>
        <el-main>
          <h1 class="tipx">请及时更改用户初始密码!</h1>
          <!-- <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item title="庆祝升话宝2.0成功上线，答谢新老客户，特推出以下活动" name="1" style="color: red;">
              <div>四大亮点——重新定义升话宝 </div>
              <div>五项服务秒响应——正规合作，售后无忧</div>
              <div>六大功能升级——破茧成蝶，全新蜕变。</div>
            </el-collapse-item>
            <el-collapse-item title="活动1、【限时2折】" name="2">
              <div>定价1000元的升话宝2.0，3月份合作价格，仅需200元。 </div>
              <div>客户可以按年合作，购买期限不限。</div>
              <div>可按1年，2年，3年…… ——4月恢复原价。</div>
            </el-collapse-item>
            <el-collapse-item title="活动2、【限量免费】" name="3">
              <div>推荐一个精准客户，送您一个月正式会员，再送他三个月正式会员，多推多送您。</div>
              <div>推荐者和被推荐者，同时享受免费合作体验。每客户仅12个名额。</div>
              <div>被推荐者再推荐新的精准客户，本活动同样有效。</div>
            </el-collapse-item>
            <el-collapse-item title="活动3、【限时免费】" name="4">
              <div>推荐一个成交客户，成交一个月，送您一个月，多成多送您。成交一年，送您一年。</div>
              <div>您不需要花一分钱，即可正式使用升话宝2.0全部开放功能。</div>
              <div>活动3，截止四月底</div>
            </el-collapse-item>
          </el-collapse> -->
          <center style="max-width: 800px;">
            <!--<el-button type="primary" @click='yhxx'>用户信息</el-button>
            <el-button type="primary" @click='cssz'>参数设置</el-button>-->
          </center>
          <el-table :data="tableData" border :header-cell-style="{'text-align':'center'}">
            <el-table-column label="目前关键词带入功能已支持情况" algin="center">
              <el-table-column prop="liulanqi" label="浏览器"></el-table-column>
              <el-table-column prop="zhichi" label="sm.cn"></el-table-column>
              <el-table-column prop="zhichi" label="sogou.com"></el-table-column>
              <el-table-column prop="zhichi" label="so.com"></el-table-column>
              <el-table-column prop="sm" label="baidu.com">×</el-table-column>
              <el-table-column prop="zhichi" label="默认搜索框"></el-table-column>
            </el-table-column>
          </el-table>





        </el-main>
        <el-footer>
          <Footer />
        </el-footer>
      </el-container>
    </el-container>
    <router-view />
    <!-- 路由出口 -->
  </el-container>
</template>
<script scoped>
  import HeaderTop from '@/components/Top.vue'
  import LeftMenu from '@/components/LeftMenu0.vue'
  import Footer from '@/components/Footer.vue'
  export default {
    name: 'user_index',
    // 注册组件
    components: {
      LeftMenu,
      HeaderTop,
      Footer
    },
    // data() 为变量赋值等

    data() {
      return {
        activeNames: ['1'],
        mystl: {
          lineHeight: '50px'
        },
        tableData: [{
          liulanqi: '神马',
          zhichi: '√',

        }, {
          liulanqi: '搜狗',
          zhichi: '√',

        }, {
          liulanqi: '360',
          zhichi: '×',

        }, {
          liulanqi: '百度',
          zhichi: '√',
          sm: '×',
          sogou: '×',
          so: '×',
          baidu: '×',

        }, {
          liulanqi: '小米',
          zhichi: '√',

        }, {
          liulanqi: '苹果',
          zhichi: '-',

        }]
      }
    },
    // created 在模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图
    created() {
      // 从缓存获取user
      const child_user = sessionStorage.getItem('user')
      let user_obj = JSON.parse(child_user)
      this.account = user_obj.account
    },
    // mounted在模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作
    methods: {
      handleChange(val) {
        console.log(val);
      },
      yhxx() {
        this.showbt = this.dhnr
        this.$router.push({
          path: '/user/list?account=account:account',
          query: {
            account: this.account
          }
        })
      },
      cssz() {
        this.showbt = this.dhnr
        this.$router.push({
          path: 'dialog/qjsz?account=account:account',
          query: {
            account: this.account
          }
        })
      }
    }
  }

</script>

<style lang="less" scoped="scoped">
  body {
    padding: 0px;
    margin: 0px;
    font-size: 18px;
  }

  /*主框体样式*/

  .el-collapse {
    margin: 5px;
    max-width: 800px;
  }

  .el-table {
    margin: 5px;
    max-width: 800px;
  }

  .tipx {
    height: 30px;
    line-height: 30px;
    font-family: "微软雅黑";
    font-weight: 100;
    margin: 2px;
    padding: 0px;
    color: #E6A23C;
  }


  /*大框体样式*/
  .el-main {
    .Breadcrumb {
      height: 50px;
      line-height: 50px;
      background-color: #E9EEF3;
    }

    .el-page-header {
      padding-top: 12px;
      padding-left: 15px;
    }

    height: calc(100vh - 120px);
  }

</style>
