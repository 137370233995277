<template>
  <div id="app">
    <div v-title data-title="升话宝2.0_宿州市博硕软件科技有限公司"></div>
    <!-- 路由出口 -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="less">
    .all_Action_Btn{
        background:#409EFF ;
        border-style: groove;
        border-radius:5px;
        padding:5px 10px;
        margin:3px 5px;
        border:0px;
        color:white;
        outline:none;
        cursor: pointer;
    }
   .all_Action_Btn:hover{
       background:#5aaafa ;
   }
</style>
