<template>
    <div class="dhnr">

        <div>是否开启商务通对话开场白：

            <el-switch style="padding:4px" v-model="is_open_kcb" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            <div style="border:1px solid rgb(230, 230, 230); margin:5px 0; padding:5px;background-color:#fbfbfb;">
                <el-button @click="change_bold(1)">加粗</el-button>
                <el-button @click="change_red(1)">红色</el-button>
                <el-button @click="change_img(1)">图片</el-button>
                <el-button @click="change_view(1)" id="btn_bj1" style="color:#409EFF;border-color:#409EFF">正在编辑<i class="el-icon-edit"></i></el-button>
                <el-input v-show="kcb_code" id="1_input" :autosize="{ minRows: 9}" class="bj_input" type="textarea" v-model="conf.begin_sentence" show-word-limit style="min-height:200px;"></el-input>
                <div v-show="kcb_view" style="border:1px solid rgb(230, 230, 230); margin:5px 0; padding:5px;height:200px;">
                    <span v-html="conf.begin_sentence"></span>
                </div>
            </div>

        </div>
        <div class="class2">
            <el-tooltip placement="right">
                <div slot="content">为空则不开启</div>
                <el-input placeholder="默认“在线咨询正在和您进行对话..”" v-model="conf.begin_sentence2">
                    <template slot="prepend">商务通对话开始语：</template>
                </el-input>
            </el-tooltip>
        </div>

        <div class="class2">
            <div class="class2_1">悬浮电话和微信</div>
            <el-tooltip placement="right">
                <div slot="content">悬浮电话和微信</div>
                <div class="class2_2">
                    <!-- <el-switch v-model="is_show_dhbtn" active-color="#13ce66" inactive-color="#ff4949"></el-switch> -->
                    <el-select v-model="is_show_dhbtn" placeholder="引导语" style="width: 70%;">
                        <el-option key="000" label="不显示" value="0"></el-option>
                        <el-option key="111" label="悬浮电话" value="1"></el-option>
                        <el-option key="222" label="悬浮微信" value="2"></el-option>
                        <el-option key="333" label="悬浮电话+微信" value="3"></el-option>
                    </el-select>
                </div>
            </el-tooltip>
        </div>

        <div class="class2">
            <div class="class2_1">阻止页面后退</div>
            <el-tooltip placement="right">
                <div slot="content">返回时前5次不会退出</div>
                <div class="class2_2">
                    <el-switch v-model="is_bp" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                </div>
            </el-tooltip>
        </div>

        <div class="class2">
            <div class="class2_1">发送搜索词到商务通</div>
            <el-tooltip placement="right">
                <div slot="content">是否将访客搜索的【关键词】带入到商务通中客服，若个别情况获取不到关键词，则把默认关键词带入到商务通</div>
                <div class="class2_2">
                    <el-switch v-model="is_send_keyword" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                </div>
            </el-tooltip>
        </div>
        <div class="class2">
            <div class="class2_1">显示引导语</div>
            <el-tooltip placement="right">
                <div slot="content">可以设置6句引导语并设置是否显示。</div>
                <div class="class2_2">
                    <el-switch v-model="is_show_auto_reply" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                </div>
            </el-tooltip>
        </div>
        <!-- <div class="class2">
            <div class="class2_1">开启页内对话</div>
            <el-tooltip placement="right">
                <div slot="content">开启后不会跳转商务通,在当前页面对话</div>
                <div class="class2_2">
                    <el-switch v-model="is_open_yndh" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                </div>
            </el-tooltip>
        </div> -->

        <!-- <div class="class2">
            <div class="class2_1">自动跳转到商务通</div>
            <el-tooltip placement="right">
                <div slot="content">开启后,预设升话宝停留时间结束后自动跳转到商务通</div>
                <div class="class2_2">
                    <el-switch v-model="is_auto_tz" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                </div>
            </el-tooltip>
        </div> -->

        <div class="class2">
            <div class="class2_1">电话/微信按钮发送消息</div>
            <el-tooltip placement="right">
                <div slot="content">开启后,电话/微信按钮发送 电话/微信聊吧</div>
                <div class="class2_2">
                    <el-switch v-model="is_autosend_buttonmsg" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                </div>
            </el-tooltip>
        </div>

        <div class="class2">
            <div class="class2_1">开启定制引导语</div>
            <el-tooltip placement="right">
                <div slot="content">开启后,会根据搜索词自动匹配预设的引导语</div>
                <div class="class2_2">
                    <el-switch v-model="is_open_dzydy" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                </div>
            </el-tooltip>
        </div>

        <div class="class2">
            <div class="class2_1">屏蔽商务通引导语</div>
            <el-tooltip placement="right">
                <div slot="content"> 访客开口前屏蔽商务通引导语 </div>
                <div class="class2_2">
                    <el-switch v-model="is_pb_swtydy" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                </div>
            </el-tooltip>
        </div>

        <div class="class2">
            <div class="class2_1">用户回复自动中断引导语</div>
            <el-tooltip placement="right">
                <div slot="content">当用户发送第一句话的时候设置的引导语不继续发送</div>
                <div class="class2_2">
                    <el-select v-model="conf.is_break_auto_reply" placeholder="引导语" style="width: 70%;">
                        <el-option v-for="item in options_level" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </el-tooltip>
        </div>

        <div class="class2">
            <el-tooltip placement="right">
                <div slot="content">落地页停留时间</div>
                <el-input placeholder="停留几秒就填几”" v-model="conf.next_enter_time">
                    <template slot="prepend">落地页停留时间:</template>
                </el-input>
            </el-tooltip>
        </div>

        <div class="class2" style="padding-left: 15px;">
            上次修改时间：{{conf.remark}}
        </div>

        <el-tabs type=" border-card">
            <el-tab-pane label="第1句">

                <div style="border:1px solid rgb(230, 230, 230); margin:5px 0; padding:5px;background-color:#fbfbfb;">
                    <el-button @click="change_bold(2)">加粗</el-button>
                    <el-button @click="change_red(2)">红色</el-button>
                    <el-button @click="change_img(2)">图片</el-button>
                    <el-button @click="change_view(2)" id="btn_bj2" style="color:#409EFF;border-color:#409EFF">正在编辑<i class="el-icon-edit"></i></el-button>
                    <el-input v-show="kcb_code" id="2_input" :autosize="{ minRows: 9}" class="bj_input" type="textarea" v-model="conf.auto_reply_first" maxlength="256" show-word-limit style="height:200px;"></el-input>
                    <div v-show="kcb_view" style="border:1px solid rgb(230, 230, 230); margin:5px 0; padding:5px;height:200px;">
                        <span v-html="conf.auto_reply_first"></span>
                    </div>
                </div>

                <div class="dan_box">
                    <div class="box_left">
                        <div class="class_dan">
                            <el-tag id="b1" :key="tag" v-for="tag in dynamicTags1" closable :disable-transitions="false" @close="handleClose(tag,1)">
                                {{tag}}
                            </el-tag>
                            <el-input placeholder="按回车结束" class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm(1)" @blur="handleInputConfirm(1)">
                            </el-input>
                            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加文本按钮</el-button>
                        </div>
                        <div class="save">
                            <el-switch v-model="value1_1" active-text="复制微信" @change="upd1(11)"> </el-switch>
                            <el-switch v-model="value1_2" active-text="拨打电话" @change="upd1(12)"> </el-switch>
                            <el-button style="padding:10px 20px;margin:0 20px;" type="primary" @click="addbr(1)" icon="el-icon-edit">换行</el-button>
                            <el-switch v-model="is_send_first" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                            时间间隔：<el-input-number v-model="conf.first_sj" controls-position="right" @change="handleChange" :min="0" :max="1200" label="描述文字"></el-input-number>
                        </div>
                    </div>
                    <div class="box_right">

                    </div>
                </div>
            </el-tab-pane>

            <el-tab-pane label="第2句">

                <div style="border:1px solid rgb(230, 230, 230); margin:5px 0; padding:5px;background-color:#fbfbfb;">
                    <el-button @click="change_bold(3)">加粗</el-button>
                    <el-button @click="change_red(3)">红色</el-button>
                    <el-button @click="change_img(3)">图片</el-button>
                    <el-button @click="change_view(3)" id="btn_bj3" style="color:#409EFF;border-color:#409EFF">正在编辑<i class="el-icon-edit"></i></el-button>
                    <el-input v-show="kcb_code" id="3_input" :autosize="{ minRows: 9}" class="bj_input" type="textarea" v-model="conf.auto_reply_second" maxlength="256" show-word-limit style="min-height:200px;"></el-input>
                    <div v-show="kcb_view" style="border:1px solid rgb(230, 230, 230); margin:5px 0; padding:5px;min-height:200px;">
                        <span v-html="conf.auto_reply_second"></span>
                    </div>
                </div>

                <div class="class_dan">
                    <el-tag :key="tag" v-for="tag in dynamicTags2" closable :disable-transitions="false" @close="handleClose(tag,2)">
                        {{tag}}
                    </el-tag>
                    <el-input placeholder="按回车结束" class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm(2)" @blur="handleInputConfirm(2)">
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加选项</el-button>
                </div>
                <div class="save">
                    <el-switch v-model="value2_1" active-text="复制微信" @change="upd1(21)"> </el-switch>
                    <el-switch v-model="value2_2" active-text="拨打电话" @change="upd1(22)"> </el-switch>
                    <el-button style="padding:10px 20px;margin:0 20px;" type="primary" @click="addbr(2)" icon="el-icon-edit">换行</el-button>
                    <el-switch v-model="is_send_second" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                    时间间隔：<el-input-number v-model="conf.second_sj" controls-position="right" @change="handleChange" :min="0" :max="1200" label="描述文字"></el-input-number>
                </div>
            </el-tab-pane>
            <el-tab-pane label="第3句">

                <div style="border:1px solid rgb(230, 230, 230); margin:5px 0; padding:5px;background-color:#fbfbfb;">
                    <el-button @click="change_bold(4)">加粗</el-button>
                    <el-button @click="change_red(4)">红色</el-button>
                    <el-button @click="change_img(4)">图片</el-button>
                    <el-button @click="change_view(4)" id="btn_bj4" style="color:#409EFF;border-color:#409EFF">正在编辑<i class="el-icon-edit"></i></el-button>
                    <el-input v-show="kcb_code" id="4_input" :autosize="{ minRows: 9}" class="bj_input" type="textarea" v-model="conf.auto_reply_third" maxlength="256" show-word-limit style="min-height:200px;"></el-input>
                    <div v-show="kcb_view" style="border:1px solid rgb(230, 230, 230); margin:5px 0; padding:5px;min-height:200px;">
                        <span v-html="conf.auto_reply_third"></span>
                    </div>
                </div>

                <!-- <editor id="editor_id4" height="150px" width="auto" :content.sync="conf.auto_reply_third" :afterChange="afterChange()" :loadStyleMode="false" @on-content-change="onContentChange"></editor> -->

                <div class="class_dan">
                    <el-tag :key="tag" v-for="tag in dynamicTags3" closable :disable-transitions="false" @close="handleClose(tag,3)">
                        {{tag}}
                    </el-tag>
                    <el-input placeholder="按回车结束" class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm(3)" @blur="handleInputConfirm(3)">
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加选项</el-button>
                </div>
                <div class="save">
                    <el-switch v-model="value3_1" active-text="复制微信" @change="upd1(31)"> </el-switch>
                    <el-switch v-model="value3_2" active-text="拨打电话" @change="upd1(32)"> </el-switch>
                    <el-button style="padding:10px 20px;margin:0 20px;" type="primary" @click="addbr(3)" icon="el-icon-edit">换行</el-button>
                    <el-switch v-model="is_send_third" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                    时间间隔：<el-input-number v-model="conf.third_sj" controls-position="right" @change="handleChange" :min="0" :max="1200" label="描述文字"></el-input-number>
                </div>
            </el-tab-pane>
            <el-tab-pane label="第4句">

                <div style="border:1px solid rgb(230, 230, 230); margin:5px 0; padding:5px;background-color:#fbfbfb;">
                    <el-button @click="change_bold(5)">加粗</el-button>
                    <el-button @click="change_red(5)">红色</el-button>
                    <el-button @click="change_img(5)">图片</el-button>
                    <el-button @click="change_view(5)" id="btn_bj5" style="color:#409EFF;border-color:#409EFF">正在编辑<i class="el-icon-edit"></i></el-button>
                    <el-input v-show="kcb_code" id="5_input" :autosize="{ minRows: 9}" class="bj_input" type="textarea" v-model="conf.auto_reply_fourth" maxlength="256" show-word-limit style="min-height:200px;"></el-input>
                    <div v-show="kcb_view" style="border:1px solid rgb(230, 230, 230); margin:5px 0; padding:5px;min-height:200px;">
                        <span v-html="conf.auto_reply_fourth"></span>
                    </div>
                </div>

                <div class="class_dan">
                    <el-tag :key="tag" v-for="tag in dynamicTags4" closable :disable-transitions="false" @close="handleClose(tag,4)">
                        {{tag}}
                    </el-tag>
                    <el-input placeholder="按回车结束" class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm(4)" @blur="handleInputConfirm(4)">
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加选项</el-button>
                </div>
                <div class="save">
                    <el-switch v-model="value4_1" active-text="复制微信" @change="upd1(41)"> </el-switch>
                    <el-switch v-model="value4_2" active-text="拨打电话" @change="upd1(42)"> </el-switch>
                    <el-button style="padding:10px 20px;margin:0 20px;" type="primary" @click="addbr(4)" icon="el-icon-edit">换行</el-button>
                    <el-switch v-model="is_send_fourth" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                    时间间隔：<el-input-number v-model="conf.fourth_sj" controls-position="right" @change="handleChange" :min="0" :max="1200" label="描述文字"></el-input-number>
                </div>
            </el-tab-pane>

            <el-tab-pane label="第5句">

                <div style="border:1px solid rgb(230, 230, 230); margin:5px 0; padding:5px;background-color:#fbfbfb;">
                    <el-button @click="change_bold(6)">加粗</el-button>
                    <el-button @click="change_red(6)">红色</el-button>
                    <el-button @click="change_img(6)">图片</el-button>
                    <el-button @click="change_view(6)" id="btn_bj6" style="color:#409EFF;border-color:#409EFF">正在编辑<i class="el-icon-edit"></i></el-button>
                    <el-input v-show="kcb_code" id="6_input" :autosize="{ minRows: 9}" class="bj_input" type="textarea" v-model="conf.auto_reply_five" maxlength="256" show-word-limit style="min-height:200px;"></el-input>
                    <div v-show="kcb_view" style="border:1px solid rgb(230, 230, 230); margin:5px 0; padding:5px;min-height:200px;">
                        <span v-html="conf.auto_reply_five"></span>
                    </div>
                </div>

                <!-- <editor id="editor_id6" height="150px" width="auto" :content.sync="conf.auto_reply_five" :afterChange="afterChange()" :loadStyleMode="false" @on-content-change="onContentChange"></editor> -->

                <div class="class_dan">
                    <el-tag :key="tag" v-for="tag in dynamicTags5" closable :disable-transitions="false" @close="handleClose(tag,5)">
                        {{tag}}
                    </el-tag>
                    <el-input placeholder="按回车结束" class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm(5)" @blur="handleInputConfirm(5)">
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加选项</el-button>
                </div>
                <div class="save">
                    <el-switch v-model="value5_1" active-text="复制微信" @change="upd1(51)"> </el-switch>
                    <el-switch v-model="value5_2" active-text="拨打电话" @change="upd1(52)"> </el-switch>
                    <el-button style="padding:10px 20px;margin:0 20px;" type="primary" @click="addbr(5)" icon="el-icon-edit">换行</el-button>
                    <el-switch v-model="is_send_five" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                    时间间隔：<el-input-number v-model="conf.five_sj" controls-position="right" @change="handleChange" :min="0" :max="1200" label="描述文字"></el-input-number>
                </div>
            </el-tab-pane>

            <el-tab-pane label="第6句">

                <div style="border:1px solid rgb(230, 230, 230); margin:5px 0; padding:5px;background-color:#fbfbfb;">
                    <el-button @click="change_bold(7)">加粗</el-button>
                    <el-button @click="change_red(7)">红色</el-button>
                    <el-button @click="change_img(7)">图片</el-button>
                    <el-button @click="change_view(7)" id="btn_bj7" style="color:#409EFF;border-color:#409EFF">正在编辑<i class="el-icon-edit"></i></el-button>
                    <el-input v-show="kcb_code" id="7_input" :autosize="{ minRows: 9}" class="bj_input" type="textarea" v-model="conf.auto_reply_six" maxlength="256" show-word-limit style="min-height:200px;"></el-input>
                    <div v-show="kcb_view" style="border:1px solid rgb(230, 230, 230); margin:5px 0; padding:5px;min-height:200px;">
                        <span v-html="conf.auto_reply_six"></span>
                    </div>
                </div>

                <!-- <editor id="editor_id7" height="150px" width="auto" :content.sync="conf.auto_reply_six" :afterChange="afterChange()" :loadStyleMode="false" @on-content-change="onContentChange"></editor> -->

                <div class="class_dan">
                    <el-tag :key="tag" v-for="tag in dynamicTags6" closable :disable-transitions="false" @close="handleClose(tag,6)">
                        {{tag}}
                    </el-tag>
                    <el-input placeholder="按回车结束" class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm(6)" @blur="handleInputConfirm(6)">
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加选项</el-button>
                </div>
                <div class="save">
                    <el-switch v-model="value6_1" active-text="复制微信" @change="upd1(61)"> </el-switch>
                    <el-switch v-model="value6_2" active-text="拨打电话" @change="upd1(62)"> </el-switch>
                    <el-button style="padding:10px 20px;margin:0 20px;" type="primary" @click="addbr(6)" icon="el-icon-edit">换行</el-button>
                    <el-switch v-model="is_send_six" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                    时间间隔：<el-input-number v-model="conf.six_sj" controls-position="right" @change="handleChange" :min="0" :max="1200" label="描述文字"></el-input-number>
                </div>
            </el-tab-pane>

        </el-tabs>
        <div class="save">
            <el-button type="primary" @click=onSubmit>保存</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DHNR',
    data() {
        return {
            is_open_dzydy: false,
            is_pb_swtydy: false,
            is_autosend_buttonmsg: true,
            kcb_code: true,
            kcb_view: false,
            is_show_dhbtn: 0,
            is_bp: true,
            add_img_url_show: false,
            selectTxt: '',
            add_img_url: '',
            is_open_yndh: true,
            is_auto_tz: '',
            value1_1: false,
            value1_2: false,
            value2_1: false,
            value2_2: false,
            value3_1: false,
            value3_2: false,
            value4_1: false,
            value4_2: false,
            value5_1: false,
            value5_2: false,
            value6_1: false,
            value6_2: false,
            dynamicTags1: [],
            dynamicTags2: [],
            dynamicTags3: [],
            dynamicTags4: [],
            dynamicTags5: [],
            dynamicTags6: [],
            inputVisible: false,
            inputValue: '',
            is_use_item: false,
            is_send_first: true,
            is_send_second: true,
            is_send_third: true,
            is_send_fourth: true,
            is_send_five: true,
            is_send_six: true,
            is_send_push: [],
            is_auto_reply: true,
            is_send_keyword: true,
            is_open_kcb: true,
            value1: '',
            value2: '',
            value3: 'false',
            value4: 'false',
            value5: '',
            is_show_auto_reply: true,
            first_send_keyword: true,
            options_level: [{
                value: 0,
                label: '不中断引导语'
            }, {
                value: 1,
                label: '用户回复时中断引导语'
            }, {
                value: 2,
                label: '客服回复时中断引导语'
            }, {
                value: 3,
                label: '用户或客服回复时中断引导语'
            },],
            conf: {},
            // 富文本编辑框
            // editorText: '直接初始化值', // 双向同步的变量
            editorTextCopy: '' // content-change 事件回掉改变的对象
        }
    },
    created() {
        // 获取链接参数
        const rec_account = this.$route.query.account
        console.log('--------------------', rec_account)
        this.conf.account = rec_account
        this.getCueConf()
        window.as1 = this.as1

    },

    mounted() {

        let that = this
        this.selectStr('1_input')
        this.selectStr('2_input')
        this.selectStr('3_input')
        this.selectStr('4_input')
        this.selectStr('5_input')
        this.selectStr('6_input')
        this.selectStr('7_input')
    },
    methods: {
        selectStr(tag) {
            let that = this
            document.getElementById(tag).onmouseup = function () {
                if (document.selection) {
                    that.selectTxt = document.selection.createRange().text;
                } else {
                    that.selectTxt = window.getSelection().toString();
                }
            }
        },
        change_view(num) {
            let a = 'btn_bj' + num
            if (this.kcb_code == true) {
                this.kcb_code = false
                this.kcb_view = true
                document.getElementById(a).style.color = '#606266'
                document.getElementById(a).style.borderColor = '#DCDFE6'
                document.getElementById(a).innerHTML = '编辑<i class="el-icon-edit">'
            } else {
                this.kcb_code = true
                this.kcb_view = false
                document.getElementById(a).style.color = '#409EFF'
                document.getElementById(a).style.borderColor = '#409EFF'
                document.getElementById(a).innerHTML = '正在编辑<i class="el-icon-edit">'
            }
        },
        change_bold(num) {
            if (this.selectTxt != '') {
                switch (num) {
                    case 1: // 开场白
                        this.conf.begin_sentence = this.conf.begin_sentence.replace(this.selectTxt, '<b>' + this.selectTxt + '</b>')
                        break;
                    case 2: // 引导语第1句
                        this.conf.auto_reply_first = this.conf.auto_reply_first.replace(this.selectTxt, '<b>' + this.selectTxt + '</b>')
                        break;
                    case 3: // 引导语第2句
                        this.conf.auto_reply_second = this.conf.auto_reply_second.replace(this.selectTxt, '<b>' + this.selectTxt + '</b>')

                        break;
                    case 4: // 引导语第3句
                        this.conf.auto_reply_third = this.conf.auto_reply_third.replace(this.selectTxt, '<b>' + this.selectTxt + '</b>')

                        break;
                    case 5: // 引导语第4句
                        this.conf.auto_reply_fourth = this.conf.auto_reply_fourth.replace(this.selectTxt, '<b>' + this.selectTxt + '</b>')

                        break;
                    case 6: // 引导语第5句
                        this.conf.auto_reply_five = this.conf.auto_reply_five.replace(this.selectTxt, '<b>' + this.selectTxt + '</b>')

                        break;
                    case 7: // 引导语第6句
                        this.conf.auto_reply_six = this.conf.auto_reply_six.replace(this.selectTxt, '<b>' + this.selectTxt + '</b>')
                        break;
                }
            }
        },
        change_red(num) {
            if (this.selectTxt != '') {
                switch (num) {
                    case 1: // 开场白
                        this.conf.begin_sentence = this.conf.begin_sentence.replace(this.selectTxt, '<span style="color:red">' + this.selectTxt + '</span>')
                        break;
                    case 2: // 引导语第1句
                        this.conf.auto_reply_first = this.conf.auto_reply_first.replace(this.selectTxt, '<span style="color:red">' + this.selectTxt + '</span>')
                        break;
                    case 3: // 引导语第2句
                        this.conf.auto_reply_second = this.conf.auto_reply_second.replace(this.selectTxt, '<span style="color:red">' + this.selectTxt + '</span>')

                        break;
                    case 4: // 引导语第3句
                        this.conf.auto_reply_third = this.conf.auto_reply_third.replace(this.selectTxt, '<span style="color:red">' + this.selectTxt + '</span>')

                        break;
                    case 5: // 引导语第4句
                        this.conf.auto_reply_fourth = this.conf.auto_reply_fourth.replace(this.selectTxt, '<span style="color:red">' + this.selectTxt + '</span>')

                        break;
                    case 6: // 引导语第5句
                        this.conf.auto_reply_five = this.conf.auto_reply_five.replace(this.selectTxt, '<span style="color:red">' + this.selectTxt + '</span>')

                        break;
                    case 7: // 引导语第6句
                        this.conf.auto_reply_six = this.conf.auto_reply_six.replace(this.selectTxt, '<span style="color:red">' + this.selectTxt + '</span>')
                        break;
                }
            }
        },
        change_img(num) {
            let that = this
            this.$prompt('请填写图片地址', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({
                value
            }) => {
                if (value != '' && value != undefined) {
                    let public_code = '<img style="width:100%" src="' + value + '" alt=".">'
                    switch (num) {
                        case 1: // 开场白
                            this.conf.begin_sentence += public_code
                            break;
                        case 2: // 引导语第1句
                            this.conf.auto_reply_first += public_code
                            break;
                        case 3: // 引导语第2句
                            this.conf.auto_reply_second += public_code

                            break;
                        case 4: // 引导语第3句
                            this.conf.auto_reply_third += public_code

                            break;
                        case 5: // 引导语第4句
                            this.conf.auto_reply_fourth += public_code

                            break;
                        case 6: // 引导语第5句
                            this.conf.auto_reply_five += public_code

                            break;
                        case 7: // 引导语第6句
                            this.conf.auto_reply_six += public_code
                            break;
                    }
                }

                this.$message({
                    type: 'success',
                    message: '插入图片成功: ' + value
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });

            this.add_img_url_show = true
            if (this.add_img_url != '') {
                this.conf.begin_sentence += '<img src="' + this.add_img_url + '">'
            }
        },
        //换行按钮
        addbr(a1) {
            if (a1 == 1) this.conf.auto_reply_first += "<br />";
            else if (a1 == 2) this.conf.auto_reply_second += "<br />";
            else if (a1 == 3) this.conf.auto_reply_third += "<br />";
            else if (a1 == 4) this.conf.auto_reply_fourth += "<br />";
            else if (a1 == 5) this.conf.auto_reply_five += "<br />";
            else if (a1 == 6) this.conf.auto_reply_six += "<br />";
        },
        // 添加按钮功能
        upd1(a2) {
            let btm_cpwx = '<button class="all_Action_Btn" onclick = "cpWx()">复制微信</button>'
            let btn_bdphone = '<button class="all_Action_Btn" onclick = "bdPhone()">拨打电话</button>'
            switch (a2) {
                case 11:
                    if (this.value1_1) this.conf.auto_reply_first += btm_cpwx;
                    else this.conf.auto_reply_first = this.conf.auto_reply_first.replace(btm_cpwx, " ");
                    break;
                case 12:
                    if (this.value1_2) this.conf.auto_reply_first += btn_bdphone;
                    else this.conf.auto_reply_first = this.conf.auto_reply_first.replace(btn_bdphone, " ");
                    break;
                case 21:
                    if (this.value2_1) this.conf.auto_reply_second += btm_cpwx;
                    else this.conf.auto_reply_second = this.conf.auto_reply_second.replace(btm_cpwx, " ");
                    break;
                case 22:
                    if (this.value2_2) this.conf.auto_reply_second += btn_bdphone;
                    else this.conf.auto_reply_second = this.conf.auto_reply_second.replace(btn_bdphone, " ");
                    break;
                case 31:
                    if (this.value3_1) this.conf.auto_reply_third += btm_cpwx;
                    else this.conf.auto_reply_third = this.conf.auto_reply_third.replace(btm_cpwx, " ");
                    break;
                case 32:
                    if (this.value3_2) this.conf.auto_reply_third += btn_bdphone;
                    else this.conf.auto_reply_third = this.conf.auto_reply_third.replace(btn_bdphone, " ");
                    break;
                case 41:
                    if (this.value4_1) this.conf.auto_reply_fourth += btm_cpwx;
                    else this.conf.auto_reply_fourth = this.conf.auto_reply_fourth.replace(btm_cpwx, " ");
                    break;
                case 42:
                    if (this.value4_2) this.conf.auto_reply_fourth += btn_bdphone;
                    else this.conf.auto_reply_fourth = this.conf.auto_reply_fourth.replace(btn_bdphone, " ");
                    break;
                case 51:
                    if (this.value5_1) this.conf.auto_reply_five += btm_cpwx;
                    else this.conf.auto_reply_five = this.conf.auto_reply_five.replace(btm_cpwx, " ");
                    break;
                case 52:
                    if (this.value5_2) this.conf.auto_reply_five += btn_bdphone;
                    else this.conf.auto_reply_five = this.conf.auto_reply_five.replace(btn_bdphone, " ");
                    break;
                case 61:
                    if (this.value6_1) this.conf.auto_reply_six += btm_cpwx;
                    else this.conf.auto_reply_six = this.conf.auto_reply_six.replace(btm_cpwx, " ");
                    break;
                case 62:
                    if (this.value6_2) this.conf.auto_reply_six += btn_bdphone;
                    else this.conf.auto_reply_six = this.conf.auto_reply_six.replace(btn_bdphone, " ");
                    break;
            }
        },
        handleClose(tag, a3) {
            let btn_txt = '<button class="all_Action_Btn" onclick = "convs(this.innerText)">'
            if (a3 == 1) {
                this.dynamicTags1.splice(this.dynamicTags1.indexOf(tag), 1);
                this.conf.auto_reply_first = this.conf.auto_reply_first.replace(btn_txt + tag + '</button>', " ");
            } else if (a3 == 2) {
                this.dynamicTags2.splice(this.dynamicTags2.indexOf(tag), 1);
                this.conf.auto_reply_second = this.conf.auto_reply_second.replace(btn_txt + tag + '</button>', " ");
            } else if (a3 == 3) {
                this.dynamicTags3.splice(this.dynamicTags3.indexOf(tag), 1);
                this.conf.auto_reply_third = this.conf.auto_reply_third.replace(btn_txt + tag + '</button>', " ");
            } else if (a3 == 4) {
                this.dynamicTags4.splice(this.dynamicTags4.indexOf(tag), 1);
                this.conf.auto_reply_fourth = this.conf.auto_reply_fourth.replace(btn_txt + tag + '</button>', " ");
            } else if (a3 == 5) {
                this.dynamicTags5.splice(this.dynamicTags5.indexOf(tag), 1);
                this.conf.auto_reply_five = this.conf.auto_reply_five.replace(btn_txt + tag + '</button>', " ");
            } else if (a3 == 6) {
                this.dynamicTags6.splice(this.dynamicTags6.indexOf(tag), 1);
                this.conf.auto_reply_six = this.conf.auto_reply_six.replace(btn_txt + tag + '</button>', " ");
            }
        },
        showInput() {
            this.inputVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        handleInputConfirm(a4) {
            let inputValue = this.inputValue;
            let btn_move = '<button class="all_Action_Btn" onclick = "convs(this.innerText)">';
            if (inputValue) {
                if (a4 == 1) {
                    this.dynamicTags1.push(inputValue);
                    this.conf.auto_reply_first += btn_move + inputValue + '</button>'
                } else if (a4 == 2) {
                    this.dynamicTags2.push(inputValue);
                    this.conf.auto_reply_second += btn_move + inputValue + '</button>'
                } else if (a4 == 3) {
                    this.dynamicTags3.push(inputValue);
                    this.conf.auto_reply_third += btn_move + inputValue + '</button>'
                } else if (a4 == 4) {
                    this.dynamicTags4.push(inputValue);
                    this.conf.auto_reply_fourth += btn_move + inputValue + '</button>'
                } else if (a4 == 5) {
                    this.dynamicTags5.push(inputValue);
                    this.conf.auto_reply_five += btn_move + inputValue + '</button>'
                } else if (a4 == 6) {
                    this.dynamicTags6.push(inputValue);
                    this.conf.auto_reply_six += btn_move + inputValue + '</button>'
                }
            }
            this.inputVisible = false;
            this.inputValue = '';
        },
        //全选复制
        qxfz() {
            var Input = document.getElementById('webcode');
            Input.focus()
            Input.select()
            try {
                document.execCommand('copy');
                this.$message('复制成功，您可以将该段代码直接粘贴到引导语内的指定位置（代码视图下），或请客服辅助。');
            } catch (e) { }
        },
        //再全选
        zqx() {
            console.log("aaa")
        },
        //全选复制
        qxfz1() {
            var Input1 = document.getElementById('webcode1');
            Input1.focus()
            Input1.select()
            try {
                document.execCommand('copy');
                this.$message('复制成功，您可以将该段代码直接粘贴到引导语内的指定位置（代码视图下），或请客服辅助。');
            } catch (e) { }
        },
        //再全选
        zqx1() {
            console.log("aaa")
        },
        //全选复制
        qxfz2() {
            var Input2 = document.getElementById('webcode2');
            Input2.focus()
            Input2.select()
            try {
                document.execCommand('copy');
                this.$message('复制成功，您可以将该段代码直接粘贴到引导语内的指定位置（代码视图下），或请客服辅助。');
            } catch (e) { }
        },
        //再全选
        zqx2() {
            console.log("aaa")
        },
        onContentChange(val) {
            this.editorTextCopy = val;
            window.console.log(this.editorTextCopy)
        },
        //全选复制
        qxfz3() {
            var Input3 = document.getElementById('webcode3');
            Input3.focus()
            Input3.select()
            try {
                document.execCommand('copy');
                this.$message('复制成功，您可以将该段代码直接粘贴到引导语内的指定位置（代码视图下），或请客服辅助。');
            } catch (e) { }
        },
        //再全选
        zqx3() {
            console.log("aaa")
        },
        afterChange() { },

        onSubmit() {
            // 保存升话宝设置
            this.conf.remark = new Date().toLocaleString('chinese', {
                hour12: false
            })
            const req = this.conf

            if (this.is_send_first == true) {
                this.is_send_push.push(req.first_sj)
            }
            if (this.is_send_second == true) {
                this.is_send_push.push(req.second_sj)
            }
            if (this.is_send_third == true) {
                this.is_send_push.push(req.third_sj)
            }
            if (this.is_send_fourth == true) {
                this.is_send_push.push(req.fourth_sj)
            }
            if (this.is_send_five == true) {
                this.is_send_push.push(req.five_sj)
            }
            if (this.is_send_six == true) {
                this.is_send_push.push(req.six_sj)
            }
            req.first_enter_time = Math.max.apply(null, this.is_send_push)

            if (this.is_auto_tz == true) {
                req.is_auto_tz = 1
            } else {
                req.is_auto_tz = 0
            }



            if (this.is_autosend_buttonmsg == true) {
                req.is_autosend_buttonmsg = 1
            } else {
                req.is_autosend_buttonmsg = 0
            }

            if (this.is_open_dzydy == true) {
                req.is_open_dzydy = 1
            } else {
                req.is_open_dzydy = 0
            }

            if (this.is_pb_swtydy == true) {
                req.is_pb_swtydy = 1
            } else {
                req.is_pb_swtydy = 0
            }

            if (this.is_open_yndh == true) {
                req.is_open_yndh = 1
            } else {
                req.is_open_yndh = 0
            }

            if (this.is_auto_reply == true) {
                req.is_auto_reply = 1
            } else {
                req.is_auto_reply = 0
            }

            req.next_enter_time = Number(req.next_enter_time)
            // console.log(" req: ", req)

            if (this.is_send_keyword == true) {
                req.is_send_keyword = 1
            } else {
                req.is_send_keyword = 0
            }

            if (this.is_show_auto_reply == true) {
                req.is_show_auto_reply = 1
            } else {
                req.is_show_auto_reply = 0
            }

            if (this.first_send_keyword == true) {
                req.first_send_keyword = 1
            } else {
                req.first_send_keyword = 0
            }

            if (this.is_open_kcb == true) {
                req.is_open_kcb = 1
            } else {
                req.is_open_kcb = 0
            }
            if (this.is_send_first == true) {
                req.is_send_first = 1
            } else {
                req.is_send_first = 0
            }
            if (this.is_send_second == true) {
                req.is_send_second = 1
            } else {
                req.is_send_second = 0
            }
            if (this.is_send_third == true) {
                req.is_send_third = 1
            } else {
                req.is_send_third = 0
            }
            if (this.is_send_fourth == true) {
                req.is_send_fourth = 1
            } else {
                req.is_send_fourth = 0
            }

            req.is_send_five = 0
            if (this.is_send_five == true) {
                req.is_send_five = 1
            }

            req.is_send_six = 0
            if (this.is_send_six == true) {
                req.is_send_six = 1
            }

            if (this.is_use_item == true) {
                req.is_use_item = 1
            } else {
                req.is_use_item = 0
            }

            req.is_show_dhbtn = Number(this.is_show_dhbtn)

            if (this.is_bp == true) {
                req.is_bp = 1
            } else {
                req.is_bp = 0
            }

            req.is_pb_swtydy = Number(req.is_pb_swtydy)
            let that = this;
            this.$axios.post('/apis/conf/shb/setting', req).then(function (res) {
                if (res.data.Code == "200") {
                    // 跳转到后退设置
                    that.$router.push({
                        path: '/dialog/fkjc',
                        query: {
                            account: that.conf.account,
                        }
                    })
                } else {
                    alert(res.data.Msg)
                }
            })
        },

        getCueConf() {
            // 获取用户设置
            const req = {
                account: this.conf.account
            }
            const that = this
            this.$axios.post('/apis/conf/shb', req).then(function (res) {
                console.log("返回值打印-----2222", res)
                const re_date = res.data.Data
                if (res.data.Code == '200') {
                    that.conf = re_date
                    if (re_date.is_open_yndh == 0) {
                        that.is_open_yndh = false
                    } else {
                        that.is_open_yndh = true
                    }

                    if (re_date.is_auto_reply == 0) {
                        that.is_auto_reply = false
                    } else {
                        that.is_auto_reply = true
                    }
                    if (re_date.is_send_keyword == 0) {
                        that.is_send_keyword = false
                    } else {
                        that.is_send_keyword = true
                    }


                    if (re_date.is_show_auto_reply == 0) {
                        that.is_show_auto_reply = false
                    } else {
                        that.is_show_auto_reply = true
                    }

                    if (re_date.is_auto_tz == 0) {
                        that.is_auto_tz = false
                    } else {
                        that.is_auto_tz = true
                    }


                    if (re_date.is_autosend_buttonmsg == 0) {
                        that.is_autosend_buttonmsg = false
                    } else {
                        that.is_autosend_buttonmsg = true
                    }

                    if (re_date.is_open_dzydy == 0) {
                        that.is_open_dzydy = false
                    } else {
                        that.is_open_dzydy = true
                    }

                    if (re_date.is_pb_swtydy == 0) {
                        that.is_pb_swtydy = false
                    } else {
                        that.is_pb_swtydy = true
                    }

                    if (re_date.first_send_keyword == 0) {
                        that.first_send_keyword = false
                    } else {
                        that.first_send_keyword = true
                    }
                    if (re_date.is_open_kcb == 0) {
                        that.is_open_kcb = false
                    } else {
                        that.is_open_kcb = true
                    }
                    if (re_date.is_send_first == 0) {
                        that.is_send_first = false
                    } else {
                        that.is_send_first = true
                    }
                    if (re_date.is_send_second == 0) {
                        that.is_send_second = false
                    } else {
                        that.is_send_second = true
                    }
                    if (re_date.is_send_third == 0) {
                        that.is_send_third = false
                    } else {
                        that.is_send_third = true
                    }
                    if (re_date.is_send_fourth == 0) {
                        that.is_send_fourth = false
                    } else {
                        that.is_send_fourth = true
                    }

                    that.is_send_five = true
                    if (re_date.is_send_five == 0) {
                        that.is_send_five = false
                    }
                    that.is_send_six = true
                    if (re_date.is_send_six == 0) {
                        that.is_send_six = false
                    }

                    if (re_date.is_use_item == 0) {
                        that.is_use_item = false
                    } else {
                        that.is_use_item = true
                    }

                    that.is_show_dhbtn = re_date.is_show_dhbtn

                    if (re_date.is_bp == 0) {
                        that.is_bp = false
                    } else {
                        that.is_bp = true
                    }

                }
            })
        },
        handleChange(value) { }
    }
}

</script>

<style scoped="scoped">
.el-tabs {
    width: 100%;
    padding: 1px;
}

.set_main {
    display: flex;
    flex-direction: row;
    margin: 10px;
}

.setright {
    float: left;
    width: 100%;
}

.dhnr {
    max-width: 800px;
}

.class2 {
    height: 40px;
    color: #767676;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.class2_1 {
    background-color: #fbfbfb;
    border: 1px solid #e6e6e6;
    height: 40px;
    line-height: 40px;
    width: 26%;
    padding: 0px 10px;
    text-align: center;
}

.class2_2 {
    border: 1px solid #e6e6e6;
    height: 40px;
    line-height: 40px;
    width: 74%;
    padding: 0px 10px;
    text-align: left;
    border-left: 0px;
}

.class3_1 {
    background-color: #fbfbfb;
    border: 0px;
    height: 40px;
    line-height: 40px;

    padding: 0px 10px;
    text-align: center;
}

.class_dan {
    margin-top: 10px;
}

.save {
    margin: 10px;
}

.el-switch {
    margin-left: 10px;
}

.el-tag + .el-tag {
    margin-left: 10px;
}

.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.button-new-tag:hover {
    background: #409eff;
    color: #fff;
}

.input-new-tag {
    width: auto;
    margin-left: 10px;
    vertical-align: bottom;
}

.kcb_input {
    min-height: 200px;
}
</style>
