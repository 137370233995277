const toolsRouters = [{
    path: '/tools/upload/photo',
    name: 'UploadPhoto',
    component: () => import('@/views/tools/UploadPhoto.vue')
},
{
    path: '/tools/baike/catch',
    name: 'CatchBaike',
    component: () => import('@/views/tools/CatchBaike.vue')
},
{
    path: '/tools/upload/sha256',
    name: 'CatchBaike',
    component: () => import('@/views/tools/Sha256Encode.vue')
}
]

export default toolsRouters
