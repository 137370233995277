     <template>
    <div id="user_list">
        <el-container>
            <el-header>
                <Top />
            </el-header>
            <el-container>
                <el-aside width="200px">
                    <LeftMenu />
                </el-aside>
                <el-container>
                    <el-main>
                        <!-- <keep-alive> -->
                        <div class="list_content">
                            <!-- 查询条件 -->
                            <el-form :inline="true" :model="formInline" class="demo-form-inline" v-if="salesShow">
                                <el-form-item label="">
                                    <el-input style="width: 100px;" v-model="formInline.user_name" placeholder="用户名"></el-input>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-select v-model="formInline.superior_id" placeholder="上级单位" style="width: 120px;">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="宿州玖泰" value="宿州玖泰"></el-option>
                                        <el-option label="宿州寻牛" value="宿州寻牛"></el-option>
                                        <el-option label="宿州天一" value="宿州天一"></el-option>
                                        <el-option label="宿州云脉" value="宿州云脉"></el-option>
                                        <el-option label="博硕科技" value="博硕科技"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-select v-model="formInline.sales" placeholder="销售员" style="width: 120px;">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="邓帅帅" value="邓帅帅"></el-option>
                                        <el-option label="杜青春" value="杜青春"></el-option>
                                        <el-option label="曾寒寒" value="曾寒寒"></el-option>
                                        <el-option label="代小帅" value="代小帅"></el-option>
                                        <el-option label="田涛" value="田涛"></el-option>
                                        <el-option label="朱珠" value="朱珠"></el-option>
                                        <el-option label="尹宇惠" value="尹宇惠"></el-option>
                                        <el-option label="陈翔宇" value="陈翔宇"></el-option>
                                        <el-option label="钟雪" value="钟雪"></el-option>
                                        <el-option label="张旭" value="张旭"></el-option>
                                        <el-option label="王骋" value="王骋"></el-option>
                                        <el-option label="刘庆" value="刘庆"></el-option>
                                        <el-option label="曹田田" value="曹田田"></el-option>
                                        <el-option label="尤大成" value="尤大成"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-select v-model="formInline.level" placeholder="级别" style="width: 120px;">
                                        <el-option label="全部" value=0></el-option>
                                        <el-option label="超级管理员" value=1></el-option>
                                        <el-option label="代理商" value=2></el-option>
                                        <el-option label="正式会员" value=4></el-option>
                                        <el-option label="普通用户" value=3></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-select v-model="formInline.is_use" placeholder="状态" style="width: 80px;">
                                        <!-- <el-option label="全部" value=3></el-option> -->
                                        <el-option label="启用" value=1></el-option>
                                        <el-option label="禁用" value=0></el-option>
                                    </el-select>
                                </el-form-item>

                                <el-form-item label="">
                                    <el-input v-model="formInline.company_name" placeholder="公司名称"></el-input>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-input v-model="formInline.phone" placeholder="联系方式" style="width: 120px;"></el-input>
                                </el-form-item>
                                <el-form-item label="">
                                    <div class="block">
                                        <el-date-picker v-model="formInline.validity_time" type="datetimerange" align="right" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:01', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" style="width: 360px;">
                                        </el-date-picker>
                                    </div>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="cx_sunbmit">查询</el-button>
                                </el-form-item>
                            </el-form>
                            <!-- 列表内容 -->
                            <el-table :data="tableData" :cell-style="{padding:'2px 0'}" height="634" border stripe>
                                <el-table-column prop="account" label="登陆账号" align="center" width="150">
                                    <template slot-scope="scope">
                                        <el-button v-if="scope.row.dialog_type=='商务通'" @click="handleClick(scope.row)" type="text" size="small" style="color:#007aff">{{scope.row.account}}</el-button>
                                        <el-button v-else-if="scope.row.dialog_type=='快商通'" @click="handleClick(scope.row)" type="text" size="small" style="color:#2cd2cc">{{scope.row.account}}</el-button>
                                        <el-button v-else-if="scope.row.dialog_type=='易聊'" @click="handleClick(scope.row)" type="text" size="small" style="color:#915df3">{{scope.row.account}}</el-button>
                                        <el-button v-else @click="handleClick(scope.row)" type="text" size="small">{{scope.row.account}}</el-button>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="dialog_v" label="参数设置" width="100" align="center">
                                    <template slot-scope="scope">
                                        <el-button v-if="scope.row.dialog_type=='商务通'" @click="handleShb(scope.row)" type="text" size="small" style="color:#007aff">{{scope.row.dialog_v}}</el-button>
                                        <el-button v-else-if="scope.row.dialog_type=='快商通'" @click="handleShb(scope.row)" type="text" size="small" style="color:#2cd2cc">{{scope.row.dialog_v}}</el-button>
                                        <el-button v-else-if="scope.row.dialog_type=='易聊'" @click="handleShb(scope.row)" type="text" size="small" style="color:#915df3">{{scope.row.dialog_v}}</el-button>
                                        <el-button v-else @click="handleShb(scope.row)" type="text" size="small">{{scope.row.dialog_v}}</el-button>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="user_name" label="用户姓名" width="150" align="center">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.is_use!=1" style="color:red">{{scope.row.user_name}}</span>
                                        <span v-else-if="(scope.row.level==4)==true&&scope.row.bool_time==false" style="color:orange">{{scope.row.user_name}}</span>
                                        <span v-else-if="scope.row.level==4" style="color:#37B328">{{scope.row.user_name}}</span>
                                        <span v-else>{{scope.row.user_name}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="sales" label="销售员" v-if="salesShow" width="80" align="center"></el-table-column>
                                <el-table-column prop="principal" label="负责人" v-if="salesShow" width="80" align="center"></el-table-column>
                                <el-table-column prop="superior_id" label="上级单位" v-if="salesShow" width="120" align="center"></el-table-column>
                                <el-table-column prop="login_num" label="登录次数" width="80" align="center"></el-table-column>
                                <el-table-column label="流量" width="60" align="center" v-if="show_liul=='1'">
                                    <template slot-scope="scope">
                                        <el-button @click="handleLiuL(scope.row)" type="text" size="small">查看</el-button>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="company_name" width="120" show-overflow-tooltip label="公司名称">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.dialog_type=='商务通'">
                                            <a style="color:#007aff" target="_blank" :href="scope.row.tg_zd">{{scope.row.company_name}}</a>
                                        </span>
                                        <span v-else-if="scope.row.dialog_type=='快商通'"><a style="color:#2cd2cc" target="_blank" :href="scope.row.tg_zd">{{scope.row.company_name}}</a></span>
                                        <span v-else-if="scope.row.dialog_type=='易聊'"><a style="color:#915df3" target="_blank" :href="scope.row.tg_zd">{{scope.row.company_name}}</a></span>
                                        <span v-else>{{scope.row.company_name}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="create_time" label="注册日期" width="110" align="center"></el-table-column>
                                <el-table-column prop="validity_time" label="有效期" width="110" align="center"></el-table-column>
                                <!--<el-table-column prop="phone" label="联系方式" width="150"></el-table-column>-->
                                <el-table-column prop="is_use" label="状态" width="100" align="center">
                                    <template slot-scope="scope">
                                        <el-button type="" v-if="scope.row.is_use==1" style="color:#37B328" @click="transIsUse(scope.row,0)">启用</el-button>
                                        <el-button type="" v-else style="color: red" @click="transIsUse(scope.row,1)">禁用</el-button>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="login_time" label="最后登录" width="170" align="center"></el-table-column>
                                <el-table-column prop="level" label="级别" width="100" align="center">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.level==1" style="color:red">超级管理员</span>
                                        <span v-else-if="scope.row.level==2" style="color:#37B328">代理商</span>
                                        <span v-else-if="scope.row.level==4" style="color:#37B328">正式会员</span>
                                        <span v-else>普通用户</span>
                                    </template>
                                </el-table-column>
                                <el-table-column show-overflow-tooltip prop="remark" label="备注" align="center"></el-table-column>
                            </el-table>
                            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current_page" :page-sizes="[15,20,25]" :page-size="page_size" layout="total, sizes, prev, pager, next, jumper" :total="data_num" :sizes='page_num'>
                            </el-pagination>
                        </div>
                        <!-- 路由出口 -->
                        <router-view />
                    </el-main>
                    <el-footer>
                        <Footer />
                    </el-footer>
                </el-container>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import LeftMenu from '@/components/LeftMenu0.vue'
import Top from '@/components/Top.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'UserList',
    components: {
        LeftMenu,
        Top,
        Footer
    },
    data() {
        return {
            formInline: {
                man_account: '',
                user_name: '',
                level: '',
                sales: '',
                superior_id: '',
                company_name: '',
                validity_time: [],
                validity_time_start: '',
                validity_time_finish: '',
                phone: '',
                is_use: '',
                current_page: 1,
                page_size: 15, // 每页的数据

            },
            tableData: [],
            current_page: 1, //初始页
            page_size: 15, // 每页的数据
            data_num: 0, // 总条数
            page_num: 0, // 总页数
            userList: [],
            show_liul: '',
            salesShow: false,
        }
    },
    methods: {
        cx_sunbmit() {
            this.current_page = 1
            this.onSubmit()
        },
        onSubmit() {
            console.log('开始查询!');
            // 从缓存获取user
            const cache_user = sessionStorage.getItem('user')
            const cache_user_obj = JSON.parse(cache_user)
            this.formInline.man_account = cache_user_obj.account
            if (typeof this.formInline.validity_time != "undefined") {
                this.formInline.validity_time_start = this.formInline.validity_time[0]
                this.formInline.validity_time_finish = this.formInline.validity_time[1]
            }

            this.formInline.current_page = this.current_page
            this.formInline.page_size = this.page_size
            const that = this
            this.$axios.post('/apis/user/list', that.formInline)
                .then(function (res) {
                    if (res.data.Code === '200') {
                        console.log(res);
                        const res_list = res.data.Data.List
                        const res_data = res.data.Data
                        that.tableData = res_list
                        that.page_num = res_data.TotalPage
                        that.data_num = res_data.TotalCount
                        let myDate = new Date();
                        let myDate_date = myDate.toLocaleDateString().split('/')
                        for (let i = 0; i < that.tableData.length; i++) {
                            if ((that.tableData[i].validity_time.slice(0, 4) - myDate_date[0]) * 365 + (that.tableData[i].validity_time.slice(5, 7) - myDate_date[1]) * 30 + (that.tableData[i].validity_time.slice(8, 10) - myDate_date[2]) >= 7) {
                                that.tableData[i].bool_time = true
                            } else if ((that.tableData[i].validity_time.slice(0, 4) - myDate_date[0]) * 365 + (that.tableData[i].validity_time.slice(5, 7) - myDate_date[1]) * 30 + (that.tableData[i].validity_time.slice(8, 10) - myDate_date[2]) <= 0) {
                                that.tableData[i].bool_time = null
                            } else {
                                that.tableData[i].bool_time = false
                            }
                            that.tableData[i].validity_time = that.tableData[i].validity_time.split(" ", [1])
                            that.tableData[i].create_time = that.tableData[i].create_time.split(" ", [1])
                            if (that.tableData[i].login_ip == '') {
                                that.tableData[i].login_ip = '暂未登录'
                            }
                        }
                    }
                })

            sessionStorage.setItem('cache_user_page', JSON.stringify(this.formInline))
        },
        // 初始页current_page、初始每页数据数page_size和数据data
        handleSizeChange: function (size) {
            this.page_size = size;
            console.log(this.page_size) //每页下拉显示数据
            this.onSubmit()
        },
        handleCurrentChange: function (current_page) {
            this.current_page = current_page;
            console.log(this.current_page) //点击第几页
            this.onSubmit()
        },

        handleLiuL: function (u) {
            this.$router.push({
                path: '/user/liul',
                query: {
                    account: u.account
                }
            })
        },

        handleClick: function (u) {
            // 加入缓存
            this.$router.push({
                name: 'set_child',
                params: {
                    account: u.account
                }
            })
        },

        handleShb: function (u) {
            // 加入缓存
            sessionStorage.setItem('sec_user', JSON.stringify(u))
            this.$router.push({
                path: '/dialog/qjsz',
                query: {
                    account: u.account
                }
            })
        },

        transIsUse: function (row, isUse) {
            if (confirm('确定修改吗?')) {
                let that = this;
                // 从缓存获取user
                const manUserStr = sessionStorage.getItem('user')
                if (!manUserStr || manUserStr == "") {
                    alert("操作失败,请重新登录后再试 !")
                    return
                }
                const manUser = JSON.parse(manUserStr)
                let req = {
                    "man_account": manUser.account
                };
                if (typeof row.account != "undefined" && row.account != "") {
                    req.account = row.account;
                    req.is_use = isUse
                    this.$axios.post('/apis/user/transIsUse', req).then(function (res) {
                        if (res.data.Code === '200') {
                            let xgIndex = that.tableData.indexOf(row);
                            if (xgIndex != -1) {
                                that.tableData[xgIndex].is_use = isUse;
                            }
                            alert("修改成功!");
                        } else {
                            alert(res.data.Msg)
                        }
                    })
                } else {
                    alert("为获取到账号!")
                }
            }
        }
    },

    created() {
        console.log('开始查询!')
        // 从缓存获取user
        const cache_user = sessionStorage.getItem('user')
        const cache_user_obj = JSON.parse(cache_user)
        if (cache_user_obj.level == 1 || cache_user_obj.Id == 6 || cache_user_obj.Id == 64 || cache_user_obj.Id == 65) {
            this.show_liul = '1'
        }
        if (cache_user_obj.level == 1 || cache_user_obj.level == 2) {
            this.salesShow = true
        }
        let req_date = {
            'man_account': cache_user_obj.account,
        }
        let cacheUserPageStr = sessionStorage.getItem('cache_user_page')
        if (cacheUserPageStr != "" && cacheUserPageStr != null) {
            let req_date2 = JSON.parse(cacheUserPageStr);
            if (req_date2.man_account == req_date.man_account) {
                this.formInline = req_date2;
                req_date = req_date2;
            }
        }

        const that = this
        this.$axios.post('/apis/user/list', req_date)
            .then(function (res) {
                console.log("res ---------------", res)
                if (res.data.Code === '200') {
                    const res_list = res.data.Data.List
                    const res_data = res.data.Data
                    console.log("res_list ---------------", res_list)
                    that.tableData = res_list
                    that.data_num = res_data.TotalCount
                    that.page_num = res_data.TotalPage

                    let myDate = new Date();
                    let myDate_date = myDate.toLocaleDateString().split('/')


                    for (let i = 0; i < that.tableData.length; i++) {
                        if ((that.tableData[i].validity_time.slice(0, 4) - myDate_date[0]) * 365 + (that.tableData[i].validity_time.slice(5, 7) - myDate_date[1]) * 30 + (that.tableData[i].validity_time.slice(8, 10) - myDate_date[2]) >= 7)
                            that.tableData[i].bool_time = true
                        else if ((that.tableData[i].validity_time.slice(0, 4) - myDate_date[0]) * 365 + (that.tableData[i].validity_time.slice(5, 7) - myDate_date[1]) * 30 + (that.tableData[i].validity_time.slice(8, 10) - myDate_date[2]) <= 0)
                            that.tableData[i].bool_time = null
                        else
                            that.tableData[i].bool_time = false

                        that.tableData[i].validity_time = that.tableData[i].validity_time.split(" ", [1])
                        that.tableData[i].create_time = that.tableData[i].create_time.split(" ", [1])
                        if (that.tableData[i].login_ip == '') {
                            that.tableData[i].login_ip = '暂未登录'
                        }
                    }
                }
            })
    },
}

</script>

<style lang="less" scoped="scoped">
body {
    margin: 0px;
    padding: 0px;
}

.el-main {
    height: calc(100vh - 120px);
}

.list_content {
    text-align: center;
    width: 100%;
}

.el-table-column {
    line-height: 20px;
}

.el-table__row .hover-row {
    background-color: red !important;
}

a {
    text-decoration: none;
}
</style>
