<template>
	 <el-container>
    <el-header>
      <HeaderTop />
    </el-header>
    <el-container>
      <el-aside width="200px">
        <LeftMenu />
      </el-aside>
      <el-container>
        <el-main>         
       <h1>多个着陆页安装方法</h1>
 		<div class="info">
 			首先创建一个多页面共同调用的js文件，如head.js，然后把下方的代码直接复制到这个head.js中即可。
 		</div>
  
        <el-input v-model="input3" placeholder="请输入内容"></el-input>
        <img src="../../assets/img/install_more1.jpg">
        </el-main>
        <el-footer>
          <Footer />
        </el-footer>
      </el-container>
    </el-container>
    <router-view /><!-- 路由出口 -->
  </el-container>
</template>
<script>
	import HeaderTop from '@/components/Top.vue'
 	 import LeftMenu from '@/components/LeftMenu.vue' 
 	 import Footer from '@/components/Footer.vue'
   export default {
   	data() {
    return {
    input3: ''
    
    }
  },
    // 注册组件
    components: {
      LeftMenu,
      HeaderTop,
      
      Footer,
    },
   }
</script>

<style scoped="scoped">
	.el-input{max-width: 800px;margin: 10px 0px;}
</style>