<template>
    <div class="login_container">
        <el-form class="login_form" ref="form" :model="user">
            <el-form-item>
                <h2 class="login_tit">升话宝5.0</h2>
                <h5>网络主管必备神器</h5>
                <el-input prefix-icon="iconfont icon-yonghuming" v-model="user.account" placeholder="请输入用户名"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input prefix-icon="iconfont icon-key-fill" type="password" v-model="user.password" placeholder="请输入密码" show-password></el-input>
            </el-form-item>
            <el-form-item>
                <el-button class="login_button" type="primary" @click="onSubmit">登录</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: 'login',
    // 注册组件
    components: {},
    // data() 为变量赋值等
    data() {
        return {
            user: {
                account: '',
                password: '',
                login_ip: '',
            }
        }
    },
    created() { },

    methods: {
        onSubmit() {
            console.log('submit!');
            const that = this
            that.user.account = that.user.account.trim()
            that.user.password = that.user.password.trim()
            this.$axios.post('/apis/login/check', that.user)
                .then(function (res) {
                    console.log(res.data.Code)
                    if (res.data.Code === '200') {
                        // 加入缓存
                        sessionStorage.setItem('user', JSON.stringify(res.data.Data))
                        console.log("开始跳转到主页 ... ")
                        that.$router.push({
                            path: '/user',
                            query: {
                                account: that.account
                            }
                        })
                    } else {
                        alert(res.data.Msg)
                    }
                })
        }
    }
}

</script>
<style scoped>
h2 {
    margin: 0px;
    padding: 0px;
    height: 40px;
    line-height: 40px;
    color: #606266;
}

h5 {
    margin: 0px;
    padding: 0px;
    text-align: right;
    font-weight: 100;
    color: #909399;
    height: 20px;
    line-height: 20px;
}

.login_container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('//static.dreamsun.net/static/img/2021-11-20/js_bkg.jpg')
        no-repeat;
    background-size: cover;
}

.login_form {
    background-color: white;
    padding: 50px;
    padding-top: 10px;
    min-width: 300px;
}

.login_button {
    width: 100%;
}

.login_tit {
    text-align: center;
}
</style>
