// 入口文件
import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 加载全局样式
// import './styles/index.less'
// 引入element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import timeUtil from './tools/public.js'
import VueClipboard from 'vue-clipboard2'

axios.defaults.headers['Content-Type'] = 'application/json'
Vue.prototype.$axios = axios
Vue.prototype.$timeUtil = timeUtil
Vue.config.productionTip = false
// 注册全局组件库
Vue.use(ElementUI);
Vue.use(VueClipboard)
// 注册全局title
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

// 把router配置到根实例中
// render方法把app组件渲染到 public下 #app 节点
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
