<template>

    <div class="set_ztsz">

        <div class="ztsz_all">

            <div class="class2">

                <div class="class2_1" style="width:120px">使用专题形式</div>

                <el-tooltip placement="right">

                    <div slot="content">开启后使用专题形式</div>

                    <div class="class2_2">

                        <el-switch v-model="is_use_item" active-color="#13ce66" inactive-color="#ff4949"></el-switch>

                    </div>

                </el-tooltip>

            </div>

            <el-tooltip class="item" effect="dark" content="简约版对应位置序号1" placement="right">

                <el-input placeholder="请输入内容" v-model="conf.item_n_n" id="item_n_n">

                    <template slot="prepend">名称标题(例如:公司名称,医院名称)</template>

                </el-input>

            </el-tooltip>

            <el-tooltip class="item" effect="dark" content="简约版对应位置序号2" placement="right">

                <el-input placeholder="请输入内容" v-model="conf.item_name">

                    <template slot="prepend">具体名称</template>

                </el-input>

            </el-tooltip>

            <el-tooltip class="item" effect="dark" content="简约版对应位置序号3" placement="right">

                <el-input placeholder="请输入内容" v-model="conf.item_t_n">

                    <template slot="prepend">性质标题</template>

                </el-input>

            </el-tooltip>

            <div style="margin-top:20px;">

                <div class="class2_1">具体性质</div>

                <el-tooltip class="item" effect="dark" content="简约版对应位置序号4" placement="right">

                    <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" resize="none" placeholder="请输入内容" v-model="conf.item_type" style="width:550px;float:left">

                    </el-input>

                </el-tooltip>

            </div>

            <el-tooltip class="item" effect="dark" content="简约版对应位置序号5" placement="right">

                <el-input placeholder="请输入内容" v-model="conf.item_d_n">

                    <template slot="prepend">简介标题</template>

                </el-input>

            </el-tooltip>

            <div style="margin-top:20px;">

                <div class="class2_1">简介内容</div>

                <el-tooltip class="item" effect="dark" content="简约版对应位置序号6" placement="right">

                    <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" resize="none" placeholder="请输入内容" v-model="conf.item_describe" style="width:550px;float:left">

                    </el-input>

                </el-tooltip>

            </div>

            <el-tooltip class="item" effect="dark" content="简约版对应位置序号7" placement="right">

                <el-input placeholder="请输入内容" v-model="conf.item_c_n">

                    <template slot="prepend">特色标题</template>

                </el-input>

            </el-tooltip>

            <div style="margin-top:20px;">

                <div class="class2_1">项目特色</div>

                <el-tooltip class="item" effect="dark" content="简约版对应位置序号8" placement="right">

                    <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" resize="none" placeholder="请输入内容" v-model="conf.item_chara" style="width:550px;float:left">

                    </el-input>

                </el-tooltip>

            </div>

            <el-input placeholder="简约版将作为视频封面" v-model="conf.company_p1_n">

                <template slot="prepend">视频封面</template>

            </el-input>

            <el-tooltip class="item" effect="dark" content="简约版将作为视频封面 建议宽高比例：1.78,建议尺寸800*450 " placement="right">

                <el-input placeholder="专题外景图 宽高比为 4:5 推荐 120px * 150px" v-model="conf.company_pic1">

                    <template slot="prepend">企业外景图</template>

                </el-input>

            </el-tooltip>

            <el-input placeholder="请输入内容" v-model="conf.company_p2_n">

                <template slot="prepend">环境图标题</template>

            </el-input>

            <el-tooltip class="item" effect="dark" content="建议宽高比例：1.78,建议尺寸800*450" placement="right">

                <el-input placeholder="专题环境图  宽高比为 5:2  推荐 375px * 150px" v-model="conf.company_pic2">

                    <template slot="prepend">企业环境图</template>

                </el-input>

            </el-tooltip>

            <el-input placeholder="请输入内容" v-model="conf.kf_video">

                <template slot="prepend">简约版视频</template>

            </el-input>

            <!-- <el-tooltip class="item" effect="dark" content="建议尺寸370X220  如，宽：370px   高：220px" placement="right">

                <el-input placeholder="每个轮播图之间用(bssplit)分隔,例:http://www.abc.com/a.png(bssplit)http://www.abc.com/b.png" v-model="conf.kf_lb_pics">

                    <template slot="prepend">简约版轮播图</template>

                </el-input>

            </el-tooltip> -->

            <div class="save">

                <el-button type="primary" @click='onSubmit'>保存</el-button>

            </div>

        </div>

        <div class="ztsewzt">

            <img src="../assets/img/ztszwzt.png">

        </div>

    </div>

</template>



<script>

export default {

    name: 'ZHSZ',

    data() {

        return {

            is_use_item: false,

            conf: {

                account: '',

                item_name: '',

                item_type: '',

                item_chara: '',

                item_describe: '',

                company_pic1: '',

                company_pic2: '',

                item_n_n: '',

                item_t_n: '',

                item_c_n: '',

                item_d_n: '',

                company_p1_n: '',

                company_p2_n: '',

            }

        }

    },

    created() {

        // 获取链接参数

        const rec_account = this.$route.query.account

        console.log('--------------------', rec_account)

        this.conf.account = rec_account

        this.getCueConf()

    },



    methods: {

        onSubmit() {

            // 保存升话宝设置

            const req = this.conf

            let that = this;

            if (that.is_use_item == true) {

                req.is_use_item = 1

            } else {

                req.is_use_item = 0

            }

            this.$axios.post('/apis/conf/shb/setting', req).then(function (res) {

                if (res.data.Code == "200") {

                    // // 跳转到安全设置

                    that.$router.push({

                        path: '/dialog/aqsz',

                        query: {

                            account: that.conf.account,

                        }

                    })

                } else {

                    alert(res.data.Msg)

                }

            })

        },



        getCueConf() {

            // 获取用户设置

            const req = {

                account: this.conf.account

            }

            const that = this

            this.$axios.post('/apis/conf/shb', req).then(function (res) {

                console.log("返回值打印:", res)

                const re_date = res.data.Data

                if (res.data.Code == '200') {

                    that.conf = re_date

                    if (re_date.is_use_item == 0) {

                        that.is_use_item = false

                    } else {

                        that.is_use_item = true

                    }

                }

            })

        }

    }

}



</script>



<style scoped="scoped">
.ztsz_all {
    display: flex;

    flex-direction: column;

    max-width: 800px;
}

.set_ztsz {
    display: flex;

    flex-direction: row;
}

.el-input {
    margin-top: 10px;

    min-width: 650px;
}

.save {
    margin: 10px;
}

/* 如何更改 input 拾色器的外观*/

/* 火狐 不兼容*/

/* ::-webkit-color-swatch-wrapper 设置拾色器的外层包括的边框带样式

	 * input[type="color"]::-webkit-color-swatch-wrapper {padding: 0;} 消除外边框带*/

.ckfg .input_color[type='color']::-webkit-color-swatch-wrapper {
    padding: 1px 0px 0px 1px;

    background-color: #a0a0a0;
}

/* ::-webkit-color-swatch  设置内层的边框(放置从拾色器选取的颜色)样式，颜色改变的地方

	 * input[type='color']::-webkit-color-swatch {border:0;} 去掉剩下的黑色边框 */

.ckfg .input_color[type='color']::-webkit-color-swatch {
    border: 0;
}

#item_block {
    position: fixed;

    height: 500px;

    width: 500px;

    right: 200px;
}

#item_n_n:hover #item_block {
    background-color: aqua;
}

.class2_1 {
    float: left;

    background-color: #f5f7fa;

    border: 1px solid #dcdfe6;

    border-radius: 4px;

    font-size: 14px;

    color: #909399;

    height: 38px;

    line-height: 41px;

    width: 78px;

    padding: 0px 10px;

    text-align: center;
}

.class2 {
    height: 40px;

    color: #767676;

    display: flex;

    flex-direction: row;

    margin-top: 10px;
}

.class2_2 {
    border: 1px solid #e6e6e6;

    height: 40px;

    line-height: 40px;

    width: 74%;

    padding: 0px 10px;

    text-align: left;

    border-left: 0px;
}

.ztsewzt {
    display: flex;

    align-items: center;

    max-width: 500px;

    padding-left: 10px;
}

.ztszwzt img {
    width: 100%;
}
</style>

