<template>
  <div class="header_top">
    <img class="topImg" :src="topImg" align="left" /> 欢迎：<span>{{account}}</span>
    您目前是<span>{{level}}</span>，有效期为<span>{{validity_time}}
    </span>!
  </div>
</template>
<script>
  export default {
    name: 'HeaderTop',
    data() {
      return {
        account: '',
        id: '',
        user_name: '',
        validity_time: '',
        topImg: require("../assets/img/logo.fw.png"),
      }
    },
    methods: {
      shoujichakan() {
        let obj = document.getElementsByClassName("el-aside")[0]
        if (obj.style.display != "none") {
          obj.style.display = "none"
        } else {
          obj.style.display = "block"
        }

      }
    },
    created() {
      let that = this
      // 从缓存获取user
      const child_user = sessionStorage.getItem('user')
      let user_obj = JSON.parse(child_user)
      this.account = user_obj.account
      this.Id = user_obj.Id
      this.user_name = user_obj.user_name
      let dateStr = user_obj.validity_time
      if (dateStr != '' && dateStr != undefined) {
        let from_year = dateStr.split('-')[0]
        if (Number(from_year) > 2120) {
          this.validity_time = '永久'
        } else {
          this.validity_time = user_obj.validity_time
        }
      }

      this.level = user_obj.level
      switch (this.level) {
        case 1:
          this.level = "超级管理员"
          break;
        case 2:
          this.level = "代理商"
          break;
        case 3:
          this.level = "普通用户"
          break;
        case 4:
          this.level = "正式会员"
          break;
      }

      if (user_obj.level == "1" || user_obj.level == "2") {
        that.topImg = user_obj.top_img
      }

      if (user_obj.level == "3" || user_obj.level == "4") {
        console.log("user_obj.level11111:", user_obj.level);
        this.$axios.post('/apis/user/byusername', {
            "user_name": user_obj.superior_id
          })
          .then(function (res) {
            console.log(res.data)
            if (res.data.Code === '200') {
              const result = res.data.Data
              console.log(result)
              if (result.top_img) {
                that.topImg = result.top_img
              }
            }
          })
      }

    }
  }

</script>

<style>
  body {
    margin: 0px;
    padding: 0px;
  }

  .header_top span {
    color: yellow;
    font-weight: bold;
  }

  .header_top {
    background-color: #0085E8 !important;
    color: rgba(255, 255, 255, 0.8);
  }

  .el-header {

    background-color: #0085E8 !important;
    color: #333;
    line-height: 60px;

  }

  .el-footer {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;

  }

  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
  }

  .el-main {
    /*background-color: #E9EEF3;*/
    color: #333;
  }

  body>.el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }

  .topImg {
    width: 201px;
    height: 58px;
  }

</style>
