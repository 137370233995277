<template>
  <div class="ckfg_all">
    <div class="ckfg">
      <div class="class2">
        <!-- <div class="class2_1">是否开启手机端对话框半屏展示</div>
        <el-tooltip placement="right">
          <div slot="content">打开之后可以看到半屏效果预览</div>
          <div class="class2_2">
            <el-switch v-model="is_bp_bool" @change="onchange" active-color="#13ce66" inactive-color="#ff4949">
            </el-switch>
          </div>
        </el-tooltip> -->
      </div>
      <div class="title">提示：商务通的迷你窗口一定要关闭</div>
      <div class="class">
        <div class="class3 br">
          <div class="class3_1">界面颜色</div>
          <div class="class3_2">
            <el-color-picker v-model="conf.bkg_color"></el-color-picker>
          </div>
        </div>
        <div class="class3">
          <div class="class3_1" style="border-left: 0px;">客人回复</div>
          <div class="class3_2">
            <el-color-picker v-model="conf.reply_color"></el-color-picker>
            <div style="padding-left: 15px; padding-right: 15px;">&nbsp;背景色</div>
            <el-color-picker v-model="conf.font_color"></el-color-picker>
            <div style="padding-left: 15px;">&nbsp;字体色</div>
          </div>
        </div>
      </div>
      <el-tabs :tab-position="tabPosition" v-model="activeName" @tab-click="handleClick" style="height: auto; border: 1px solid #e6e6e6; margin: 5px;padding: 10px;">

        <!-- <el-tab-pane name="经典版">
          <span slot="label">
            <el-radio v-model="conf.dialog_v" label="经典版">经典版2.0</el-radio>
          </span>
          <img src="../assets/img/1.jpg" alt="经典版" />
        </el-tab-pane>
        <el-tab-pane name="微信版">
          <span slot="label">
            <el-radio v-model="conf.dialog_v" label="微信版">微信版</el-radio>
          </span>
          <img src="../assets/img/2.jpg" alt="微信版" />
        </el-tab-pane>
        <el-tab-pane name="微信特制版">
          <span slot="label">
            <el-radio v-model="conf.dialog_v" label="微信特制版">微信特制版</el-radio>
          </span>
          <img src="../assets/img/3.jpg" alt="微信特制版" />
        </el-tab-pane>
        <el-tab-pane name="微信电话版">
          <span slot="label">
            <el-radio v-model="conf.dialog_v" label="微信电话版">微信电话版</el-radio>
          </span>
          <img src="../assets/img/4.jpg" alt="微信电话版" />
        </el-tab-pane>-->
        <el-tab-pane name="引导版">
          <span slot="label">
            <el-radio v-model="conf.dialog_v" label="引导版">引导版</el-radio>
          </span>
          <img src="../assets/img/5.jpg" alt="引导版" />
        </el-tab-pane>
        <el-tab-pane name="简约版">
          <span slot="label">
            <el-radio v-model="conf.dialog_v" label="简约版">视频介绍版</el-radio>
          </span>
          <img src="../assets/img/6.jpg" alt="简约版" />
        </el-tab-pane>
        <el-tab-pane name="页内对话版">
          <span slot="label">
            <el-radio v-model="conf.dialog_v" label="页内对话版">经典定制版</el-radio>
          </span>
          <img src="../assets/img/7.jpg" alt="页内对话版" />
        </el-tab-pane>
      </el-tabs>

      <el-button type="primary" @click="onSubmit">保存</el-button>
      <a :href="uploadTempHref">页内对话版(不用跳转版本)</a>
    </div>
    <div class="ckfg_tp" v-show="show_tp">
      <img src="../assets/img/bp.jpg" />
    </div>

  </div>
</template>
<script>
  export default {
    name: 'WindowStyle',
    data() {
      return {
        show_tp: false,
        radio: '1',
        activeName: '页内对话版',
        tabPosition: 'left',
        conf: {
          account: '',
          is_bp: 0,
          bkg_color: '#606266',
          reply_color: '#67C23A',
          font_color: '#606266',
          dialog_v: '页内对话版',
          dialog_v_i: 1,
        },
        is_bp_bool: false,
      };
    },
    created() {
      // 获取链接参数
      const rec_account = this.$route.query.account
      console.log('--------------------', rec_account)
      this.conf.account = rec_account
      this.uploadTempHref = "http://shb.boshuoit.com/apis/upload/temp?account=" + rec_account
      this.getCueConf()
    },

    methods: {
      onchange() {
        if (this.is_bp_bool == true) {

          this.show_tp = true
        } else {
          this.show_tp = false
        }
      },
      onSubmit() {
        // 保存升话宝设置
        const req = this.conf
        if (this.is_bp_bool) {
          req.is_bp = 1
        } else {
          req.is_bp = 0
        }
        switch (req.dialog_v) {
          case '经典版':
            req.dialog_v_i = 1
            break;
          case '微信版':
            req.dialog_v_i = 2
            break;
          case '微信特制版':
            req.dialog_v_i = 3
            break;
          case '微信电话版':
            req.dialog_v_i = 4
            break;
          case '引导版':
            req.dialog_v_i = 5
            break;
          case '简约版':
            req.dialog_v_i = 6
            break;
          case '页内对话版':
            req.dialog_v_i = 7
            break;
          default:
            req.dialog_v_i = 1
            break;
        }
        let that = this;
        this.$axios.post('/apis/conf/shb/setting', req).then(function (res) {
          if (res.data.Code == "200") {
            // 跳转到窗口风格
            that.$router.push({
              path: '/dialog/dhnr',
              query: {
                account: that.conf.account,
              }
            })
          } else {
            alert(res.data.Msg)
          }
        })
      },

      getCueConf() {
        // 获取用户设置
        const req = {
          account: this.conf.account
        }
        const that = this
        this.$axios.post('/apis/conf/shb', req).then(function (res) {
          console.log("返回值打印-----2222", res)
          const re_date = res.data.Data
          if (res.data.Code == '200') {
            if (re_date.is_bp == 1) {
              that.is_bp_bool = true
            } else {
              that.is_bp_bool = false
            }
            that.conf = re_date
            that.activeName = that.conf.dialog_v
          }
        })
      },
      handleClick(tab, event) {}
    }
  };

</script>
<style scoped="scoped">
  .ckfg_all {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .ckfg {
    width: 800px;
  }

  .ckfg_tp {

    max-width: 500px;
    padding-left: 10px;
  }

  .ckfg_tp img {
    width: 100%;
  }

  .title {
    text-align: center;
    height: 40px;
    line-height: 40px;
    border: 1px solid #e6e6e6;
    color: deepskyblue;
    display: flex;
    flex-direction: column;
  }

  .calss {
    display: flex;
    flex-direction: row;
  }

  .class2,
  .title,
  .class {
    margin: 5px;
    display: flex;
  }

  .class2_1 {
    background-color: #fbfbfb;
    border: 1px solid #e6e6e6;
    box-sizing: content-box;
    height: 40px;
    line-height: 40px;
    width: 40%;
    padding: 0px 10px;
    text-align: center;
  }

  .class2_2 {
    height: 40px;
    line-height: 40px !important;
    box-sizing: content-box;

    border: 1px solid #e6e6e6;
    border-left: 0px;
    width: 60%;
    padding: 0px 10px;
    text-align: left;
    display: flex;
    flex-direction: row;
  }

  .el-switch {
    margin-top: 10px !important;
  }

  .class {
    display: flex;
    flex-direction: row;
  }

  .class3 {
    width: 50%;
    height: 40px;
    box-sizing: content-box;
    color: #767676;
    display: flex;
    flex-direction: row;
    margin: 5px 0px;
  }

  .br {
    border-right: 0px;
  }

  .class3_1 {
    background-color: #fbfbfb;
    border: 1px solid #e6e6e6;
    box-sizing: content-box;
    height: 40px;
    line-height: 40px;
    width: 30%;
    padding: 0px 10px;
    text-align: center;

  }

  .class3_2 {
    border: 1px solid #e6e6e6;
    box-sizing: content-box;
    border-left: 0px;
    height: 40px;
    line-height: 40px;
    width: 70%;
    padding: 0px 10px;
    text-align: left;
    display: flex;
    flex-direction: row;
  }

</style>
