<template>
  <el-container>
    <el-header>
      <HeaderTop />
    </el-header>
    <el-container>
      <el-aside width="200px">
        <LeftMenu />
      </el-aside>
      <el-container>
        <el-main>
          <div class='list_content'>
            <h2 style="text-align: center;">添加bug记录</h2>
            <el-form ref="form" :model="bug_record" label-width="80px">
              <el-form-item label="标题" style="margin-left: 0px;">
                <el-tooltip class="item" effect="dark" content="bug的简要描述" placement="right">
                  <el-input v-model="bug_record.title"></el-input>
                </el-tooltip>
              </el-form-item>
              <el-form-item label="详情" style="margin-left: 0px; ">
                <el-tooltip class="item" effect="dark" content="bug的详细描述" placement="right">
                  <el-input type="textarea" :rows="10" v-model="bug_record.bug_detail"></el-input>
                </el-tooltip>
              </el-form-item>

              <el-form-item label="需求类型">
                <el-select v-model="bug_record.bug_type" placeholder="访客需求">
                  <el-option label="访客需求" value="访客需求"></el-option>
                  <el-option label="客户需求" value="客户需求"></el-option>
                  <el-option label="代理商需求" value="代理商需求"></el-option>
                  <el-option label="管理需求" value="管理需求"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="提交者" style="margin-left: 0px; width: 200px;">
                <el-tooltip class="item" effect="dark" content="谁提交的，谁负责审核" placement="right">
                  <!-- <el-input v-model="bug_record.submit_name" width="100"></el-input> -->
                  <el-autocomplete
                    class="inline-input"
                    v-model="bug_record.submit_name"
                    :fetch-suggestions="querySearch"
                    placeholder="请输入内容"
                    @select="handleSelect"
                    ></el-autocomplete>
                </el-tooltip>
              </el-form-item>
              <el-button type="primary" @click="onSubmit">添加</el-button>
              <el-button @click="cancel">取消</el-button>
            </el-form>
          </div>
        </el-main>
        <el-footer>
          <Footer />
        </el-footer>
      </el-container>
    </el-container>
    <router-view /><!-- 路由出口 -->
  </el-container>
</template>

<script>
  import LeftMenu from '@/components/LeftMenu0.vue'
  import HeaderTop from '@/components/Top.vue'
  import Footer from '@/components/Footer.vue'

  export default {
    name: 'UserAdd',
    // 注册组件
    components: {
      LeftMenu,
      HeaderTop,
      Footer
    },
    props: {},
    // data() 为变量赋值等
    data() {
      return {
        restaurants: [],
        bug_record: {
          title: '',
          bug_detail: '',
          bug_pic: '',
          submit_time: new Date(),
          fixed_time: '',
          my_result: '',
          author_result: '',
        },
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.restaurants = this.loadAll();
    },
    methods: {
        createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
    querySearch(queryString, cb) {
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      loadAll() {
        return [
          { "value": "刘前程" },
          { "value": "魏梦龙"},
          { "value": "宗世恒" },
          { "value": "李新运"},
          { "value": "范海瑞" },
          { "value": "志强" },
          { "value": "田晓曼" },
        ];
      },
      handleSelect(item) {
        console.log(item);
      },
      onSubmit() {
        console.log('开始提交bug!');
        const that = this
        this.$axios.post('/apis/bug/add', that.bug_record)
          .then(function (res) {
            console.log(res)
            if (res.data.Code == "200") {
              alert(res.data.Msg);
              window.history.go(-1);
            }
          })
      },
      cancel() {
        window.history.go(-1);
      }
    }
  }

</script>

<style scoped="scoped">
  .list_content {
    max-width: 800px;
  }

  .el-form-item__label {
    max-width: 200px !important;
  }

</style>
