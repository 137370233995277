<template>
  <div>
    <h1 class="tipx">正在退出</h1>
    <router-view />
  </div>
</template>

<script>
// 从缓存获取user
const cache_user = sessionStorage.getItem('user')
export default {
  name: 'SignOut',
  created () {
    const that = this
    this.$axios.post('/apis/sign/out', cache_user)
      .then(function (res) {
        console.log(res.data)
        if (res.data.Code === '200') {
          alert("退出成功!")
          that.$router.push('/login')
        } else {
          alert(res.data.Msg)
        }
      })
  }
}
</script>

<style>
</style>
