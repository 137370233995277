<template>
  <div class="smile">
    <div class="faceabc">
      <img src="../../assets/img/a.gif" alt="...">
    </div>
    <h3>{{phone}}</h3>
    <div class="mybtn">

      <el-button type="primary" @click="bodh">拨打电话</el-button>
      <el-button type="info" @click="jxzx">返回咨询</el-button>
    </div>
    <h2>打字不方便</h2>
    <h2>马上电话咨询</h2>
    <!--<button onclick='bodh1()'>bodh1</button>-->
  </div>
</template>

<script>
  export default {
    name: 'smile',
    data() {
      return {
        phone: '17364486668',
      }
    },
    created() {
      let _this = this
//    window.bodh1 = _this.bodh1
      let from_phone = this.$route.query.phone
      if (from_phone != undefined && from_phone != '') {
        this.phone = from_phone
      }
      console.log('=-=-------------------------------', this.$route.query.phone, this.phone)
    },
    methods: {
//    bodh1: function () {
//      window.open('tel://' + this.phone)
//    },
      bodh() {
        window.open('tel://' + this.phone)
      },
      jxzx() {
        this.$router.go(-1)
      },
    }
  }

</script>

<style scoped="scoped">
  body {
    background-color: gainsboro;
  }

  .smile {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: center;
    margin: 10px;
    border-radius: 15px;
    border: 1px solid #c1c1c1;
    background-color: #fff;
    position: absolute;
    width: auto;
    height: auto;
    overflow: auto;
    bottom: 20px;
    top: 30px;

    box-sizing: border-box;
    _height: 100%;
    /*_border-top:60px solid #eee;
	_border-right:8px solid #eee;*/
    right: 0px;
    /*_border-left:8px solid #eee;*/
    left: 0px;
    /*_border-bottom:50px solid redeee;*/
    _top: 0px;
    z-index: 90;
  }

  .faceabc {
    text-align: center;
    width: 40%;
    margin-top: 50px;
  }

  .faceabc img {
    width: 100%;
  }

  h2 {
    font-size: 28px;
    text-align: center;
    margin: 5px;
  }

  .smile h3 {
    color: red;
    text-align: center;
    font-weight: 100;
    margin: 5px;
  }

  .el-button {
    margin: 10px;
  }

  .mybtn {
    display: flex;
    flex-direction: row;
  }

</style>
