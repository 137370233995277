<template>
  <div id="liul_list">
    <el-container>
      <el-header>
        <HeaderTop />
      </el-header>
      <el-container>
        <el-aside width="200px">
          <LeftMenu />
        </el-aside>
        <el-container>
          <el-main>
            <!-- <keep-alive> -->
            <div class="list_content">
              <!-- 查询条件 -->
              <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="时间段">
                  <div class="block">
                    <el-date-picker v-model="formInline.submit_time" type="datetimerange" align="right" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:01', '23:59:59']" value-format="yyyy-MM-dd HH:mm" style="width: 360px;">
                    </el-date-picker>
                  </div>
                </el-form-item>
                <el-form-item label="标题查询">
                  <el-input placeholder="请输入内容" prefix-icon="el-icon-search" v-model="bug_input">
                  </el-input>
                </el-form-item>
                <el-form-item label="详情查询">
                  <el-input placeholder="请输入内容" prefix-icon="el-icon-search" v-model="bug_input">
                  </el-input>
                </el-form-item>
                <el-form-item label="需求状态">
                  <el-select v-model="formInline.level" placeholder="需求状态" style="width: 120px;">
                    <el-option label="新提交" value=1></el-option>
                    <el-option label="前端修复中" value=2></el-option>
                    <el-option label="后端修复中" value=3></el-option>
                    <el-option label="前端已修复" value=4></el-option>
                    <el-option label="等待验收" value=5></el-option>
                    <el-option label="已修复" value=6></el-option>
                    <el-option label="待商讨" value=7></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="审核结果">
                  <el-select v-model="formInline.level" placeholder="审核结果" style="width: 120px;">
                    <el-option label="待审核" value=1></el-option>
                    <el-option label="前端已通过" value=2></el-option>
                    <el-option label="前端不通过" value=3></el-option>
                    <el-option label="后端不通过" value=4></el-option>
                    <el-option label="已通过" value=5></el-option>
                    <el-option label="待商讨" value=6></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="显示全部">
                  <el-switch v-model="show_all" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                </el-form-item>

                <el-form-item>
                  <el-button type="primary" @click="cx_sunbmit">查询</el-button>
                  <el-button fixed='right' type="primary" @click="addBugRec">添加</el-button>
                </el-form-item>
              </el-form>
              <!-- 列表内容 -->
              <el-table :data="bug_list" :cell-style="{padding:'2px 0'}" height="650" border stripe>
                <el-table-column prop="id" label="序号" align="center" width="50"></el-table-column>
                <el-table-column label="标题" width="500">
                  <template slot-scope="scope">
                    <router-link class="title_redirect" v-if='scope.row.author_result=="已通过"||scope.row.my_result=="前端修复中"||scope.row.my_result=="后端修复中"||scope.row.my_result=="前端已修复"||scope.row.my_result=="已修复"' style="color:green" v-bind:to="'/debug/xg?id='+scope.row.id">{{scope.row.title}}</router-link>
                    <router-link class="title_redirect" v-else-if='scope.row.author_result=="等待验收"' style="color:orangered" v-bind:to="'/debug/xg?id='+scope.row.id">{{scope.row.title}}</router-link>
                    <router-link class="title_redirect" v-else v-bind:to="'/debug/xg?id='+scope.row.id">{{scope.row.title}}</router-link>
                  </template>
                </el-table-column>
                <el-table-column prop="submit_name" label="提交者" width="80" align="center"></el-table-column>
                <el-table-column prop="bug_type" label="需求类型" width="100" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.bug_type=='访客需求'" style="background-color:deepskyblue;padding: 5px 10px;border-radius:3px;display: block;color: white;">访客需求</span>
                    <span v-else-if="scope.row.bug_type=='客户需求'" style="background-color:darkorange;padding: 5px 10px;border-radius:3px;display: block;color: white;">客户需求</span>
                    <span v-else-if="scope.row.bug_type=='代理商需求'" style="background-color:cornflowerblue;padding: 5px 2px;border-radius:3px;display: block;color: white;">代理商需求</span>
                    <span v-else style="background-color:grey;padding: 5px 2px;border-radius:3px;display: block;color: white;">管理需求</span>
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="bug_detail" label="详情" width="500" align="center"></el-table-column> -->
                <el-table-column prop="submit_time" label="提交时间" width="170" align="center"></el-table-column>
                <el-table-column prop="fixed_time" label="改正时间" width="170" align="center"></el-table-column>
                <el-table-column prop="my_result" label="需求状态" width="100" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.my_result=='新提交'" style="color:deepskyblue">新提交</span>
                    <span v-else-if="scope.row.my_result=='前端修复中'" style="color:#37B328">前端修复中</span>
                    <span v-else-if="scope.row.my_result=='后端修复中'" style="color:green">后端修复中</span>
                    <span v-else-if="scope.row.my_result=='前端已修复'" style="color:green">前端已修复</span>
                    <span v-else-if="scope.row.my_result=='等待验收'" style="color:orangered">等待验收</span>
                    <span v-else-if="scope.row.my_result=='已修复'" style="color:green">已修复</span>
                    <span v-else style="color:orange">待商讨</span>
                  </template>
                </el-table-column>
                <el-table-column prop="fixed_name" label="修复者" width="80" align="center"></el-table-column>
                <el-table-column prop="author_result" label="审核结果" width="100" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.author_result=='待审核'" style="color:deepskyblue">待审核</span>
                    <span v-else-if="scope.row.author_result=='已通过'" style="color:#37B328">已通过</span>
                    <span v-else-if="scope.row.author_result=='待商讨'" style="color:orange">待商讨</span>
                    <span v-else-if="scope.row.author_result=='前端已通过'" style="color:#37B328">前端已通过</span>
                    <span v-else-if="scope.row.author_result=='前端不通过'" style="color:orange">前端不通过</span>
                    <span v-else-if="scope.row.author_result=='后端不通过'" style="color:orange">后端不通过</span>
                    <span v-else style="color:red">不通过</span>
                  </template>
                </el-table-column>
                <el-table-column prop="submit_name" label="审核者" width="80" align="center"></el-table-column>
                <el-table-column prop="is_show" label="是否显示" width="100" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.is_show==1">显示</span>
                    <span v-else style="color:darkgrey">隐藏</span>
                  </template>

                </el-table-column>
              </el-table>
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current_page" :page-sizes="[15,20,25]" :page-size="page_size" layout="total, sizes, prev, pager, next, jumper" :total="data_num" :sizes='page_num'>
              </el-pagination>
            </div>
            <!-- 路由出口 -->
            <router-view />
          </el-main>
          <el-footer>
            <Footer />
          </el-footer>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>
<script>
  import LeftMenu from '@/components/LeftMenu0.vue'
  import HeaderTop from '@/components/Top.vue'
  import Footer from '@/components/Footer.vue'
  export default {
    name: 'bug_list',
    components: {
      LeftMenu,
      HeaderTop,
      Footer
    },
    data() {
      return {
        bug_input:'',
        bug_list: [{
          id: 1,
          title: '',
          bug_detail: '',
          bug_type: '',
          submit_time: "",
          fixed_time: "",
          my_result: '',
          author_result: '',
          is_show: '',
        }, ],
        formInline: {
          submit_time: '',
          show_all: 0,
          current_page: 1,
          page_size: 15, // 每页的数据
        },
        show_all: false,
        current_page: 1, //初始页
        page_size: 15, // 每页的数据
        data_num: 0, // 总条数
        page_num: 0, // 总页数
      }
    },
    created() {
      console.log('查询bug记录 !!!!!');
      let a=sessionStorage.getItem('bug_page')
        if(a!=''){
            this.current_page=Number(a)
        }
      let that = this
      const res_pag = {
        'current_page': this.current_page
      }
      this.$axios.post('/apis/bug/list',res_pag)
        .then(function (res) {
          console.log(res.data)
          console.log("res.data.Code:", res.data.Code)
          if (res.data.Code == '200') {
            that.bug_list = res.data.Data.List
            that.data_num = res.data.Data.TotalCount
            that.page_num = res.data.Data.TotalPage
          }
        })
    },
    mounted() {},
    methods: {
      cx_sunbmit() {
        this.current_page = 1
        this.onSubmit()
      },
      onSubmit() {
        console.log('查询bug记录!');
        const that = this
        let req = {}
        req.submit_time_start = this.formInline.submit_time[0]
        req.submit_time_finish = this.formInline.submit_time[1]
        req.submit_title=this.bug_input
        req.current_page = this.current_page
        req.page_size = this.page_size
        req.show_all = 0
        if (this.show_all == true) {
          req.show_all = 1
        }
        this.$axios.post('/apis/bug/list', req)
          .then(function (res) {
            if (res.data.Code === '200') {
              const res_list = res.data.Data.List
              const res_data = res.data.Data
              that.bug_list = res.data.Data.List
              that.tableData = res_list
              that.page_num = res_data.TotalPage
              that.data_num = res_data.TotalCount
              console.log(res.data.Data.List);
            }
          })
          sessionStorage.setItem('bug_page',this.current_page)
      },
      // 初始页current_page、初始每页数据数page_size和数据data
      handleSizeChange: function (size) {
        this.page_size = size;
        console.log(this.page_size) //每页下拉显示数据
        this.onSubmit()
      },
      handleCurrentChange: function (current_page) {
        this.current_page = current_page;
        console.log(this.current_page) //点击第几页
        this.onSubmit()
      },
      handleDetail: function (u) {
        this.$router.push({
          path: '/debug/xg',
          query: {
            id: u.id
          }
        })
      },
      addBugRec: function () {
        this.$router.push({
          path: '/debug/add',
        })
      }
    }
  }

</script>

<style scoped>
  /*大框体样式*/
  .el-main {
    height: calc(100vh - 120px);
  }

  .title_redirect {
    text-decoration: none;

  }

  .title_redirect-hover {
    color: red;
  }

  .title_redirect-visited {
    text-decoration: none;
    color: gray;
  }

</style>
