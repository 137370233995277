<template>
    <!-- <div class="footer">版权所有&copy;{{user_name}}·翻版必究，技术交流：{{user_name}} TEL：{{phone}} </div> -->
    <div class="footer">版权所有&copy;{{user_name}}·翻版必究</div>
</template>

<script>
export default {
    name: 'HeaderTop',
    data() {
        return {
            account: '',
            id: '',
            user_name: '',
            validity_time: '',
            phone: "",
        }
    },
    created() {
        let that = this
        // 从缓存获取user
        const child_user = sessionStorage.getItem('user')
        let user_obj = JSON.parse(child_user)
        if (user_obj.level == "1" || user_obj.level == "2") {
            this.account = user_obj.account
            this.Id = user_obj.Id
            this.user_name = user_obj.user_name
            this.validity_time = user_obj.validity_time
            this.level = user_obj.level
            this.phone = user_obj.phone
        }

        if (user_obj.level == "3" || user_obj.level == "4") {
            this.$axios.post('/apis/user/byusername', {
                "user_name": user_obj.superior_id
            })
                .then(function (res) {
                    console.log(res.data)
                    if (res.data.Code === '200') {
                        const result = res.data.Data
                        console.log(result)
                        that.account = result.account
                        that.user_name = result.user_name
                        that.phone = result.phone
                    }
                })
        }
    }
}

</script>

<style scoped="scoped">
</style>